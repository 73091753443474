import React from "react";
import Header from './header';
import Footer from './footer';
import Select from 'react-select';
import $ from "jquery";
import opps from "../../assets/images/icons/New folder (2)/oops.png";
class Portfolio_Review extends React.Component {
hideGoal() {
$("#first").css({ "display": "none" });
$("#second").css({ "display": "block" });
}
hideForm() {
$("#second").css({ "display": "none" });
$("#first").css({ "display": "block" });
}
render() {
const profile = [
{ value: 'select', label: 'select' },
];
return (
<>
<div id="content-wrapper" className="d-flex flex-column">
  {/* ===================Main Content================ */}
  <div id="content">
    {/* ==================Topbar================== */}
    <Header />
    {/* ===================End of Topbar============== */}
    {/* ===================Begin Page Content=============== */}
    <div className="container-fluid port-review">
      {/* ===================Page Heading=================== */}
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb">
          <li className="breadcrumb-item"><a href="home">Home</a></li>
          <li className="breadcrumb-item active" aria-current="page">Portfolio Review</li>
        </ol>
      </nav>
      <section>
        <div className="row justify-content-center">
          <div className="col-lg-8 col-md-11">
            <div id="first">
              <div className="col-lg-5 col-md-8 col-sm-12 ml-3">
                <label>Select Profile</label>
                <Select className='single-drop py-1 pl-2 ' options={profile} />
              </div>
              <div className="shadow-custom p-3">
                <div className="col-md-12 text-right pb-4">
                  <input className="" type="checkbox" value="Select All"/>Select All
                </div>
                <div className="table-responsive">
                <div className="col-md-12 red text-left fs-16 min-w-35em">
                  Kotak Bluechip Fund-Growth
                  (Regular Plan) (Eastwhile Kotak 50 Scheme)
                  (705527/17)
                </div>
                </div>
                <div className="col-md-12 text-left fs-14">
                  Recommendation
                </div>
                <div className="col-md-12 text-black font-weight-500 text-left">
                  <div className="row">
                    <div className="col-md-6 ">Switch</div>
                    <div className="col-md-6 text-right"> <input className="" type="checkbox"/></div>
                  </div>
                </div>
                <hr />
                <div className="table-responsive">
                <div className="col-md-12 red text-left fs-16 min-w-35em">
                  Kotak Bluechip Fund-Growth
                  (Regular Plan) (Eastwhile Kotak 50 Scheme)
                  (705527/17)
                </div>
                </div>
                <div className="col-md-12 text-left fs-14">
                  Recommendation
                </div>
                <div className="col-md-12 text-black font-weight-500 text-left">
                  <div className="row">
                    <div className="col-md-6 ">Redemption</div>
                    <div className="col-md-6 text-right"> <input className="" type="checkbox"/></div>
                  </div>
                </div>
                <hr />
                <div className="table-responsive">
                <div className="col-md-12 red text-left fs-16 min-w-35em">
                  Kotak Bluechip Fund-Growth
                  (Regular Plan) (Eastwhile Kotak 50 Scheme)
                  (705527/17)
                </div>
                </div>
                <div className="col-md-12 text-left fs-14">
                  Recommendation
                </div>
                <div className="col-md-12 text-black font-weight-500 text-left">
                  <div className="row">
                    <div className="col-md-6 ">Switch</div>
                    <div className="col-md-6 text-right"> <input className="" type="checkbox"/></div>
                  </div>
                </div>
                <div className="col-md-12 pt-5 text-right">
                  <a href="javascript:void(0);" className="btn-custom" onClick={this.hideGoal}>Next</a>
                </div>
              </div>
            </div>
            <div id="second">
              <div className="shadow-custom p-lg-5 px-sm-3 bg-light-red">
                <div className='row '>
                  <div className='col-md-12 text-left'>
                    <div className="form-group pmd-textfield pmd-textfield-floating-label">
                      <label for="source" className='text-label'>Source Scheme<span className="text-danger">*</span></label><br />
                      <input type="text" className="bg-c form-control" />
                    </div>
                  </div>
                  <div className='col-md-6 text-left'>
                    <p className="text-label mb-1 mt-1 p-radio lb">
                      Asset Class 
                      <spna className="text-danger">*</spna>
                    </p>
                    <div className='pt-2'>
                      <input className=" input-text" id="equity" type="radio" name="asset" value="Equity" defaultChecked/>
                      <label htmlFor="equity" className="text-black">Equity</label>
                      <input className="input-text ml-3" id="debt" type="radio" name="asset" value="DEBT" />
                      <label htmlFor="debt" className="text-black">Debt</label>
                      <br></br>  <small className="text-danger pull-left"></small>
                    </div>
                  </div>
                  <div className='col-md-6 text-left'>
                    <p className="text-label mb-1 mt-1 p-radio lb">
                      Option 
                      <spna className="text-danger">*</spna>
                    </p>
                    <div className='pt-2'>
                      <input className=" input-text" id="growth" type="radio" name="growth" value="GROWTH" />
                      <label htmlFor="growth" className="text-black">Growth</label>
                      <input className="input-text ml-3" id="dividend" type="radio" name="growth" value="Dividend" />
                      <label htmlFor="dividend" className="text-black">IDCW  </label>
                    </div>
                  </div>
                  <div className='col-md-12 text-left'>
                    <div className="form-group pmd-textfield pmd-textfield-floating-label">
                      <label for="source" className='text-label'>Target Scheme<span className="text-danger">*</span></label><br />
                      <input type="text" className="bg-c form-control" />
                    </div>
                  </div>
                  <div className="col-md-6 pt-4">
                    <input className=" input-text" id="amt" type="radio" name="amt_type" value="amt" />
                    <label htmlFor="amt" className="text-black">Amount</label>
                    <input className="input-text ml-3" id="units" type="radio" name="amt_type" value="unit" />
                    <label htmlFor="units" className="text-black">Units</label>
                    <input className="input-text ml-3" id="all_units" type="radio" name="amt_type" value="all_units" />
                    <label htmlFor="all_units" className="text-black">All Units</label>
                    <br></br>  <small className="text-danger pull-left"></small>
                  </div>
                  <div className='col-md-6 text-left'>
                    <div className="form-group pmd-textfield pmd-textfield-floating-label">
                      <label for="source" className='text-label'>Enter Amount<span className="text-danger">*</span></label><br />
                      <input type="text" className="bg-c form-control" />
                    </div>
                  </div>
                  <div className="col-md-12 col-sm-12">
                    <div className="row pt-5">
                  <div className="col-md-6 col-sm-6 text-left">
                    <a href="javascript:void(0);" className="btn-custom" type="button" onClick={this.hideForm}>Back</a>
                  </div>
                  <div className="col-md-6  col-sm-6 text-right">
                    <a href="javascript:void(0);" className="btn-custom" data-target="#wrong" data-toggle="modal" type="button">Order</a>
                  </div>
                  </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* something went wrong */}
        <div className="modal animate__animated animate__zoomIn animate__fast" id="wrong" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true" data-keyboard="false" data-backdrop="static">
            <div className="modal-dialog modal-dialog-centered " role="document">
              <div className="modal-content br-15 ">
                <div className="modal-body text-center">
                  <img className="img-fluid" src={opps} alt="" />
                  <h3 className="text-black font-weight-500 py-3"> Something went wrong</h3>
                  <p className="">
                  Please try again after sometime.
                  </p>
                </div>
                <div className="bg-red text-center pt-2 order-b">
                  <a href='#' className="text-white font-weight-bold" data-dismiss="modal" >
                    <h4>OK</h4>
                  </a>
                </div>
              </div>
            </div>
          </div>
      </section>
    </div>
    {/* ====================Footer=================== */}
    <Footer />
    {/* ====================End of Footer================= */}
  </div>
  {/* =======================End of Content Wrapper==================== */}
</div>
{/*====================== End of Page Wrapper====================== */}
</>
)
}
}
export default Portfolio_Review