import React from "react";
import $ from 'jquery';
import Select from 'react-select';
import success from "../../assets/images/icons/New folder (2)/successfully.png";
class Invest_Sip extends React.Component {
  hides1() {
    $("#moi").css({ "display": "none" });
    $("#formf").css({ "display": "block" });
  }
  hides4() {
    $("#formf").css({ "display": "none" });
    $("#formhides").css({ "display": "block" });
  }
  hides2() {
    $("#formhides").css({ "display": "none" });
    $("#confirms").css({ "display": "block" });
  }
  hides3() {
    $("#confirms").css({ "display": "none" });
    $("#success").css({ "display": "block" });
  }
  edit() {
    $("#confirms").css({ "display": "none" });
    $("#formhides").css({ "display": "block" });
  }
  render() {
    const modeofinvest = [
      { value: "Lumpsum", label: "Lumpsum" },
      { value: "SIP", label: "SIP" },
    ];
    const folio = [
      { value: '1544545454', label: '1544545454' },
      { value: '55588888', label: '55588888' },
    ];
    const date = [
      { value: '1', label: '1' },
      { value: '2', label: '2' },
      { value: '3', label: '3' },
      { value: '4', label: '4' },
    ];
    const profile = [
      { value: 'select', label: 'select' },
    ];
    const selectperiod = [
      { value: 'select', label: 'select' },
    ];
    return (
      <>
        <section className="invest-section">
          <div className="row">
            <div tabindex="-1" className="modal pmd-modal animate__animated animate__zoomIn animate__fast" id="invest-sip" aria-hidden="true">
              <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content b-r p-1 bg-gray" >
                  {/* =================mode of investment============ */}
                  <div id="moi">
                    <div className="modal-header border-0">
                      <button aria-hidden="true" data-dismiss="modal" className="close" type="button">×</button>
                    </div>
                    <div className="modal-body">
                      <form>
                        <div className='row'>
                          <div className='col-md-12'>
                            <div className="form-group pmd-textfield pmd-textfield-floating-label">
                              <label className="control-label lb " for="email">Mode Of Investment<span className="text-danger">*</span></label>
                              <Select className=' bg-c' options={modeofinvest} />
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="modal-footer border-0 text-right">
                              <a type="button" className="btn btn-danger shadow-sm" href="javascript:void(0);" onClick={this.hides1}>Continue</a>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
               
                  {/*================ form===================  */}
                  <div id="formf">
                    <div className="modal-header">
                      <h5 className="modal-title text-black" id="sipTitle">SIP</h5>
                      <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">×</span>
                      </button>
                    </div>
                    <div className="modal-body ">
                      <form>
                        <div className="col mb-3 ">
                          <label htmlFor="Profile" className='text-label' >Select Profile <span className='text-danger'>*</span></label>
                          <Select className='bg-c' options={profile} />
                        </div>
                        <div className="col mb-3 ">
                          <label htmlFor="Profile" className='text-label' >Select Mandate <span className='text-danger'>*</span></label>
                          <Select className='bg-c' options={profile} />
                        </div>
                        <a href="/prodigypro/dashboard/bank-and-mandate" className="red fs-13">Create Mandate</a>
                      </form>
                    </div>
                    <div className="modal-footer border-0">
                      <a type="button" className="btn btn-danger shadow-sm" href="javascript:void(0);" onClick={this.hides4}>Continue</a>
                    </div>
                  </div>
                  {/*=============sip cart============ */}
                  <div id='formhides'>
                    <div className="modal-header">
                      <h5 className="modal-title text-black" id="sipTitle">SIP Cart</h5>
                      <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">×</span>
                      </button>
                    </div>
                    <div className="modal-body ">
                      <div className='cartitemwith bg-light-red'>
                        <div className='row p-4'>
                          <div className='col-md-12  red '>SBI Long Term Equity Fund-Regular
                            Plan-Growth
                          </div>
                        </div>
                        <div className="col bg-white py-2 px-4">
                          <label htmlFor="Profile" className='fs-14' >Select Folio <span className='text-danger'>*</span></label>
                          <Select className='border-pop' options={folio} />
                        </div>
                        <div className="col  bg-white pb-1 px-4">
                          <label htmlFor="amount" className='fs-14' >Enter Amount <span className='text-danger'>*</span></label>
                          <input type="text" className="form-control amount-b" name="amount" placeholder='Enter Amount' />
                        </div>
                        <div className="col bg-white py-2 px-4">
                          <label htmlFor="date" className='fs-14' >Select Date <span className='text-danger'>*</span></label>
                          <Select className='border-pop' options={date} />
                        </div>
                        <div className="col bg-white py-2 px-4">
                          <label htmlFor="period" className='fs-14' >Select Period <span className='text-danger'>*</span></label>
                          <Select className='border-pop' options={selectperiod} />
                        </div>
                        <div className='col mb-2 lastin'>
                          <input className="input-text p-2" id="perpetual" type="checkbox" name="perpetual" value="Y" defaultChecked />
                          <input type="hidden" id="perpetual_val" name="perpetual_val" />
                          <label htmlFor="perpetual" className="ml-2">
                            Perpetual
                            <spna className="text-danger">*</spna>
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="modal-footer border-0">
                      <a type="button" className="btn btn-danger shadow-sm" href="javascript:void(0);" onClick={this.hides2}>Continue</a>
                    </div>
                  </div>
                  {/* ==========confirm========== */}
                  <div id="confirms">
                    <div className="modal-header">
                      <h5 className="modal-title text-black" id="sipTitle">SIP Confirmation Purchase</h5>
                      <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">×</span>
                      </button>
                    </div>
                    <div className="modal-body ">
                      <div className="col mb-3 border-bottom">
                        <h6 className='red'>Shivam Shrivastav</h6>
                        <p>Mode of Holding :  Single <a href="#" className='p-4 ml-5' onClick={this.edit}>Edit</a></p>
                      </div>
                      <p className='red'>Axis Long Term Equity Fund-Regular-Growth</p>
                      <table className='mx-auto'>
                        <tr className='text-center'>
                          <td className='pr-4'>Folio </td>
                          :
                          <td className='pl-4'>124564</td>
                        </tr>
                        <tr className='text-center'>
                          <td className='pr-4'>Amount  </td>
                          :
                          <td className='pl-4'>50,000</td>
                        </tr>
                        <tr className='text-center'>
                          <td className='pr-4'>SIP From  </td>
                          :
                          <td className='pl-4'>11-sep-2022</td>
                        </tr>
                        <tr className='text-center'>
                          <td className='pr-4'>SIP To </td>
                          :
                          <td className='pl-4'>31-dec-2099</td>
                        </tr>
                        <tr className='text-center'>
                          <td className='pr-4'>SIP Date </td>
                          :
                          <td className='pl-4'>11</td>
                        </tr>
                      </table>
                      <p className='text-center pt-5'><b>Total  :  55,55882</b></p>
                    </div>
                    <div className="modal-footer border-0">
                      <a type="button" className="btn btn-danger shadow-sm" href="javascript:void(0);" onClick={this.hides3} >Order</a>
                    </div>
                  </div>
                  {/* ================successful transaction===============  */}
                  <div id="success">
                    <div className="modal-body py-0">
                      <div className="text-center">
                        <img className="img-fluid" src={success} alt="" />
                        <h6 className="success-c font-weight-500 py-3 fs-19">Successful Transaction with
                          Following Details
                        </h6>
                      </div>
                      <div className="row px-2">
                        <div className="col-md-4 col-sm-4 text-left text-black">Unique Number </div>
                        <div className="col-md-1 col-sm-1">:</div>
                        <div className="col-md-7 col-sm-7 text-left">12345678</div>
                        </div>
                        <div className="row px-2">
                        <div className="col-md-4  col-sm-4 text-left text-black">Trxn Number </div>
                        <div className="col-md-1 col-sm-1">:</div>
                        <div className="col-md-7 col-sm-7 text-left">12346195</div>
                        </div>
                        <div className="row px-2">
                        <div className="col-md-4  col-sm-4 text-left text-black">Fund </div>
                        <div className="col-md-1 col-sm-1">:</div>
                        <div className="col-md-7 col-sm-7 text-left">Axis Mutual Fund</div>
                        </div>
                        <div className="row px-2">
                        <div className="col-md-4  col-sm-4 text-left text-black">Folio Number </div>
                        <div className="col-md-1 col-sm-1">:</div>
                        <div className="col-md-7  col-sm-7 text-left">12345678</div>  
                        </div>
                        <div className="row px-2">                      
                        <div className="col-md-4  col-sm-4 text-left text-black">Amount   </div>
                        <div className="col-md-1 col-sm-1">:</div>
                        <div className="col-md-7 col-sm-7 text-left">1,000</div>
                        </div>
                        <div className="row px-2">
                        <div className="col-md-4  col-sm-4 text-left text-black">Status   </div>
                        <div className="col-md-1 col-sm-1">:</div>
                        <div className="col-md-7  col-sm-7 text-left">Successful</div>
                        </div>
                        <div className="row px-2">
                        <div className="col-md-4  col-sm-4 text-left text-black">Scheme Name </div>
                        <div className="col-md-1 col-sm-1">:</div>
                        <div className="col-md-7 col-sm-7 text-left">Axis Arbitrage Fund - Regular Growth</div>
                      </div>
                      <hr />
                      <div className="text-center">
                        <p className="text-black fs-13">
                          <span className="red text-left font-weight-500 fs-16">Note:</span>  Authorization link has been sent on your registered mail id and mobile number.The Link shall remain active for the next 48 hours.Kindly authorize.
                        </p>
                      </div>
                    </div>
                  </div>
                  {/*  */}
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    )
  }
}
export default Invest_Sip