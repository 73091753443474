import React, { component } from 'react';
import Header from './header';
import Footer from './footer';
import { Helmet } from "react-helmet";
import $ from "jquery";
import success from "../../assets/images/icons/New folder (2)/successfully.png";
import sunderam from "../../assets/images/icons/New folder (2)/Sundaram_Mutual_Fund.png";
import sbi from "../../assets/images/icons/New folder (2)/sbi mutual fund.png";
import idfc from "../../assets/images/icons/New folder (2)/IDFC.png";
import star from "../../assets/images/icons/New folder (2)/star.png";
import 'chartkick/chart.js';
import Select from 'react-select';
import { FaTrash } from "react-icons/fa";
import { DatePickerComponent } from "@syncfusion/ej2-react-calendars";
import Nav_Chart from './nav-chart';
class Tax_Planning extends React.Component {
  hideScheme() {
    $("#prod_div").css({ "display": "none" });
    $("#single_div").css({ "display": "block" });
  }
  back() {
    $("#single_div").css({ "display": "none" });
    $("#prod_div").css({ "display": "block" });
  }
  hide1() {
    $("#cart-l").css({ "display": "none" });
    $("#formhide-l").css({ "display": "block" });
  }
  hide2() {
    $("#formhide-l").css({ "display": "none" });
    $("#confirm-l").css({ "display": "block" });
  }
  hide3() {
    $("#confirm-l").css({ "display": "none" });
    $("#purchase-l").css({ "display": "block" });
  }
  hides1() {
    $("#cart-s").css({ "display": "none" });
    $("#form-s").css({ "display": "block" });
  }
  hides4() {
    $("#form-s").css({ "display": "none" });
    $("#formhide-s").css({ "display": "block" });
  }
  hides2() {
    $("#formhide-s").css({ "display": "none" });
    $("#confirm-s").css({ "display": "block" });
  }
  hides3() {
    $("#confirm-s").css({ "display": "none" });
    $("#success").css({ "display": "block" });
  }
  render() {
    const folio = [
      { value: '1544545454', label: '1544545454' },
      { value: '55588888', label: '55588888' },
    ];
    const date = [
      { value: '1', label: '1' },
      { value: '2', label: '2' },
      { value: '3', label: '3' },
      { value: '4', label: '4' },
    ];
    const profile = [
      { value: 'select', label: 'select' },
    ];
    const selectperiod = [
      { value: 'select', label: 'select' },
    ];
    const mandate = [
      { value: "--select--", label: "--select--" },
    ];
    const amc = [
      { value: "--select--", label: "--select--" },
    ];
    const scheme = [
      { value: "--select--", label: "--select--" },
    ];
    const payment_mode = [
      { value: "Net Banking", label: "Net Banking" },
      { value: "UPI", label: "UPI" },
      { value: "RTGS/NEFT", label: "RTGS/NEFT" },
      { value: "Debit Mandate", label: "Debit Mandate" },
    ];
    const bankname = [
      { value: "--select--", label: "--select--" },
    ];
    return (
      <>
        <Helmet>
          <title>Tax Planning</title>
        </Helmet>
        <style>
          {`
  .card{
  min-height:420px;
  }
  #Wait{
  display:none;
  }
  .text-color{
  color:#fff !important;
  }
  .nav-pills .nav-item {
  padding: 0px!important;
  }
  .custom-tab-bg {
  background-color: #FAF1F2;
  }
  .table td a, .table th
  {
  color:#000;
  }
  #single_div
  {
  display:none;
  }
  .modal-dialog
  {
  padding-bottom:3em;
  }
  .btn.dropdown-toggle
  {
  border:none!important;
  }
  .dropdown-menu.show
  {
  border-radius:0.35rem;
  }
  .dropdown-item.active{
  color: #000;
  border-radius: 0.5rem;
  background-color: #FDF0F0;
  }
  .shadowcart
  {
  background: #FFFFFF;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2);
  border-radius: 15px;
  }
  .shadowcart a
  {
  color:#000;
  }
  #ej2-datepicker_3,#ej2-datepicker_2 {
  border: 2px solid #f0f2ff !important;
  }
  `}
        </style>
        {/* Page Wrapper */}
        <div id="wrapper">
          {/* Content Wrapper */}
          <div id="content-wrapper" className="d-flex flex-column">
            {/* Main Content */}
            <div id="content">
              {/* Topbar */}
              <Header />
              {/* End of Topbar */}
              {/* Begin Page Content */}
              <div className="container-fluid tax-p">
                {/* Page Heading */}
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item"><a href="home">Home</a></li>
                    <li className="breadcrumb-item active">Tax Planning</li>
                  </ol>
                </nav>
                <div id="prod_div">
                  <div className="col-md-6 offset-md-3 mb-3 mt-5 bs" >
                    <ul class="nav nav-pills nav-fill navtop">
                      <li class="nav-item">
                        <a class="nav-link active fs-16" href="#menu1" data-toggle="tab" >Lumpsum</a>
                      </li>
                      <li class="nav-item">
                        <a class="nav-link fs-16" href="#menu2" data-toggle="tab" >SIP</a>
                      </li>
                    </ul>
                  </div>
                  <div class="tab-content mt-3 ">
                    <div class="tab-pane show active" id="menu1" role="tabpanel">
                      <div className="row mt-5">
                        <div className="col-xl-10 col-lg-10 offset-lg-1 col-md-12">
                          <div className="card shadow-custom text-left m-sm-auto">
                            <div className="card-body custom-tab-bg" id="Wait">
                              <div className="col-12" >
                                Please Wait...
                              </div>
                            </div>
                            <div className="card-body custom-tab-bg">
                              <div className="col-12">
                                <div className="table-responsive">
                                <table class="table">
                                  <thead>
                                    <tr>
                                      <th scope="col" colSpan="2" className='red'>Recommended Schemes</th>
                                      <th></th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr>
                                      <td><img src={idfc} alt="" className='img-fluid min-w-3em' /></td>
                                      <td className='pt-4 min-w-35em'><a href='javascript:void(0);' onClick={this.hideScheme}>IDFC Tax Advantage (ELSS)
                                        Fund-Growth-Regular Plan  </a>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td><img src={sunderam} alt="" className='img-fluid' /></td>
                                      <td className='pt-4'><a href='!#'>Sundaram Tax Savings Fund (Formerly Principal Tax Savings Fund)-
                                        Regular Growth </a>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td><img src={sunderam} alt="" className='img-fluid' /></td>
                                      <td className='pt-4'><a href='!#'>Sundaram Tax Savings Fund (Formerly Principal Tax Savings Fund)-
                                        Regular Growth </a>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td><img src={sbi} alt="" className='img-fluid' /></td>
                                      <td className='pt-4'><a href='!#'>SBI Long Term Equity Fund-Regular
                                        Plan-Growth  </a>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                                </div>
                                <div className='col-md-12 pb-3 fs-sm-10'>
                                  <a href='javascript:void(0);' className="float-right" data-target="#form-dialog-new-purchase" data-toggle="modal" type="button">Looking for another scheme?</a>
                                </div>
                              </div>
                              <div className="col-12 mt-4 mb-3 text-right">
                                <a className="btn-custom text-color" data-toggle="modal" data-target="#lumpsum">Continue</a>
                                
                              </div>
                            </div>
                          </div>
                          {/* disclaimer */}
                          <div class="text-center pt-4 mb-0" role="alert">
                            <p className="text-disclaimer  fs-13 fs-sm-10 text-black">*Mutual Fund investments are subject to market risks, please read the scheme related documents carefully before investing.</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="tab-pane show" id="menu2" role="tabpanel">
                      <div className="row mt-5">
                        <div className="col-xl-10 col-lg-10 offset-lg-1 col-md-12">
                          <div className="card shadow-custom text-left">
                            <div className="card-body custom-tab-bg" id="Wait">
                              <div className="col-12" >
                                Please Wait...
                              </div>
                            </div>
                            <div className="card-body custom-tab-bg">
                              <div className="col-12">
                                <div className="table-responsive">
                                <table class="table">
                                  <thead>
                                    <tr>
                                      <th scope="col" colSpan="2" className='red'>Recommended Schemes</th>
                                      <th></th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr>
                                      <td><img src={sunderam} alt="" className='img-fluid min-w-3em' /></td>
                                      <td className='pt-4 min-w-35em'><a href='!#'>Sundaram Tax Savings Fund (Formerly Principal Tax Savings Fund)-
                                        Regular Growth </a>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td><img src={sbi} alt="" className='img-fluid' /></td>
                                      <td className='pt-4'><a href='!#'>SBI Long Term Equity Fund-Regular
                                        Plan-Growth  </a>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td><img src={idfc} alt="" className='img-fluid' /></td>
                                      <td className='pt-4'><a href='!#'>IDFC Tax Advantage (ELSS)
                                        Fund-Growth-Regular Plan   </a>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td><img src={sunderam} alt="" className='img-fluid' /></td>
                                      <td className='pt-4'> <a href='!#'>Sundaram Tax Savings Fund (Formerly Principal Tax Savings Fund)-
                                        Regular Growth  </a>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                                </div>
                                <div className='col-md-12 pb-3 fs-sm-10'>
                                  <a href='javascript:void(0);' className="float-right" data-target="#form-dialog-sip" data-toggle="modal" type="button">Looking for another scheme?</a>
                                </div>
                              </div>
                              <div className="col-12 mt-4 mb-3 text-right">
                                <a className="btn-custom text-color" data-toggle="modal" data-target="#sip">Continue</a>
                              
                              </div>
                            </div>
                          </div>
                          {/* disclaimer */}
                          <div class="text-center pt-4 mb-0" role="alert">
                            <p className="text-disclaimer  fs-13 text-black">*Mutual Fund investments are subject to market risks, please read the scheme related documents carefully before investing.</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-10 offset-md-1 card shadow-custom my-5 text-left m-sm-auto" id="single_div">
                  <div className="card-body">
                    <div className='col-md-12 py-3 table-responsive'>
                      <table className='w-100'>
                        <tr>
                          <td className='px-lg-4'><img src={idfc} alt="" className='img-fluid min-w-3em' /></td>
                          <td className='px-lg-4 min-w-35em'>IDFC Tax Advantage (ELSS)
                            Fund-Growth-Regular Plan
                          </td>
                          <td className='px-4' style={{ color: "#2E3192" }}>
                            <h3>25%</h3>
                          </td>
                          <td className='px-4 min-w-14em'>3Y annualised</td>
                        </tr>
                      </table>
                    </div>
                    <div className='col-md-12'>
                      <div className="pt-4">
                        <Nav_Chart />
                      </div>
                    </div>
                    <div className="col-md-8 offset-md-2">
                      <div className='nav d-flex justify-content-between nav-h'>
                        <div className=''>
                          <a href="#">1M</a>
                        </div>
                        <div className=''>
                          <a href="#">6M</a>
                        </div>
                        <div className=''>
                          <a href="#">1Y</a>
                        </div>
                        <div className=''>
                          <a href="#">3Y</a>
                        </div>
                        <div className=''>
                          <a href="#">5Y</a>
                        </div>
                        <div className=''>
                          <a href="#">7Y</a>
                        </div>
                        <div className=''>
                          <a href="#">All</a>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-10 offset-md-1 py-4 pl-5 table-responsive">
                      <table>
                        <tr>
                          <td className='px-lg-5 text-label'>NAV: 12 Aug-2022</td>
                          <td className='px-5 text-label'>Rating
                          </td>
                          <td className='px-lg-5 text-label'>Min. SIP amount </td>
                          <td className='px-5 text-label min-w-11em'>Fund Size</td>
                        </tr>
                        <tr>
                          <td className='px-5 pt-3'>₹134.56</td>
                          <td className='px-5 pt-3'>4 <img src={star} alt className='pb-1' /> </td>
                          <td className='px-5 pt-3'>₹1000</td>
                          <td className='px-5 pt-3'>₹1,911 Cr </td>
                        </tr>
                      </table>
                    </div>
                    <div className="col-md-12 py-5">
                    <button className='btn-custom float-left' onClick={this.back}>Back</button>
                      <button className='btn-custom float-right'>Add to Cart</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* End of Main Content */}
            {/* <!-- Form trigger modal SIP --> */}
            <section className="sip">
              <div className="row">
                <div tabindex="-1" className="modal pmd-modal fade" id="form-dialog-sip" aria-hidden="true">
                  <div className="modal-dialog modal-lg">
                    <div className="modal-content b-r p-3 bg-gray">
                      <div className="modal-header border-0">
                        <button aria-hidden="true" data-dismiss="modal" className="close" type="button">×</button>
                      </div>
                      <div className="modal-body">
                        <form>
                          <div className="row">
                            <div className='col-md-5 col-lg-5'>
                              <div className="form-group pmd-textfield pmd-textfield-floating-label">
                                <label for="select-p" className='text-label'>Select Profile<span className="text-danger">*</span></label><br />
                                <Select className='' options={profile} />
                              </div>
                            </div>
                            <div className='col-md-7 col-lg-7'>
                              <div className='pt-4 mt-3'>
                                <input className=" input-text" id="equity" type="radio" name="asset" value="Equity" />
                                <label htmlFor="equity" className="">Existing Scheme</label>
                                <input className="input-text ml-3" id="debt" type="radio" name="asset" value="DEBT" />
                                <label htmlFor="debt" className="">New Scheme</label>
                                <br></br>  <small className="text-danger pull-left"></small>
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className='col-md-6 col-lg-4 '>
                              <div className="form-group pmd-textfield pmd-textfield-floating-label">
                                <label for="amc" className='text-label'>Select AMC<span className="text-danger">*</span></label><br />
                                <Select className='' options={amc} />
                              </div>
                            </div>
                            <div className='col-md-6 col-lg-4'>
                              <div className="form-group pmd-textfield pmd-textfield-floating-label">
                                <label for="folio" className='text-label'>Select Folio<span className="text-danger">*</span></label><br />
                                <Select className='' options={folio} />
                              </div>
                            </div>
                            <div className='col-md-6 col-lg-4'>
                              <div className="form-group pmd-textfield pmd-textfield-floating-label">
                                <label for="Scheme" className='text-label'>Select  Scheme<span className="text-danger">*</span></label><br />
                                <Select className='' options={scheme} />
                              </div>
                            </div>
                          </div>
                          <div className='row'>
                            <div className='col-md-6 col-lg-4'>
                              <div className="form-group pmd-textfield pmd-textfield-floating-label">
                                <label className="control-label lb text-label" for="amount">Enter Amount<span className="text-danger">*</span></label>
                                <input type="text" className="form-control" name='amount' placeholder='Enter Amount' />
                              </div>
                            </div>
                            <div className='col-md-6 col-lg-4'>
                              <div className="form-group pmd-textfield pmd-textfield-floating-label">
                                <label for="sipdate" className='lb  text-label'>SIP Date<span className="text-danger">*</span></label><br />
                                <Select className='' options={date} />
                              </div>
                            </div>
                            <div className='col-md-6 col-lg-4'>
                              <div className="form-group pmd-textfield pmd-textfield-floating-label">
                                <label className="control-label lb text-label" for="sipf">SIP From<span className="text-danger">*</span></label>
                               
                                <DatePickerComponent format='MMM-yyyy' className="form-control" placeholder='MM-YYYY' start='Year' depth='Year' />
                              </div>
                            </div>
                          </div>
                          <div className='row'>
                            <div className='col-md-6 col-lg-4'>
                              <div className="form-group pmd-textfield pmd-textfield-floating-label">
                                <label className="control-label lb text-label" for="sipend">SIP To<span className="text-danger">*</span></label>
                                <DatePickerComponent format='MMM-yyyy' className="form-control" placeholder='MM-YYYY' start='Year' depth='Year' />
                              </div>
                            </div>
                            <div className='col-md-6 col-lg-3 '>
                              <input className="input-text mt-5" id="perpetual" type="checkbox" name="perpetual" value="Y" defaultChecked />
                              <input type="hidden" id="perpetual_val" name="perpetual_val" />
                              <label htmlFor="perpetual" className="ml-2">
                                Perpetual
                                <spna className="text-danger">*</spna>
                              </label>
                            </div>
                          </div>
                          <div className="col-md-12 ">
                            <a href="javascript:void(0)" className="btn btn-danger float-right my-4" >+ Add</a>
                          </div>
                          <div className="col-md-12 " id="tbt">
                            <div className="table-responsive">
                            <table className="table bg-white mt-5 mb-3">
                              <tr>
                                <th>Profile</th>
                                <th>Scheme Name</th>
                                <th>Folio Number</th>
                                <th>SIP From</th>
                                <th>SIP To</th>
                                <th>Amount</th>
                                <th></th>
                              </tr>
                              <tr>
                                <td>Profile</td>
                                <td>Scheme Name</td>
                                <td>Folio Number</td>
                                <td>20/10/2021</td>
                                <td>20/11/2021</td>
                                <td>3500</td>
                                <td>
                                  <a href="javascript:void(0)" >
                                    <FaTrash className="red" />
                                  </a>
                                </td>
                              </tr>
                            </table>
                            </div>
                            <div className="row">
                              <div className='col-md-6 col-lg-5'>
                                <div className="form-group pmd-textfield pmd-textfield-floating-label">
                                  <label for="mandate" className='lb text-label'>Select Mandate<span className="text-danger">*</span></label><br />
                                  <Select className='' options={mandate} />
                                </div>
                                <a href="/prodigypro/dashboard/bank-and-mandate" className='red fs-13'>Create Mandate</a>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                      <div className="modal-footer border-0">
                        <button data-dismiss="modal" className="new-btn1" type="button">Order Now</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            {/* <!--End Form trigger modal SIP --> */}
            {/* <!-- Form trigger modal new Purchase --> */}
            <section className="new-purchase">
              <div className="row">
                <div tabindex="-1" className="modal pmd-modal fade" id="form-dialog-new-purchase" aria-hidden="true">
                  <div className="modal-dialog modal-lg">
                    <div className="modal-content b-r p-3 bg-gray">
                      <div className="modal-header border-0">
                        <button aria-hidden="true" data-dismiss="modal" className="close" type="button">×</button>
                      </div>
                      <div className="modal-body">
                        <form>
                          <div className='row'>
                            <div className='col-md-6'>
                              <div className="form-group pmd-textfield pmd-textfield-floating-label">
                                <label for="select-profile" className='text-label'>Select Profile<span className="text-danger">*</span></label><br />
                                <Select className='' options={profile} />
                              </div>
                            </div>
                            <div className='col-md-6'>
                              <div className="form-group pmd-textfield pmd-textfield-floating-label">
                                <label for="amc" className='text-label'>Select AMC<span className="text-danger">*</span></label><br />
                                <Select className='' options={amc} />
                              </div>
                            </div>
                          </div>
                          <div className='row'>
                            <div className='col-md-6'>
                              <p className=" mb-1 mt-1 p-radio lb text-label ml-3">
                                Option
                                <spna className="text-danger">*</spna>
                              </p>
                              <div className='pt-2'>
                                <input className=" input-text ml-3" id="growth" type="radio" name="growth" value="GROWTH" />
                                <label htmlFor="growth" className="">Growth</label>
                                <input className="input-text ml-3" id="dividend" type="radio" name="growth" value="Dividend" />
                                <label htmlFor="dividend" className="">IDCW  </label>
                              </div>
                            </div>
                            <div className='col-md-6'>
                              <div className="form-group pmd-textfield pmd-textfield-floating-label">
                                <label for="select-scheme" className='lb text-label'>Select Scheme<span className="text-danger">*</span></label><br />
                                <Select className='' options={scheme} />
                              </div>
                            </div>
                          </div>
                          <div className="row">
                          <div className='col-md-6'>
                            <div className="form-group pmd-textfield pmd-textfield-floating-label">
                              <label className="control-label lb text-label" for="amount">Enter Amount<span className="text-danger">*</span></label>
                              <input type="text" className="form-control" name='amount' placeholder='Enter Amount' />
                            </div>
                          </div>
                          </div>
                          <div className="col-md-12 ">
                            <a href="javascript:void(0)" className="btn btn-danger float-right my-4" >+ Add</a>
                          </div>
                          <div className="col-md-12 " id="tbt">
                            <div className="table-responsive">
                            <table className="table bg-white mt-5 mb-3">
                              <tr>
                                <th>Profile</th>
                                <th>Scheme Name</th>
                                <th>Folio Number</th>
                                <th>Amount</th>
                                <th></th>
                              </tr>
                              <tr>
                                <td>Profile</td>
                                <td>Scheme Name</td>
                                <td>Folio Number</td>
                                <td>3500</td>
                                <td>
                                  <a href="javascript:void(0)" >
                                    <FaTrash className="red" />
                                  </a>
                                </td>
                              </tr>
                            </table>
                            </div>
                            <div className="row">
                              <div className='col-md-5'>
                                <div className="form-group pmd-textfield pmd-textfield-floating-label">
                                  <label htmlFor="payment_mode" className="text-label">
                                    Select Payment Mode
                                    <spna className="text-danger">*</spna>
                                  </label>
                                  <br />
                                  <Select className='' options={payment_mode} />
                                </div>
                              </div>
                              <div className='col-md-7'>
                                <div className="pt-4 mt-3">
                                  <input className=" input-text" id="emailLink" type="radio" name="payType" value="N" />
                                  <label htmlFor="emailLink" className="">Link On Email</label>
                                  <input className="input-text ml-3" id="immediatePay" type="radio" name="payType" value="Y" />
                                  <label htmlFor="immediatePay" className="">Immediate Payment</label>
                                </div>
                              </div>
                              <div className='col-md-5'>
                                <div className="form-group pmd-textfield pmd-textfield-floating-label">
                                  <label for="select-bank" className='lb text-label'>Select Bank<span className="text-danger">*</span></label><br />
                                  <Select className='' options={bankname} />
                                </div>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                      <div className="modal-footer border-0">
                        <button data-dismiss="modal" className="new-btn1" type="submit">Order Now</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            {/* <!-- End Form trigger modal new Purchase --> */}
            {/*Purchase Modal lumpsum */}
            <div className="modal fade" id="lumpsum" tabIndex={-1} role="dialog" aria-labelledby="sipTitle" aria-hidden="true">
              <div className="modal-dialog modal-dialog-centered" role="document">
                <div className="modal-content bg-light-red">
                  <div id='cart-l'>
                    <div className="modal-header">
                      <h5 className="modal-title text-dark" id="sipTitle">My Cart</h5>
                      <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">×</span>
                      </button>
                    </div>
                    <div className="modal-body">
                      <h6 className='text-black fs-16'>Schemes Name</h6>
                      <div class="col-md-12">
                        <div className=' row shadowcart mb-2 p-3'>
                          <div className='col-md-2'><img src={idfc} alt="" className='img-fluid' /></div>
                          <div className='col-md-9'><a href='!#'>IDFC Tax Advantage (ELSS)
                            Fund-Growth-Regular Plan  </a>
                          </div>
                          <div className='col-md-1 pt-2'>
                            <FaTrash className='red' />
                          </div>
                        </div>
                        <div className=' row shadowcart mb-2 p-3'>
                          <div className='col-md-2'><img src={sunderam} alt="" className='img-fluid' /></div>
                          <div className='col-md-9'><a href='!#'>Sundaram Tax Savings Fund (Formerly Principal Tax Savings Fund)-
                            Regular Growth </a>
                          </div>
                          <div className='col-md-1 pt-3'>
                            <FaTrash className='red'/>
                          </div>
                        </div>
                        <div className=' row shadowcart mb-2 p-3'>
                          <div className='col-md-2'><img src={sunderam} alt="" className='img-fluid' /></div>
                          <div className='col-md-9'><a href='!#'>Sundaram Tax Savings Fund (Formerly Principal Tax Savings Fund)-
                            Regular Growth </a>
                          </div>
                          <div className='col-md-1 pt-3'>
                            <FaTrash className='red' />
                          </div>
                        </div>
                        <div className=' row shadowcart mb-2 p-3'>
                          <div className='col-md-2'><img src={sbi} alt="" className='img-fluid' /></div>
                          <div className='col-md-9'><a href='!#'>SBI Long Term Equity Fund-Regular
                            Plan-Growth  </a>
                          </div>
                          <div className='col-md-1 pt-2'>
                            <FaTrash className='red' />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="modal-footer border-0">
                      <a type="button" className="btn btn-danger shadow-sm" href="javascript:void(0);" onClick={this.hide1}>Continue</a>
                    </div>
                  </div>
                  {/* form  */}
                  <div id='formhide-l'>
                    <div className="modal-header">
                      <h5 className="modal-title text-black" id="sipTitle">Investment Details</h5>
                      <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">×</span>
                      </button>
                    </div>
                    <div className="modal-body ">
                      <form>
                        <div className="col mb-3 ">
                          <label htmlFor="Profile" className='text-label' >Select Profile <span className='text-danger'>*</span></label>
                          <Select className='bg-c' options={profile} />
                        </div>
                      </form>
                      <div className='cartitemwith'>
                        <div className='row p-4'>
                          <div className='col-md-12  red '>SBI Long Term Equity Fund-Regular
                            Plan-Growth
                          </div>
                        </div>
                        <div className="col bg-white py-3 px-4">
                          <label htmlFor="Profile" className='fs-14' >Select Folio <span className='text-danger'>*</span></label>
                          <Select className='border-pop' options={folio} />
                        </div>
                        <div className="col mb-3 bg-white pb-4 px-4 lastin">
                          <label htmlFor="amount" className='fs-14' >Enter Amount <span className='text-danger'>*</span></label>
                          <input type="text" className="form-control border-pop " name="amount" placeholder='Enter Amount' />
                        </div>
                      </div>
                    </div>
                    <div className="modal-footer border-0">
                      <a type="button" className="btn btn-danger shadow-sm" href="javascript:void(0);" onClick={this.hide2}>Continue</a>
                    </div>
                  </div>
                  <div id="confirm-l">
                    <div className="modal-header">
                      <h5 className="modal-title text-black" id="sipTitle">Confirm Purchase</h5>
                      <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">×</span>
                      </button>
                    </div>
                    <div className="modal-body ">
                      <div className="col mb-3 border-bottom">
                        <h6 className='red'>Shivam Shrivastav</h6>
                        <p>Mode of Holding :  Single <a href="#" className='p-4 ml-5'>Edit</a></p>
                      </div>
                      <p className='red'>Axis Long Term Equity Fund-Regular-Growth</p>
                      <table className='mx-auto'>
                        <tr className='text-center'>
                          <td className='pr-4'>Folio  </td>
                          :
                          <td className='pl-4'>124564</td>
                        </tr>
                        <tr className='text-center'>
                          <td className='pr-4'>Amount  </td>
                          :
                          <td className='pl-4'>50,000</td>
                        </tr>
                      </table>
                      <p className='text-center pt-5'><b>Total  :  55,55882</b></p>
                    </div>
                    <div className="modal-footer border-0">
                      <a type="button" className="btn btn-danger shadow-sm" href="javascript:void(0);" onClick={this.hide3}>Continue</a>
                    </div>
                  </div>
                  <div id='purchase-l'>
                    <div className="modal-header">
                      <h5 className="modal-title text-black" id="sipTitle"> Purchase</h5>
                      <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">×</span>
                      </button>
                    </div>
                    <div className="modal-body ">
                      <form>
                        <p className='red'>Investment Total : 5000.00</p>
                        <div className="col mb-3 ">
                          <label htmlFor="Profile" className='text-label' >Select Payment Mode <span className='text-danger'>*</span></label>
                          <Select className='bg-c' options={profile} />
                        </div>
                        <div className="col mb-3 ">
                          <label htmlFor="Profile" className='text-label' >Select Bank <span className='text-danger'>*</span></label>
                          <Select className='bg-c' options={profile} />
                        </div>
                        <div className="pt-4 mt-3">
                          <input className=" input-text" id="emailLink" type="radio" name="payType" value="N" />
                          <label htmlFor="emailLink" className="">Link On Email</label>
                          <input className="input-text ml-3" id="immediatePay" type="radio" name="payType" value="Y" />
                          <label htmlFor="immediatePay" className="">Immediate Payment</label>
                        </div>
                      </form>
                    </div>
                    <div className="modal-footer border-0">
                      <a type="button" className="btn btn-danger shadow-sm" href="javascript:void(0);" >Order</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* sip */}
            <div className="modal fade" id="sip" tabIndex={-1} role="dialog" aria-labelledby="sipTitle" aria-hidden="true">
              <div className="modal-dialog modal-dialog-centered" role="document">
                <div className="modal-content bg-light-red">
                  <div id='cart-s'>
                    <div className="modal-header">
                      <h5 className="modal-title text-dark" id="sipTitle">My Cart</h5>
                      <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">×</span>
                      </button>
                    </div>
                    <div className="modal-body">
                      <h6 className='text-black fs-16'>Schemes Name</h6>
                      <div class="col-md-12">
                        <div className=' row shadowcart mb-2 p-3'>
                          <div className='col-md-2'><img src={idfc} alt="" className='img-fluid' /></div>
                          <div className='col-md-9'><a href='!#'>IDFC Tax Advantage (ELSS)
                            Fund-Growth-Regular Plan  </a>
                          </div>
                          <div className='col-md-1 pt-2'>
                            <FaTrash className='red'/>
                          </div>
                        </div>
                        <div className=' row shadowcart mb-2 p-3'>
                          <div className='col-md-2'><img src={sunderam} alt="" className='img-fluid' /></div>
                          <div className='col-md-9'><a href='!#'>Sundaram Tax Savings Fund (Formerly Principal Tax Savings Fund)-
                            Regular Growth </a>
                          </div>
                          <div className='col-md-1 pt-3'>
                            <FaTrash className='red'/>
                          </div>
                        </div>
                        <div className=' row shadowcart mb-2 p-3'>
                          <div className='col-md-2'><img src={sunderam} alt="" className='img-fluid' /></div>
                          <div className='col-md-9'><a href='!#'>Sundaram Tax Savings Fund (Formerly Principal Tax Savings Fund)-
                            Regular Growth </a>
                          </div>
                          <div className='col-md-1 pt-3'>
                            <FaTrash className='red'/>
                          </div>
                        </div>
                        <div className=' row shadowcart mb-2 p-3'>
                          <div className='col-md-2'><img src={sbi} alt="" className='img-fluid' /></div>
                          <div className='col-md-9'><a href='!#'>SBI Long Term Equity Fund-Regular
                            Plan-Growth  </a>
                          </div>
                          <div className='col-md-1 pt-2'>
                            <FaTrash className='red' />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="modal-footer border-0">
                      <a type="button" className="btn btn-danger shadow-sm" href="javascript:void(0);" onClick={this.hides1}>Continue</a>
                    </div>
                  </div>
                  {/* form  */}
                  <div id="form-s">
                    <div className="modal-header">
                      <h5 className="modal-title text-black" id="sipTitle">SIP</h5>
                      <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">×</span>
                      </button>
                    </div>
                    <div className="modal-body ">
                      <form>
                        <div className="col mb-3 ">
                          <label htmlFor="Profile" className='text-label' >Select Profile <span className='text-danger'>*</span></label>
                          <Select className='bg-c' options={profile} />
                        </div>
                        <div className="col mb-3 ">
                          <label htmlFor="Profile" className='text-label' >Select Mandate <span className='text-danger'>*</span></label>
                          <Select className='bg-c' options={profile} />
                        </div>
                        <a href="!#" className="red fs-13">Create Mandate</a>
                      </form>
                    </div>
                    <div className="modal-footer border-0">
                      <a type="button" className="btn btn-danger shadow-sm" href="javascript:void(0);" onClick={this.hides4}>Continue</a>
                    </div>
                  </div>
                  <div id='formhide-s'>
                    <div className="modal-header">
                      <h5 className="modal-title text-black" id="sipTitle">SIP Cart</h5>
                      <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">×</span>
                      </button>
                    </div>
                    <div className="modal-body ">
                      <div className='cartitemwith'>
                        <div className='row p-4'>
                          <div className='col-md-12  red '>SBI Long Term Equity Fund-Regular
                            Plan-Growth
                          </div>
                        </div>
                        <div className="col bg-white py-3 px-4">
                          <label htmlFor="Profile" className='fs-14' >Select Folio <span className='text-danger'>*</span></label>
                          <Select className='border-pop' options={folio} />
                        </div>
                        <div className="col  bg-white pb-1 px-4">
                          <label htmlFor="amount" className='fs-14' >Enter Amount <span className='text-danger'>*</span></label>
                          <input type="text" className="form-control amount-b" name="amount" placeholder='Enter Amount' />
                        </div>
                        <div className="col bg-white py-3 px-4">
                          <label htmlFor="date" className='fs-14' >Select Date <span className='text-danger'>*</span></label>
                          <Select className='border-pop' options={date} />
                        </div>
                        <div className="col bg-white py-3 px-4">
                          <label htmlFor="date" className='fs-14' >Select Period <span className='text-danger'>*</span></label>
                          <Select className='border-pop' options={selectperiod} />
                        </div>
                        <div className='col mb-2 lastin'>
                          <input className="input-text p-2" id="perpetual" type="checkbox" name="perpetual" value="Y" defaultChecked />
                          <input type="hidden" id="perpetual_val" name="perpetual_val" />
                          <label htmlFor="perpetual" className="ml-2">
                            Perpetual
                            <spna className="text-danger">*</spna>
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="modal-footer border-0">
                      <a type="button" className="btn btn-danger shadow-sm" href="javascript:void(0);" onClick={this.hides2}>Continue</a>
                    </div>
                  </div>
                  <div id="confirm-s">
                    <div className="modal-header">
                      <h5 className="modal-title text-black" id="sipTitle">SIP Confirmation Purchase</h5>
                      <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">×</span>
                      </button>
                    </div>
                    <div className="modal-body ">
                      <div className="col mb-3 border-bottom">
                        <h6 className='red'>Shivam Shrivastav</h6>
                        <p>Mode of Holding :  Single <a href="#" className='p-4 ml-5'>Edit</a></p>
                      </div>
                      <p className='red'>Axis Long Term Equity Fund-Regular-Growth</p>
                      <table className='mx-auto'>
                        <tr className='text-center'>
                          <td className='pr-4'>Folio </td>
                          :
                          <td className='pl-4'>124564</td>
                        </tr>
                        <tr className='text-center'>
                          <td className='pr-4'>Amount  </td>
                          :
                          <td className='pl-4'>50,000</td>
                        </tr>
                        <tr className='text-center'>
                          <td className='pr-4'>SIP From  </td>
                          :
                          <td className='pl-4'>11-sep-2022</td>
                        </tr>
                        <tr className='text-center'>
                          <td className='pr-4'>SIP To </td>
                          :
                          <td className='pl-4'>31-dec-2099</td>
                        </tr>
                        <tr className='text-center'>
                          <td className='pr-4'>SIP Date </td>
                          :
                          <td className='pl-4'>11</td>
                        </tr>
                      </table>
                      <p className='text-center pt-5'><b>Total  :  55,55882</b></p>
                    </div>
                    <div className="modal-footer border-0">
                      <a type="button" className="btn btn-danger shadow-sm" href="javascript:void(0);" onClick={this.hides3}>Order</a>
                    </div>
                  </div>
                  {/* ================successful transaction===============  */}
                  <div id="success">
                    <div className="modal-body py-0">
                      <div className="text-center">
                        <img className="img-fluid" src={success} alt="" />
                        <h5 className="success-c font-weight-500 py-3">Successful Transaction with
                          Following Details
                        </h5>
                      </div>
                      <div className="row px-2">
                        <div className="col-md-4 col-sm-4 text-left text-black">Unique Number </div>
                        <div className="col-md-1 col-sm-1">:</div>
                        <div className="col-md-7 col-sm-7 text-left">12345678</div>
                        </div>
                        <div className="row px-2">
                        <div className="col-md-4  col-sm-4 text-left text-black">Trxn Number </div>
                        <div className="col-md-1 col-sm-1">:</div>
                        <div className="col-md-7 col-sm-7 text-left">12346195</div>
                        </div>
                        <div className="row px-2">
                        <div className="col-md-4  col-sm-4 text-left text-black">Fund </div>
                        <div className="col-md-1 col-sm-1">:</div>
                        <div className="col-md-7 col-sm-7 text-left">Axis Mutual Fund</div>
                        </div>
                        <div className="row px-2">
                        <div className="col-md-4  col-sm-4 text-left text-black">Folio Number </div>
                        <div className="col-md-1 col-sm-1">:</div>
                        <div className="col-md-7  col-sm-7 text-left">12345678</div>  
                        </div>
                        <div className="row px-2">                      
                        <div className="col-md-4  col-sm-4 text-left text-black">Amount   </div>
                        <div className="col-md-1 col-sm-1">:</div>
                        <div className="col-md-7 col-sm-7 text-left">1,000</div>
                        </div>
                        <div className="row px-2">
                        <div className="col-md-4  col-sm-4 text-left text-black">Status   </div>
                        <div className="col-md-1 col-sm-1">:</div>
                        <div className="col-md-7  col-sm-7 text-left">successful</div>
                        </div>
                        <div className="row px-2">
                        <div className="col-md-4  col-sm-4 text-left text-black">Scheme Name </div>
                        <div className="col-md-1 col-sm-1">:</div>
                        <div className="col-md-7 col-sm-7 text-left">Axis Arbitrage Fund - Regular Growth</div>
                      </div>
                      <hr />
                      <div className="text-center">
                        <p className="text-black fs-13">
                          <span className="red text-left font-weight-500 fs-16">Note:</span>  Authorization link has been sent on your registered mail id and mobile number.The Link shall remain active for the next 48 hours.Kindly authorize.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Footer */}
            <Footer />
            {/* End of Footer */}
          </div>
          {/* End of Content Wrapper */}
        </div>
        {/* End of Page Wrapper */}
      </>
    )
  }
}
export default Tax_Planning