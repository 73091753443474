import React from "react";
import Header from './header';
import Footer from './footer';
import $ from "jquery";
import sunderam from "../../assets/images/icons/New folder (2)/Sundaram_Mutual_Fund.png";
import sbi from "../../assets/images/icons/New folder (2)/sbi mutual fund.png";
import idfc from "../../assets/images/icons/New folder (2)/IDFC.png";
import icon from "../../assets/images/icons/New folder (2)/Icon.png";
import Select from 'react-select';
import success from "../../assets/images/icons/New folder (2)/successfully.png";
// import SelectVariants from "./floating";
// import BasicDatePicker from "./floating";
class NFO extends React.Component {
hideScheme() {
$("#prod_div").css({ "display": "none" });
$("#single_div").css({ "display": "block" });
}
render() {
const payment_mode = [
{ value: "Net Banking", label: "Net Banking" },
{ value: "UPI", label: "UPI" },
{ value: "RTGS/NEFT", label: "RTGS/NEFT" },
{ value: "Debit Mandate", label: "Debit Mandate" },
];
const profile = [
  { value: "select", label: "select" },
 
  ];
return (
<>
<style>
  {
  `
  #single_div
  {
  display:none;
  }
  `
  }
</style>
<div id="content-wrapper" className="d-flex flex-column">
  {/* Main Content */}
  <div id="content">
    {/* Topbar */}
    <Header />
    {/* End of Topbar */}
    {/* Begin Page Content */}
    <div className="container-fluid nfo">
      {/* Page Heading */}
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb">
          <li className="breadcrumb-item"><a href="home">Home</a></li>
          <li className="breadcrumb-item active" aria-current="page">NFO</li>
        </ol>
      </nav>
      <div id="prod_div">
        <div className="col-md-6 offset-md-3 mb-3 mt-5 bs" >
          <ul class="nav nav-pills nav-fill navtop">
            <li class="nav-item">
              <a class="nav-link active fs-16 " href="#menu1" data-toggle="tab" >Equity</a>
            </li>
            <li class="nav-item">
              <a class="nav-link fs-16" href="#menu2" data-toggle="tab" >Debt</a>
            </li>
          </ul>
        </div>
        <div class="tab-content mt-3 ">
          <div class="tab-pane show active" id="menu1" role="tabpanel">
            <div className="row justify-content-center pb-5">
              <div className="col-md-9" >
                <div className="card-body shadow-custom text-left ">
                  <div className="col-12 table-responsive">
                    <table class="table">
                      <tbody >
                        <tr>
                          <td><img src={idfc} alt="" className='img-fluid min-w-3em' /></td>
                          <td className="min-w-35em"><a href='javascript:void(0);' className="text-black" onClick={this.hideScheme}>IDFC Tax Advantage (ELSS)
                            Fund-Growth-Regular Plan  </a> 
                          </td>
                          <td ><a href="#" className="btn-invest"> Invest</a></td>
                        </tr>
                        <tr>
                          <td><img src={sunderam} alt="" className='img-fluid' /></td>
                          <td className='pt-4 '><a href='!#' className="text-black">Sundaram Tax Savings Fund (Formerly Principal Tax Savings Fund)-
                            Regular Growth </a> 
                          </td>
                          <td className='pt-4 '><a href="#" className="btn-invest"> Invest</a></td>
                        </tr>
                        <tr>
                          <td><img src={sunderam} alt="" className='img-fluid' /></td>
                          <td className='pt-4 '><a href='!#' className="text-black">Sundaram Tax Savings Fund (Formerly Principal Tax Savings Fund)-
                            Regular Growth </a> 
                          </td>
                          <td className='pt-4 '><a href="#" className="btn-invest"> Invest</a></td>
                        </tr>
                        <tr>
                          <td><img src={sbi} alt="" className='img-fluid' /></td>
                          <td className='pt-4 '><a href='!#' className="text-black">SBI Long Term Equity Fund-Regular
                            Plan-Growth  </a>
                          </td>
                          <td className='pt-4 '><a href="#" className="btn-invest"> Invest</a></td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="tab-pane show" id="menu2" role="tabpanel">
            <div className="row justify-content-center pb-5">
              <div className="col-md-9" >
                <div className="card-body shadow-custom text-left ">
                  <div className="col-12 table-responsive">
                    <table class="table">
                      <tbody >
                        <tr>
                          <td><img src={sunderam} alt="" className='img-fluid min-w-3em' /></td>
                          <td className='pt-4 min-w-35em'><a href='!#' className="text-black">Sundaram Tax Savings Fund (Formerly Principal Tax Savings Fund)-
                            Regular Growth </a> 
                          </td>
                          <td className='pt-4 '><a href="#" className="btn-invest"> Invest</a></td>
                        </tr>
                        <tr>
                          <td><img src={sunderam} alt="" className='img-fluid' /></td>
                          <td className='pt-4 '><a href='!#' className="text-black">Sundaram Tax Savings Fund (Formerly Principal Tax Savings Fund)-
                            Regular Growth </a> 
                          </td>
                          <td className='pt-4 '><a href="#" className="btn-invest"> Invest</a></td>
                        </tr>
                        <tr>
                          <td><img src={sbi} alt="" className='img-fluid' /></td>
                          <td className='pt-4 '><a href='!#' className="text-black">SBI Long Term Equity Fund-Regular
                            Plan-Growth  </a>
                          </td>
                          <td className='pt-4 '><a href="#" className="btn-invest"> Invest</a></td>
                        </tr>
                        <tr>
                          <td><img src={idfc} alt="" className='img-fluid' /></td>
                          <td><a href='javascript:void(0);' className="text-black" onClick={this.hideScheme}>IDFC Tax Advantage (ELSS)
                            Fund-Growth-Regular Plan  </a> 
                          </td>
                          <td ><a href="#" className="btn-invest"> Invest</a></td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* =====================scheme detail======================== */}
      <div id="single_div">
        <div className="row justify-content-center pb-5">
          <div className="col-md-10" >
            <div className="card-body shadow-custom text-left m-sm-auto">
              <div className="col-12 table-responsive">
                <table className='w-100'>
                  <tr>
                    <td className='px-lg-4'><img src={idfc} alt="" className='img-fluid min-w-3em' /></td>
                    <td className='px-lg-4 min-w-35em'>IDFC Tax Advantage (ELSS)
                      Fund-Growth-Regular Plan  
                    </td>
                    <td className='px-lg-4 min-w-14em'>Very High risk - Other - Index</td>
                  </tr>
                </table>
              </div>
              <div className="col-md-12 py-5 pl-lg-5 ml-lg-5 table-responsive">
                <table>
                  <tr>
                    <td className='px-lg-5 text-label min-w-8em'>Launch date
                    </td>
                    <td className='px-lg-5 text-label min-w-8em'>End Date
                    </td>
                    <td className='px-lg-5 text-label min-w-8em'>Allotment date
                    </td>
                    <td className='px-5 text-label min-w-8em'>NAV
                    </td>
                  </tr>
                  <tr>
                    <td className='px-lg-5 pt-3'>19th Aug 2022</td>
                    <td className='px-lg-5 pt-3'>26th Aug 2022  </td>
                    <td className='px-lg-5 pt-3'>2nd Sept 2022</td>
                    <td className='px-5 pt-3'>₹10 </td>
                  </tr>
                </table>
              </div>
              <div className="col-md-6 offset-md-3 col-lg-4 offset-lg-4 nfo-alert">
                <h6 className="py-2"><img src={icon} alt="" /><span className="pl-4">NFO will close today.</span></h6>
              </div>
              <div className="col-md-12">
                <div className="">
                  <div className="p-lg-4">
                    <div className="accordion" id="accordionExample">
                      <div className=" border-top border-bottom ">
                        <div className="card-head" id="headingOne">
                          <h2 className="my-2 collapsed" data-toggle="collapse" data-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                            <div className='row fs-16 py-2 '>
                              <div className='col-md-9 text-left pl-lg-5 fs-sm-13'>Fund house & investment objective</div>
                            </div>
                          </h2>
                        </div>
                        <div id="collapseOne" className="collapse pl-lg-4" aria-labelledby="headingOne" data-parent="#accordionExample">
                          <div className="row">
                            <div className="col-md-8 py-3 pl-4">
                              <img src={idfc} alt="" className='img-fluid' />
                              <span className="text-black font-weight-500 pl-4">  IDFC Tax Advantage (ELSS) Fund-Growth-Regular Plan  </span>
                            </div>
                          </div>
                          <div className="row pb-3 ">
                            <div className="col-md-6 py-3 border-right pl-4">
                              <div className="row">
                                <div className="col-md-6 text-black">
                                  <h6>Rank (Total assets) </h6>
                                </div>
                                <div className="col-md-6">
                                  <h6> #2 India </h6>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-md-6 text-black">
                                  <h6>Total AUM</h6>
                                </div>
                                <div className="col-md-6">
                                  <h6>700 cr </h6>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="row">
                                <div className="col-md-6 text-black">
                                  <h6>Date of Incorportion</h6>
                                </div>
                                <div className="col-md-6">
                                  <h6>26th Aug 2022 </h6>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-12 text-justify pb-2">
                            Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries.
                          </div>
                          <div className="col-md-12 text-justify pb-2">
                            <span className="text-black font-weight-500 fs-14">About IDFC Tax Advantage (ELSS) Fund-Growth-Regular Plan </span>
                            <br />  <span>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here</span>
                          </div>
                          <div className="col-md-12 text-justify pb-2">
                            <span className="text-black font-weight-500 fs-14">Investment Objective </span>
                            <br />  <span>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here</span>
                          </div>
                        </div>
                      </div>
                      <div className="">
                        <div className="card-head " id="headingTwo">
                          <h2 className="my-2 collapsed" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                            <div className='row fs-16 py-2 '>
                              <div className='col-md-9 text-left pl-lg-5 fs-sm-13'>Minimum Investment Amounts</div>
                            </div>
                          </h2>
                        </div>
                        <div id="collapseTwo" className="collapse" aria-labelledby="headingTwo" data-parent="#accordionExample">
                          <div className="row">
                            <div className="col-md-11 pr-5rem text-left">
                              <div className="row pb-3">
                                <div className="col-md-8 py-3 border-right pl-lg-5">
                                  <div className="row">
                                    <div className="col-md-6 text-black">
                                      <h6>Min. for 1st investment</h6>
                                    </div>
                                    <div className="col-md-6">
                                      <h6> ₹500</h6>
                                    </div>
                                  </div>
                                  <div className="row">
                                    <div className="col-md-6 text-black">
                                      <h6>Min. for 2nd investment</h6>
                                    </div>
                                    <div className="col-md-6">
                                      <h6>₹100 </h6>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-4">
                                  <div className="row">
                                    <div className="col-md-6 text-black">
                                      <h6>Min. for SIP</h6>
                                    </div>
                                    <div className="col-md-6">
                                      <h6>₹100  </h6>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className=" border-top">
                        <div className="card-head " id="headingThree">
                          <h2 className="my-2 collapsed" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                            <div className='row fs-16 py-2'>
                              <div className='col-md-9 text-left pl-lg-5 fs-sm-13'>Exit Loads & Tax Implications</div>
                            </div>
                          </h2>
                        </div>
                        <div id="collapseThree" className="collapse" aria-labelledby="headingThree" data-parent="#accordionExample">
                          <div className="row">
                            <div className="col-md-11 pr-5rem text-left ">
                              <div className="col-md-12 text-justify pb-2 pl-lg-5">
                                <span className="text-black font-weight-500 fs-14">Expence ratio :0.11%</span>
                                <br />  <span>Inclusive of GST</span>
                              </div>
                              <div className="col-md-12 text-justify pb-2 pl-lg-5">
                                <span className="text-black font-weight-500 fs-14">Exit load</span>
                                <br />  <span>Exit load of 1% for investments beyond 10% if redeemed before 12 months</span>
                              </div>
                              <div className="col-md-12 text-justify pb-2 pl-lg-5">
                                <span className="text-black font-weight-500 fs-14">Stamp Duty</span>
                                <br />  <span> before 12 months</span>
                              </div>
                              <div className="col-md-12 text-justify pb-2 pl-lg-5">
                                <span className="text-black font-weight-500 fs-14">Tax Implication</span>
                                <br />  <span> long established fact that a reader will be distracted</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-12 py-4">
                <button className='btn-custom'>Add to Cart</button>
                <a href="javascript:void(0);" className='btn-custom float-right' data-target="#nfo-page" data-toggle="modal" type="button">Invest Now</a>
              </div>
              {/* ===========================Message model============================= */}
              <div className="modal fade" id="nfo-page" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true" data-keyboard="false" data-backdrop="static">
                <div className="modal-dialog modal-dialog-centered " role="document">
                  <div className="modal-content br-15 bg-gray">
                    <div className="modal-header">
                      <h5 className="modal-title text-black" id="sipTitle">NFO</h5>
                      <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                      <span aria-hidden="true">×</span>
                      </button>
                    </div>
                    <div className="modal-body ">
                      <div className="row">
                       
                        <div className="col-md-12 red py-3">Axis Long Term Equity Fund-
                          Regular Plan-Growth (Eastwhile Kotak 50 Scheme) (705527/17)
                        </div>
                        <div className="col-md-12">
                          <form>
                          <div className="col mb-3 ">
                              <label htmlFor="Profile" className='text-label' >Select Profile <span className='text-danger'>*</span></label>
                              <Select className='bg-c' options={profile} />
                            </div>
                            <div className="col mb-3 ">
                              <label htmlFor="amount" className='text-label' >Enter Amount <span className='text-danger'>*</span></label>
                              <input type="text" className="form-control bg-c" name="amount" />
                            </div>
                            <div className="col mb-3 ">
                              <label htmlFor="payment" className='text-label' >Select Payment Mode <span className='text-danger'>*</span></label>
                              <Select className='bg-c' options={payment_mode} />
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                    <div className="modal-footer border-0">
                    <button data-dismiss="modal" className="new-btn1" data-target="#success-transaction-redem" data-toggle="modal" type="button">Order Now</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
     {/* successful transaction*/}
     <section className="success-trans">
                          <div className="row">
                            <div tabindex="-1" className="modal pmd-modal fade" id="success-transaction-redem" aria-hidden="true">
                              <div className="modal-dialog  modal-dialog-centered">
                                <div className="modal-content b-r p-1 bg-white text-center">
                                  <div className="text-right pt-2">
                                    <button aria-hidden="true" data-dismiss="modal" className="close" type="button">×</button>
                                  </div>
                                  <div className="modal-body py-0">
                      <div className="text-center">
                        <img className="img-fluid" src={success} alt="" />
                        <h6 className="success-c font-weight-500 py-3 fs-19">Successful Transaction with
                          Following Details
                        </h6>
                      </div>
                      <div className="row px-2">
                        <div className="col-md-4 col-sm-4 text-left text-black">Unique Number </div>
                        <div className="col-md-1 col-sm-1">:</div>
                        <div className="col-md-7 col-sm-7 text-left">12345678</div>
                        </div>
                        <div className="row px-2">
                        <div className="col-md-4  col-sm-4 text-left text-black">Trxn Number </div>
                        <div className="col-md-1 col-sm-1">:</div>
                        <div className="col-md-7 col-sm-7 text-left">12346195</div>
                        </div>
                        <div className="row px-2">
                        <div className="col-md-4  col-sm-4 text-left text-black">Fund </div>
                        <div className="col-md-1 col-sm-1">:</div>
                        <div className="col-md-7 col-sm-7 text-left">Axis Mutual Fund</div>
                        </div>
                        <div className="row px-2">
                        <div className="col-md-4  col-sm-4 text-left text-black">Folio Number </div>
                        <div className="col-md-1 col-sm-1">:</div>
                        <div className="col-md-7  col-sm-7 text-left">12345678</div>  
                        </div>
                        <div className="row px-2">                      
                        <div className="col-md-4  col-sm-4 text-left text-black">Amount   </div>
                        <div className="col-md-1 col-sm-1">:</div>
                        <div className="col-md-7 col-sm-7 text-left">1,000</div>
                        </div>
                        <div className="row px-2">
                        <div className="col-md-4  col-sm-4 text-left text-black">Status   </div>
                        <div className="col-md-1 col-sm-1">:</div>
                        <div className="col-md-7  col-sm-7 text-left">Ok</div>
                        </div>
                        <div className="row px-2">
                        <div className="col-md-4  col-sm-4 text-left text-black">Scheme Name </div>
                        <div className="col-md-1 col-sm-1">:</div>
                        <div className="col-md-7 col-sm-7 text-left">Axis Arbitrage Fund - Regular Growth</div>
                      </div>
                      <hr />
                      <div className="">
                        <p className="text-black fs-13">
                          <span className="red text-left font-weight-500 fs-16">Note:</span>  Authorization link has been sent on your registered mail id and mobile number.The Link shall remain active for the next 48 hours.Kindly authorize.
                        </p>
                      </div>
                    </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </section>
                        {/* successful transaction  end */}
    {/* Footer */}
    <Footer />
    {/* End of Footer */}
  </div>
  {/* End of Content Wrapper */}
</div>
{/* End of Page Wrapper */}
</>
)
}
}
export default NFO