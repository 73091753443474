import React from "react";
import Header from './header';
import Footer from './footer';
import { Helmet } from "react-helmet";
import 'animate.css';
// import $ from "jquery";
import New_purchase from "../../assets/images/icons/New folder (2)/new_puchase.png";
import Additional_puchase from "../../assets/images/icons/New folder (2)/add_puchase.png";
import switch1 from "../../assets/images/icons/New folder (2)/switch.png";
import sip from "../../assets/images/icons/New folder (2)/bar_chart.png";
import stp from "../../assets/images/icons/New folder (2)/STP.png";
import swp from "../../assets/images/icons/New folder (2)/withdraw.png";
import redemption from "../../assets/images/icons/New folder (2)/redemption.png";
import success from "../../assets/images/icons/New folder (2)/successfully.png";
import { FaTrash } from "react-icons/fa";
import { DatePickerComponent } from "@syncfusion/ej2-react-calendars";
import opps from "../../assets/images/icons/New folder (2)/oops.png";

class Transact extends React.Component {
hide = () => {
}
render() {


return (
<>
<Helmet>
<title>Transact - Prodigy Pro</title>
</Helmet>

{/* Page Wrapper */}
<div id="wrapper">
  {/* <div id="overlay" >
    <div className="spinner"></div>
    <br />Loading...
  </div>          */}
  {/* Content Wrapper */}
  <div id="content-wrapper" className="d-flex flex-column">
    {/* Main Content */}
    <div id="content">
      {/* Topbar */}
      <Header />
        {/* End of Topbar */}
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item"><a href="home">Home</a></li>
            <li className="breadcrumb-item active" aria-current="page">Transact</li>
          </ol>
        </nav>
        <div className="container-fluid px-lg-5 pb-5 transact">
          <div className="row px-lg-5">
            <div className="col-md-12">
              <div className="container border tp">
                <div className="row">
                  <div className="col-md-3">
                    <div className=" transactcard  py-lg-5 py-3 mx-lg-3  roundedc" style={{ marginTop: "4rem" }}>
                      <a className=" bg-white transact-link" data-target="#form-dialog-sip" data-toggle="modal" type="button">
                        <div className="goal-card text-center">
                          <img src={sip} className="mr-2 new-icon  p-3" alt='' /><br />
                          <h4 className="pt-4">SIP</h4>
                        </div>
                      </a>
                    </div>
                  </div>
                  <div className="col-md-9">
                    <div className="row">
                      <div className="col-md-4 pt-xs-2">
                        <div className="transactcard py-3 mx-lg-3 roundedc">
                          <a className=" bg-white transact-link" data-target="#form-dialog-new-purchase" data-toggle="modal" type="button">
                            <div className="goal-card text-center">
                              <img src={New_purchase} className="mr-2 new-icon p-3" alt='' /><br />
                              <h4 className="pt-4">New Purchase</h4>
                            </div>
                          </a>
                        </div>
                      </div>
                      <div className="col-md-4 pt-xs-2">
                        <div className="transactcard py-3 mx-lg-3 roundedc">
                          <a className=" bg-white transact-link" data-target="#form-dialog-additional-purchase" data-toggle="modal" type="button">
                            <div className="goal-card text-center">
                              <img src={Additional_puchase} className="mr-2 new-icon  p-3" alt='' /><br />
                              <h4 className="pt-4">Additional Purchase</h4>
                            </div>
                          </a>
                        </div>
                      </div>
                      <div className="col-md-4 pt-xs-2">
                        <div className=" transactcard py-3 mx-lg-3 roundedc">
                          <a className=" bg-white transact-link" data-target="#form-dialog-switch" data-toggle="modal" type="button">
                            <div className="goal-card text-center">
                              <img src={switch1} className="mr-2 new-icon  p-3" alt='' /><br />
                              <h4 className="pt-4">Switch</h4>
                            </div>
                          </a>
                        </div>
                      </div>
                      <div className="col-md-4 pt-4">
                        <div className="transactcard py-3 mx-lg-3 roundedc">
                          <a className=" bg-white transact-link" data-target="#form-dialog-stp" data-toggle="modal" type="button">
                            <div className="goal-card text-center">
                              <img src={stp} className="mr-2 new-icon  p-3" alt='' /><br />
                              <h4 className="pt-4">STP</h4>
                            </div>
                          </a>
                        </div>
                      </div>
                      <div className="col-md-4 pt-4">
                        <div className="transactcard py-3 mx-lg-3 roundedc">
                          <a className=" bg-white transact-link" data-target="#form-dialog-swp" data-toggle="modal" type="button">
                            <div className="goal-card text-center">
                              <img src={swp} className="mr-2 new-icon  p-3" alt='' /><br />
                              <h4 className="pt-4">SWP</h4>
                            </div>
                          </a>
                        </div>
                      </div>
                      <div className="col-md-4 pt-4">
                        <div className="transactcard py-3 mx-lg-3 roundedc">
                          <a className=" bg-white transact-link" data-target="#form-dialog-redemption" data-toggle="modal" type="button">
                            <div className="goal-card text-center">
                              <img src={redemption} className="mr-2 new-icon  p-3" alt='' /><br />
                              <h4 className="pt-4">Redemption</h4>
                            </div>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* <!-- Form trigger modal new Purchase --> */}
              <section className="new-purchase">
                <div className="row">
                  <div tabindex="-1" className="modal pmd-modal fade" id="form-dialog-new-purchase" aria-hidden="true">
                    <div className="modal-dialog modal-lg">
                      <div className="modal-content b-r p-3">
                        <div className="modal-header border-0">
                          <div className="quick-access-title">
                            <h3>New Purchase</h3>
                          </div>
                          <button aria-hidden="true" data-dismiss="modal" className="close" type="button">×</button>
                        </div>
                        <div className="modal-body">
                          <form>
                            <div className='row'>
                              <div className='col-lg-4 col-md-6'>
                                <div className="form-group pmd-textfield pmd-textfield-floating-label">
                                  <label for="select-profile" className='text-label'>Select Profile<span className="text-danger">*</span></label><br />
                                  <select name="select-profile" id="select-profile" className='form-control border-0 selectpicker' data-live-search="true">
                                          <option value="select">Select</option>

                                        </select>
                                  </div>
                                </div>
                                <div className='col-lg-4 col-md-6'>
                                  <div className="form-group pmd-textfield pmd-textfield-floating-label">
                                    <label for="amc" className='text-label'>Select AMC<span className="text-danger">*</span></label><br />
                                    <select name="amc" id="amc" className='form-control border-0 selectpicker' data-live-search="true">
                                          <option value="select">Select</option>

                                        </select>
                                    </div>
                                  </div>
                                  <div className='col-lg-4 col-md-6'>
                                    <p className="text-label mb-1 mt-1 p-radio lb">Asset Class <spna className="text-danger">*</spna></p>
                                    <div className='pt-2'>
                                      <input className=" input-text" id="equity" type="radio" name="asset" value="Equity" />
                                      <label htmlFor="equity" className="">Equity</label>
                                      <input className="input-text ml-3" id="debt" type="radio" name="asset" value="DEBT" />
                                      <label htmlFor="debt" className="">Debt</label>
                                      <br></br>  <small className="text-danger pull-left"></small>
                                    </div>
                                  </div>
                                </div>
                                <div className='row'>
                                  <div className='col-lg-4 col-md-6'>
                                    <p className=" mb-1 mt-1 p-radio lb text-label">Option <spna className="text-danger">*</spna></p>
                                    <div className='pt-2'>
                                      <input className=" input-text" id="growth" type="radio" name="growth" value="GROWTH" />
                                      <label htmlFor="growth" className="">Growth</label>
                                      <input className="input-text ml-3" id="dividend" type="radio" name="growth" value="Dividend" />
                                      <label htmlFor="dividend" className="">IDCW  </label>
                                    </div>
                                  </div>
                                  <div className='col-lg-4 col-md-6'>
                                    <div className="form-group pmd-textfield pmd-textfield-floating-label">
                                      <label for="select-scheme" className='lb text-label'>Select Scheme<span className="text-danger">*</span></label><br />
                                      <select name="select-scheme" id="select-scheme" className='form-control border-0 selectpicker' data-live-search="true">
                                          <option value="select">Select</option>

                                        </select>
                                      </div>
                                    </div>
                                    <div className='col-lg-4 col-md-6'>
                                      <div className="form-group pmd-textfield pmd-textfield-floating-label">
                                        <label className="control-label lb text-label" for="amount">Enter Amount<span className="text-danger">*</span></label>
                                        <input type="text" className="form-control" name='amount' placeholder='Enter Amount' />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-md-12 ">
                                    <a href="javascript:void(0)" className="btn btn-danger float-right my-4" >+ Add</a>
                                  </div>
                                  <div className="col-md-12 " id="tbt">
                                    <div className="table-responsive">
                                    <table className="table bg-white mt-5 mb-3">
                                      <tr>
                                        <th>Profile</th>
                                        <th>Scheme Name</th>
                                        <th>Folio Number</th>
                                        <th>Amount</th>
                                        <th></th>
                                      </tr>
                                      <tr>
                                        <td>Profile</td>
                                        <td>Scheme Name</td>
                                        <td>Folio Number</td>
                                        <td>3500</td>
                                        <td> <a href="javascript:void(0)" ><FaTrash className="red" /></a></td>
                                      </tr>
                                    </table>
                                    </div>
                                    <div className="row">
                                      <div className='col-md-5'>
                                        <div className="form-group pmd-textfield pmd-textfield-floating-label">
                                          <label htmlFor="payment_mode" className="text-label">Select Payment Mode  <spna className="text-danger">*</spna></label><br />
                                          <select name="payment_mode" id="payment_mode" className='form-control border-0 selectpicker' data-live-search="true">
                                          <option value="select">Select</option>

                                        </select>
                                          </div>
                                        </div>
                                        <div className='col-md-7'>
                                          <div className="pt-4 mt-3">
                                            <input className=" input-text" id="emailLink" type="radio" name="payType" value="N" />
                                            <label htmlFor="emailLink" className="">Link On Email</label>
                                            <input className="input-text ml-3" id="immediatePay" type="radio" name="payType" value="Y" />
                                            <label htmlFor="immediatePay" className="">Immediate Payment</label>
                                          </div>
                                        </div>
                                        <div className='col-md-5'>
                                          <div className="form-group pmd-textfield pmd-textfield-floating-label">
                                            <label for="select-bank" className='lb text-label'>Select Bank<span className="text-danger">*</span></label><br />
                                            <select name="select-bank" id="select-bank" className='form-control border-0 selectpicker' data-live-search="true">
                                          <option value="select">Select</option>

                                        </select>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </form>
                                  </div>
                                  <div className="modal-footer border-0">
                                    <button data-dismiss="modal" className="new-btn1" data-target="#success-transaction" data-toggle="modal" type="button">Order Now</button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </section>
                        {/* <!-- End Form trigger modal new Purchase --> */}
                        {/* <!-- Form trigger modal additional Purchase --> */}
                        <section className="additional-purchase">
                          <div className="row">
                            <div tabindex="-1" className="modal pmd-modal fade" id="form-dialog-additional-purchase" aria-hidden="true">
                              <div className="modal-dialog modal-lg">
                                <div className="modal-content b-r p-3">
                                  <div className="modal-header border-0">
                                    <div className="quick-access-title">
                                      <h3>Additional Purchase</h3>
                                    </div>
                                    <button aria-hidden="true" data-dismiss="modal" className="close" type="button">×</button>
                                  </div>
                                  <div className="modal-body">
                                    <form>
                                      <div className="row">
                                        <div className='col-md-5'>
                                          <div className="form-group pmd-textfield pmd-textfield-floating-label">
                                            <label for="select-p" className='text-label'>Select Profile<span className="text-danger">*</span></label><br />
                                            <select name="select-profile" id="select-profile" className='form-control border-0 selectpicker' data-live-search="true">
                                          <option value="select">Select</option>

                                        </select>
                                          </div>
                                        </div>
                                        <div className='col-md-7'>
                                          <div className='pt-4 mt-3'>
                                            <input className=" input-text" id="equity" type="radio" name="asset" value="Equity" />
                                            <label htmlFor="equity" className="">Existing Scheme</label>
                                            <input className="input-text ml-3" id="debt" type="radio" name="asset" value="DEBT" />
                                            <label htmlFor="debt" className="">New Scheme</label>
                                            <br></br>  <small className="text-danger pull-left"></small>
                                          </div>
                                        </div>
                                      </div>
                                      <div className='row'>
                                        <div className='col-md-5'>
                                          <div className="form-group pmd-textfield pmd-textfield-floating-label">
                                            <label for="amc" className='lb  text-label'>Select AMC<span className="text-danger">*</span></label><br />
                                            <select name="amc" id="amc" className='form-control border-0 selectpicker' data-live-search="true">
                                          <option value="select">Select</option>

                                        </select>
                                          </div>
                                        </div>
                                        <div className='col-md-3'>
                                          <p className="text-label mb-1 mt-1 p-radio lb">Asset Class <spna className="text-danger">*</spna></p>
                                          <div className='pt-2'>
                                            <input className=" input-text" id="equity" type="radio" name="asset" value="Equity" />
                                            <label htmlFor="equity" className="">Equity</label>
                                            <input className="input-text ml-3" id="debt" type="radio" name="asset" value="DEBT" />
                                            <label htmlFor="debt" className="">Debt</label>
                                            <br></br>  <small className="text-danger pull-left"></small>
                                          </div>
                                        </div>
                                        <div className='col-md-3'>
                                          <p className="text-label mb-1 mt-1 p-radio lb">Option <spna className="text-danger">*</spna></p>
                                          <div className='pt-2'>
                                            <input className=" input-text" id="growth" type="radio" name="growth" value="GROWTH" />
                                            <label htmlFor="growth" className="">Growth</label>
                                            <input className="input-text ml-3" id="dividend" type="radio" name="growth" value="Dividend" />
                                            <label htmlFor="dividend" className="">IDCW  </label>
                                          </div>
                                        </div>
                                      </div>
                                      <div className='row'>
                                        <div className='col-lg-4 col-md-6'>
                                          <div className="form-group pmd-textfield pmd-textfield-floating-label">
                                            <label for="folio" className='lb text-label'>Select Folio<span className="text-danger">*</span></label><br />
                                            <select name="folio" id="folio" className='form-control border-0 selectpicker' data-live-search="true">
                                          <option value="select">Select</option>

                                        </select>
                                          </div>
                                        </div>
                                        <div className='col-lg-4 col-md-6'>
                                          <div className="form-group pmd-textfield pmd-textfield-floating-label">
                                            <label for="scheme" className='lb text-label'>Select Scheme<span className="text-danger">*</span></label><br />
                                            <select name="scheme" id="scheme" className='form-control border-0 selectpicker' data-live-search="true">
                                          <option value="select">Select</option>

                                        </select>
                                          </div>
                                        </div>
                                        <div className='col-lg-4 col-md-6'>
                                          <div className="form-group pmd-textfield pmd-textfield-floating-label">
                                            <label className="control-label lb text-label" for="amount">Enter Amount<span className="text-danger">*</span></label>
                                            <input type="text" className="form-control" name='amount' placeholder='Enter Amount' />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="col-md-12 ">
                                        <a href="javascript:void(0)" className="btn btn-danger float-right my-4" >+ Add</a>
                                      </div>
                                      <div className="col-md-12 " id="tbt">
                                      <div className="table-responsive">
                                        <table className="table bg-white mt-5 mb-3">
                                          <tr>
                                            <th>Profile</th>
                                            <th>Scheme Name</th>
                                            <th>Folio Number</th>
                                            <th>Amount</th>
                                            <th></th>
                                          </tr>
                                          <tr>
                                            <td>Profile</td>
                                            <td>Scheme Name</td>
                                            <td>Folio Number</td>
                                            <td>3500</td>
                                            <td> <a href="javascript:void(0)" ><FaTrash className="red" /></a></td>
                                          </tr>
                                        </table>
                                        </div>
                                        <div className="row">
                                          <div className='col-md-5'>
                                            <div className="form-group pmd-textfield pmd-textfield-floating-label">
                                              <label htmlFor="payment_mode" className="text-label">Select Payment Mode  <spna className="text-danger">*</spna></label><br />
                                              <select name="payment_mode" id="payment_mode" className='form-control border-0 selectpicker' data-live-search="true">
                                          <option value="select">Select</option>

                                        </select>
                                            </div>
                                          </div>
                                          <div className='col-md-7'>
                                            <div className="pt-4 mt-3">
                                              <input className=" input-text" id="emailLink" type="radio" name="payType" value="N" />
                                              <label htmlFor="emailLink" className="">Link On Email</label>
                                              <input className="input-text ml-3" id="immediatePay" type="radio" name="payType" value="Y" />
                                              <label htmlFor="immediatePay" className="">Immediate Payment</label>
                                            </div>
                                          </div>
                                          <div className='col-md-5'>
                                            <div className="form-group pmd-textfield pmd-textfield-floating-label">
                                              <label for="select-bank" className='lb text-label'>Select Bank<span className="text-danger">*</span></label><br />
                                              <select name="select-bank" id="select-bank" className='form-control border-0 selectpicker' data-live-search="true">
                                          <option value="select">Select</option>

                                        </select>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </form>
                                  </div>
                                  <div className="modal-footer border-0">
                                  <button data-dismiss="modal" className="new-btn1" data-target="#wrong" data-toggle="modal" type="button">Order Now</button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </section>
                        {/* <!--End Form trigger modal additional Purchase --> */}
                        {/* <!-- Form trigger modal Switch --> */}
                        <section className="switch">
                          <div className="row">
                            <div tabindex="-1" className="modal pmd-modal fade" id="form-dialog-switch" aria-hidden="true">
                              <div className="modal-dialog modal-lg">
                                <div className="modal-content b-r p-3">
                                  <div className="modal-header border-0">
                                    <div className="quick-access-title">
                                      <h3>Switch</h3>
                                    </div>
                                    <button aria-hidden="true" data-dismiss="modal" className="close" type="button">×</button>
                                  </div>
                                  <div className="modal-body">
                                    <form>
                                      <div className="row">
                                        <div className='col-lg-4 col-md-6'>
                                          <div className="form-group pmd-textfield pmd-textfield-floating-label">
                                            <label for="select-p" className='text-label'>Select Profile<span className="text-danger">*</span></label><br />
                                            <select name="select-profile" id="select-profile" className='form-control border-0 selectpicker' data-live-search="true">
                                          <option value="select">Select</option>

                                        </select>
                                          </div>
                                        </div>
                                        <div className='col-lg-4 col-md-6'>
                                          <div className="form-group pmd-textfield pmd-textfield-floating-label">
                                            <label for="amc" className='text-label'>Select AMC<span className="text-danger">*</span></label><br />
                                            <select name="amc" id="amc" className='form-control border-0 selectpicker' data-live-search="true">
                                          <option value="select">Select</option>

                                        </select>
                                          </div>
                                        </div>
                                        <div className='col-lg-4 col-md-6'>
                                          <div className="form-group pmd-textfield pmd-textfield-floating-label">
                                            <label for="folio" className='text-label'>Select Folio<span className="text-danger">*</span></label><br />
                                            <select name="folio" id="folio" className='form-control border-0 selectpicker' data-live-search="true">
                                          <option value="select">Select</option>

                                        </select>
                                          </div>
                                        </div>
                                      </div>
                                      <div className='row'>
                                        <div className='col-md-5'>
                                          <div className="form-group pmd-textfield pmd-textfield-floating-label">
                                            <label for="source" className='text-label'>Select Source Scheme<span className="text-danger">*</span></label><br />
                                            <select name="source" id="source" className='form-control border-0 selectpicker' data-live-search="true">
                                          <option value="select">Select</option>

                                        </select>
                                          </div>
                                        </div>
                                        <div className='col-md-3'>
                                          <p className="text-label mb-1 mt-1 p-radio lb">Asset Class <spna className="text-danger">*</spna></p>
                                          <div className='pt-2'>
                                            <input className=" input-text" id="equity" type="radio" name="asset" value="Equity" />
                                            <label htmlFor="equity" className="">Equity</label>
                                            <input className="input-text ml-3" id="debt" type="radio" name="asset" value="DEBT" />
                                            <label htmlFor="debt" className="">Debt</label>
                                            <br></br>  <small className="text-danger pull-left"></small>
                                          </div>
                                        </div>
                                        <div className='col-md-3'>
                                          <p className="text-label mb-1 mt-1 p-radio lb">Option <spna className="text-danger">*</spna></p>
                                          <div className='pt-2'>
                                            <input className=" input-text" id="growth" type="radio" name="growth" value="GROWTH" />
                                            <label htmlFor="growth" className="">Growth</label>
                                            <input className="input-text ml-3" id="dividend" type="radio" name="growth" value="Dividend" />
                                            <label htmlFor="dividend" className="">IDCW  </label>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="row">
                                        <div className='col-md-5'>
                                          <div className="form-group pmd-textfield pmd-textfield-floating-label">
                                            <label for="target" className='lb  text-label'>Select Target Scheme<span className="text-danger">*</span></label><br />
                                            <select name="target" id="target" className='form-control border-0 selectpicker' data-live-search="true">
                                          <option value="select">Select</option>

                                        </select>
                                          </div>
                                        </div>
                                        <div className="col-md-7">
                                          <p className="text-label mb-1 mt-1 p-radio">Switch Type <spna className="text-danger">*</spna></p>
                                          <input className=" input-text" id="amt" type="radio" name="amt_type" value="amt" />
                                          <label htmlFor="amt" className="">By Amount</label>
                                          <input className="input-text ml-3" id="units" type="radio" name="amt_type" value="unit" />
                                          <label htmlFor="units" className="">By Units</label>
                                          <input className="input-text ml-3" id="all_units" type="radio" name="amt_type" value="all_units" />
                                          <label htmlFor="all_units" className="">All Units</label>
                                          <br></br>  <small className="text-danger pull-left"></small>
                                        </div>
                                      </div>
                                      <div className='row'>
                                        <div className='col-md-5'>
                                          <div className="form-group pmd-textfield pmd-textfield-floating-label">
                                            <label className="control-label lb text-label" for="amount">Enter Amount<span className="text-danger">*</span></label>
                                            <input type="text" className="form-control" name='amount' placeholder='Enter Amount' />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="col-md-12 ">
                                        <a href="javascript:void(0)" className="btn btn-danger float-right my-4" >+ Add</a>
                                      </div>
                                      <div className="col-md-12 " id="tbt">
                                      <div className="table-responsive">
                                        <table className="table bg-white mt-5 mb-3">
                                          <tr>
                                            <th>Profile</th>
                                            <th>Scheme Name</th>
                                            <th>Folio Number</th>
                                            <th>Target Scheme</th>
                                            <th>Switch Type</th>
                                            <th>Amount/Unit</th>
                                            <th></th>
                                          </tr>
                                          <tr>
                                            <td>Profile</td>
                                            <td>Scheme Name</td>
                                            <td>Folio Number</td>
                                            <td>Target Scheme</td>
                                            <td>switch type</td>
                                            <td>3500</td>
                                            <td> <a href="javascript:void(0)" ><FaTrash className="red" /></a></td>
                                          </tr>
                                        </table>
                                        </div>
                                      </div>
                                    </form>
                                  </div>
                                  <div className="modal-footer border-0">
                                    <button data-dismiss="modal" className="new-btn1" type="button">Order Now</button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </section>
                        {/* <!--End Form trigger modal Switch --> */}
                        {/* <!-- Form trigger modal SIP --> */}
                        <section className="sip">
                          <div className="row">
                            <div tabindex="-1" className="modal pmd-modal fade" id="form-dialog-sip" aria-hidden="true">
                              <div className="modal-dialog modal-lg">
                                <div className="modal-content b-r p-3">
                                  <div className="modal-header border-0">
                                    <div className="quick-access-title">
                                      <h3>SIP</h3>
                                    </div>
                                    <button aria-hidden="true" data-dismiss="modal" className="close" type="button">×</button>
                                  </div>
                                  <div className="modal-body">
                                    <form>
                                      <div className="row">
                                        <div className='col-md-5'>
                                          <div className="form-group pmd-textfield pmd-textfield-floating-label">
                                            <label for="select-p" className='text-label'>Select Profile<span className="text-danger">*</span></label><br />
                                           
                                            <select name="select-profile" id="select-profile" className='form-control border-0 selectpicker' data-live-search="true">
                                          <option value="select">Select</option>

                                        </select>
                                          </div>
                                        </div>
                                        <div className='col-md-7'>
                                          <div className='pt-4 mt-3'>
                                            <input className=" input-text" id="equity" type="radio" name="asset" value="Equity" />
                                            <label htmlFor="equity" className="">Existing Scheme</label>
                                            <input className="input-text ml-3" id="debt" type="radio" name="asset" value="DEBT" />
                                            <label htmlFor="debt" className="">New Scheme</label>
                                            <br></br>  <small className="text-danger pull-left"></small>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="row">
                                        <div className='col-lg-4 col-md-6'>
                                          <div className="form-group pmd-textfield pmd-textfield-floating-label">
                                            <label for="amc" className='text-label'>Select AMC<span className="text-danger">*</span></label><br />
                                            <select name="amc" id="amc" className='form-control border-0 selectpicker' data-live-search="true">
                                          <option value="select">Select</option>

                                        </select>
                                          </div>
                                        </div>
                                        <div className='col-lg-4 col-md-6'>
                                          <div className="form-group pmd-textfield pmd-textfield-floating-label">
                                            <label for="folio" className='text-label'>Select Folio<span className="text-danger">*</span></label><br />
                                            <select name="folio" id="folio" className='form-control border-0 selectpicker' data-live-search="true">
                                          <option value="select">Select</option>

                                        </select>
                                          </div>
                                        </div>
                                        <div className='col-lg-4 col-md-6'>
                                          <div className="form-group pmd-textfield pmd-textfield-floating-label">
                                            <label for="Scheme" className='text-label'>Select  Scheme<span className="text-danger">*</span></label><br />
                                            <select name="Scheme" id="Scheme" className='form-control border-0 selectpicker' data-live-search="true">
                                          <option value="select">Select</option>

                                        </select>
                                          </div>
                                        </div>
                                      </div>
                                      <div className='row'>
                                        <div className='col-lg-4 col-md-6'>
                                          <div className="form-group pmd-textfield pmd-textfield-floating-label">
                                            <label className="control-label lb text-label" for="amount">Enter Amount<span className="text-danger">*</span></label>
                                            <input type="text" className="form-control" name='amount' placeholder='Enter Amount' />
                                          </div>
                                        </div>
                                        <div className='col-lg-4 col-md-6'>
                                          <div className="form-group pmd-textfield pmd-textfield-floating-label">
                                            <label for="sipdate" className='lb  text-label'>SIP Date<span className="text-danger">*</span></label><br />
                                            <select name="sipdate" id="sipdate" className='form-control border-0 selectpicker' data-live-search="true">
                                          <option value="select">Select</option>

                                        </select>
                                          </div>
                                        </div>
                                        <div className='col-lg-4 col-md-6'>
                                          <div className="form-group pmd-textfield pmd-textfield-floating-label">
                                            <label className="control-label lb text-label" for="sipf">SIP From<span className="text-danger">*</span></label>
                                            <DatePickerComponent format='MMM-yyyy' className="form-control" placeholder='MM-YYYY' start='Year' depth='Year' />
                                          </div>
                                        </div>
                                      </div>
                                      <div className='row'>
                                        <div className='col-lg-4 col-md-6'>
                                          <div className="form-group pmd-textfield pmd-textfield-floating-label">
                                            <label className="control-label lb text-label" for="sipend">SIP To<span className="text-danger">*</span></label>
                                            <DatePickerComponent format='MMM-yyyy' className="form-control" placeholder='MM-YYYY' start='Year' depth='Year' />
                                          </div>
                                        </div>
                                        <div className='col-md-3 '>
                                          <input className="input-text mt-5" id="perpetual" type="checkbox" name="perpetual" value="Y" defaultChecked />
                                          <input type="hidden" id="perpetual_val" name="perpetual_val" />
                                          <label htmlFor="perpetual" className="ml-2">Perpetual  <spna className="text-danger">*</spna></label>
                                        </div>
                                      </div>
                                      <div className="col-md-12 ">
                                        <a href="javascript:void(0)" className="btn btn-danger float-right my-4" >+ Add</a>
                                      </div>
                                      <div className="col-md-12 " id="tbt">
                                      <div className="table-responsive">
                                        <table className="table bg-white mt-5 mb-3">
                                          <tr>
                                            <th>Profile</th>
                                            <th>Scheme Name</th>
                                            <th>Folio Number</th>
                                            <th>SIP From</th>
                                            <th>SIP To</th>
                                            <th>Amount</th>
                                            <th></th>
                                          </tr>
                                          <tr>
                                            <td>Profile</td>
                                            <td>Scheme Name</td>
                                            <td>Folio Number</td>
                                            <td>20/10/2021</td>
                                            <td>20/11/2021</td>
                                            <td>3500</td>
                                            <td> <a href="javascript:void(0)" ><FaTrash className="red" /></a></td>
                                          </tr>
                                        </table>
                                        </div>
                                        <div className="row">
                                          <div className='col-md-5'>
                                            <div className="form-group pmd-textfield pmd-textfield-floating-label">
                                              <label for="mandate" className='lb text-label'>Select Mandate<span className="text-danger">*</span></label><br />
                                              <select name="mandate" id="mandate" className='form-control border-0 selectpicker' data-live-search="true">
                                          <option value="select">Select</option>

                                        </select>
                                            </div>
                                            <a href="/prodigypro/dashboard/bank-and-mandate" className="red fs-13">Create Mandate</a>
                                          </div>
                                        </div>
                                      </div>
                                    </form>
                                  </div>
                                  <div className="modal-footer border-0">
                                    <button data-dismiss="modal" className="new-btn1" type="button">Order Now</button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </section>
                        {/* <!--End Form trigger modal SIP --> */}
                        {/* <!-- Form trigger modal STP --> */}
                        <section className="stp">
                          <div className="row">
                            <div tabindex="-1" className="modal pmd-modal fade" id="form-dialog-stp" aria-hidden="true">
                              <div className="modal-dialog modal-lg">
                                <div className="modal-content b-r p-3">
                                  <div className="modal-header border-0">
                                    <div className="quick-access-title">
                                      <h3>STP</h3>
                                    </div>
                                    <button aria-hidden="true" data-dismiss="modal" className="close" type="button">×</button>
                                  </div>
                                  <div className="modal-body">
                                    <form>
                                      <div className="row">
                                        <div className='col-lg-4 col-md-6'>
                                          <div className="form-group pmd-textfield pmd-textfield-floating-label">
                                            <label for="profile" className='text-label'>Select Profile<span className="text-danger">*</span></label><br />
                                            <select name="profile" id="profile" className='form-control border-0 selectpicker' data-live-search="true">
                                          <option value="select">Select</option>

                                        </select>
                                          </div>
                                        </div>
                                        <div className='col-lg-4 col-md-6'>
                                          <div className="form-group pmd-textfield pmd-textfield-floating-label">
                                            <label for="amc" className='text-label'>Select AMC<span className="text-danger">*</span></label><br />
                                            <select name="amc" id="amc" className='form-control border-0 selectpicker' data-live-search="true">
                                          <option value="select">Select</option>

                                        </select>
                                          </div>
                                        </div>
                                        <div className='col-lg-4 col-md-6'>
                                          <div className="form-group pmd-textfield pmd-textfield-floating-label">
                                            <label for="folio" className='text-label'>Select Folio<span className="text-danger">*</span></label><br />
                                            <select name="folio" id="folio" className='form-control border-0 selectpicker' data-live-search="true">
                                          <option value="select">Select</option>

                                        </select>
                                          </div>
                                        </div>
                                      </div>
                                      <div className='row'>
                                        <div className='col-md-5'>
                                          <div className="form-group pmd-textfield pmd-textfield-floating-label">
                                            <label for="source" className='text-label'>Select Source Scheme<span className="text-danger">*</span></label><br />
                                            <select name="source" id="source" className='form-control border-0 selectpicker' data-live-search="true">
                                          <option value="select">Select</option>

                                        </select>
                                          </div>
                                        </div>
                                        <div className='col-md-3'>
                                          <p className="text-label mb-1 mt-1 p-radio lb">Asset Class <spna className="text-danger">*</spna></p>
                                          <div className='pt-2'>
                                            <input className=" input-text" id="equity" type="radio" name="asset" value="Equity" />
                                            <label htmlFor="equity" className="">Equity</label>
                                            <input className="input-text ml-3" id="debt" type="radio" name="asset" value="DEBT" />
                                            <label htmlFor="debt" className="">Debt</label>
                                            <br></br>  <small className="text-danger pull-left"></small>
                                          </div>
                                        </div>
                                        <div className='col-md-3'>
                                          <p className="text-label mb-1 mt-1 p-radio lb">Option <spna className="text-danger">*</spna></p>
                                          <div className='pt-2'>
                                            <input className=" input-text" id="growth" type="radio" name="growth" value="GROWTH" />
                                            <label htmlFor="growth" className="">Growth</label>
                                            <input className="input-text ml-3" id="dividend" type="radio" name="growth" value="Dividend" />
                                            <label htmlFor="dividend" className="">IDCW  </label>
                                          </div>
                                        </div>
                                      </div>
                                      <div className='row'>
                                        <div className='col-lg-4 col-md-6'>
                                          <div className="form-group pmd-textfield pmd-textfield-floating-label">
                                            <label for="target" className='lb  text-label'>Select Target Scheme<span className="text-danger">*</span></label><br />
                                            <select name="target" id="target" className='form-control border-0 selectpicker' data-live-search="true">
                                          <option value="select">Select</option>

                                        </select>
                                          </div>
                                        </div>
                                        <div className="col-lg-4 col-md-6">
                                          <div className="form-group pmd-textfield pmd-textfield-floating-label">
                                            <label className="control-label lb text-label" for="to">STP From<span className="text-danger">*</span></label>
                                            <DatePickerComponent format='MMM-yyyy' className="form-control" placeholder='MM-YYYY' start='Year' depth='Year' />
                                          </div>
                                        </div>
                                        <div className='col-lg-4 col-md-6'>
                                          <div className="form-group pmd-textfield pmd-textfield-floating-label">
                                            <label for="from" className='lb  text-label'>STP To<span className="text-danger">*</span></label><br />
                                            <DatePickerComponent format='MMM-yyyy' className="form-control" placeholder='MM-YYYY' start='Year' depth='Year' />
                                          </div>
                                        </div>
                                      </div>
                                      <div className='row'>
                                        <div className='col-lg-4 col-md-6'>
                                          <div className="form-group pmd-textfield pmd-textfield-floating-label">
                                            <label for="selectf" className='lb  text-label'>Select Frequency<span className="text-danger">*</span></label><br />
                                            <select name="selectf" id="selectf" className='form-control border-0 selectpicker' data-live-search="true">
                                          <option value="select">Select</option>

                                        </select>
                                          </div>
                                        </div>
                                        <div className="col-lg-4 col-md-6">
                                          <div className="form-group pmd-textfield pmd-textfield-floating-label">
                                            <label className="control-label lb text-label" for="amount">Enter Amount<span className="text-danger">*</span></label>
                                            <input type="text" className="form-control" name='amount' placeholder='Enter Amount' />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="col-md-12 ">
                                        <a href="javascript:void(0)" className="btn btn-danger float-right my-4" >+ Add</a>
                                      </div>
                                      <div className="col-md-12 " id="tbt">
                                      <div className="table-responsive">
                                        <table className="table table-responsive bg-white mt-5 mb-3">
                                          <tr>
                                            <th>Profile</th>
                                            <th> Source Scheme</th>
                                            <th> Folio Number</th>
                                            <th>Target Scheme</th>
                                            <th>STP From</th>
                                            <th>STP To</th>
                                            <th>Frequency</th>
                                            <th>Amount</th>
                                            <th></th>
                                          </tr>
                                          <tr>
                                            <td>Profile</td>
                                            <td>Source Scheme </td>
                                            <td>Folio number</td>
                                            <td>Target Scheme</td>
                                            <td>5/2/22</td>
                                            <td>10/3/22</td>
                                            <td>Frequency</td>
                                            <td>6000</td>
                                            <td> <a href="javascript:void(0)" ><FaTrash className="red" /></a></td>
                                          </tr>
                                        </table>
                                        </div>
                                      </div>
                                    </form>
                                  </div>
                                  <div className="modal-footer border-0">
                                    <button data-dismiss="modal" className="new-btn1" type="button">Order Now</button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </section>
                        {/* <!--End Form trigger modal STP --> */}
                        {/* <!-- Form trigger modal SWP --> */}
                        <section className="swp">
                          <div className="row">
                            <div tabindex="-1" className="modal pmd-modal fade" id="form-dialog-swp" aria-hidden="true">
                              <div className="modal-dialog modal-lg">
                                <div className="modal-content b-r p-3">
                                  <div className="modal-header border-0">
                                    <div className="quick-access-title">
                                      <h3>SWP</h3>
                                    </div>
                                    <button aria-hidden="true" data-dismiss="modal" className="close" type="button">×</button>
                                  </div>
                                  <div className="modal-body">
                                    <form>
                                      <div className="row">
                                        <div className='col-lg-4 col-md-6'>
                                          <div className="form-group pmd-textfield pmd-textfield-floating-label">
                                            <label for="select-p" className='text-label'>Select Profile<span className="text-danger">*</span></label><br />
                                            <select name="select-profile" id="select-profile" className='form-control border-0 selectpicker' data-live-search="true">
                                          <option value="select">Select</option>

                                        </select>
                                          </div>
                                        </div>
                                        <div className='col-lg-4 col-md-6'>
                                          <div className="form-group pmd-textfield pmd-textfield-floating-label">
                                            <label for="amc" className='text-label'>Select AMC<span className="text-danger">*</span></label><br />
                                            <select name="amc" id="amc" className='form-control border-0 selectpicker' data-live-search="true">
                                          <option value="select">Select</option>

                                        </select>
                                          </div>
                                        </div>
                                        <div className='col-lg-4 col-md-6'>
                                          <div className="form-group pmd-textfield pmd-textfield-floating-label">
                                            <label for="folio" className='text-label'>Select Folio<span className="text-danger">*</span></label><br />
                                            <select name="folio" id="folio" className='form-control border-0 selectpicker' data-live-search="true">
                                          <option value="select">Select</option>

                                        </select>
                                          </div>
                                        </div>
                                      </div>
                                      <div className='row'>
                                        <div className='col-lg-4 col-md-6'>
                                          <div className="form-group pmd-textfield pmd-textfield-floating-label">
                                            <label for="Scheme" className='lb  text-label'>Select Scheme<span className="text-danger">*</span></label><br />
                                            <select name="scheme" id="scheme" className='form-control border-0 selectpicker' data-live-search="true">
                                          <option value="select">Select</option>

                                        </select>
                                          </div>
                                        </div>
                                        <div className='col-lg-4 col-md-6'>
                                          <div className="form-group pmd-textfield pmd-textfield-floating-label">
                                            <label className="control-label lb text-label" for="sipf">SWP From<span className="text-danger">*</span></label>
                                            <DatePickerComponent format='MMM-yyyy' className="form-control" placeholder='MM-YYYY' start='Year' depth='Year' />
                                          </div>
                                        </div>
                                        <div className='col-lg-4 col-md-6'>
                                          <div className="form-group pmd-textfield pmd-textfield-floating-label">
                                            <label className="control-label lb text-label" for="sipend">SWP To<span className="text-danger">*</span></label>
                                            <DatePickerComponent format='MMM-yyyy' className="form-control" placeholder='MM-YYYY' start='Year' depth='Year' />
                                          </div>
                                        </div>
                                      </div>
                                      <div className='row'>
                                        <div className='col-lg-4 col-md-6'>
                                          <div className="form-group pmd-textfield pmd-textfield-floating-label">
                                            <label for="selectf" className='lb  text-label'>Select Frequency<span className="text-danger">*</span></label><br />
                                            <select name="selectf" id="selectf" className='form-control border-0 selectpicker' data-live-search="true">
                                          <option value="select">Select</option>

                                        </select>
                                          </div>
                                        </div>
                                        <div className="col-lg-4 col-md-6">
                                          <div className="form-group pmd-textfield pmd-textfield-floating-label">
                                            <label className="control-label lb text-label" for="amount">Enter Amount<span className="text-danger">*</span></label>
                                            <input type="text" className="form-control" name='amount' placeholder='Enter Amount' />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="col-md-12 ">
                                        <a href="javascript:void(0)" className="btn btn-danger float-right my-4" >+ Add</a>
                                      </div>
                                      <div className="col-md-12 " id="tbt">
                                      <div className="table-responsive">
                                        <table className="table bg-white mt-5 mb-3">
                                          <tr>
                                            <th>Profile</th>
                                            <th>Scheme Name</th>
                                            <th>Folio Number</th>
                                            <th>SWP From</th>
                                            <th>SWP To</th>
                                            <th>Frequency</th>
                                            <th>Amount</th>
                                            <th></th>
                                          </tr>
                                          <tr>
                                            <td>Profile</td>
                                            <td>Scheme Name</td>
                                            <td>folio number</td>
                                            <td>5/12/20</td>
                                            <td>6/1/21</td>
                                            <td>frequency</td>
                                            <td>4500</td>
                                            <td> <a href="javascript:void(0)" ><FaTrash className="red" /></a></td>
                                          </tr>
                                        </table>
                                        </div>
                                      </div>
                                    </form>
                                  </div>
                                  <div className="modal-footer border-0">
                                    <button data-dismiss="modal" className="new-btn1" type="button">Order Now</button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </section>
                        {/* <!--End Form trigger modal SWP --> */}
                        {/* <!-- Form trigger modal Redemption--> */}
                        <section className="redemption">
                          <div className="row">
                            <div tabindex="-1" className="modal pmd-modal fade" id="form-dialog-redemption" aria-hidden="true">
                              <div className="modal-dialog modal-lg">
                                <div className="modal-content b-r p-3">
                                  <div className="modal-header border-0">
                                    <div className="quick-access-title">
                                      <h3>Redemption</h3>
                                    </div>
                                    <button aria-hidden="true" data-dismiss="modal" className="close" type="button">×</button>
                                  </div>
                                  <div className="modal-body">
                                    <form>
                                      <div className="row">
                                        <div className='col-lg-4 col-md-6'>
                                          <div className="form-group pmd-textfield pmd-textfield-floating-label">
                                            <label for="select-p" className='text-label'>Select Profile<span className="text-danger">*</span></label><br />
                                            <select name="select-profile" id="select-profile" className='form-control border-0 selectpicker' data-live-search="true">
                                          <option value="select">Select</option>

                                        </select>
                                          </div>
                                        </div>
                                        <div className='col-lg-4 col-md-6'>
                                          <div className="form-group pmd-textfield pmd-textfield-floating-label">
                                            <label for="amc" className='text-label'>Select AMC<span className="text-danger">*</span></label><br />
                                            <select name="amc" id="amc" className='form-control border-0 selectpicker' data-live-search="true">
                                          <option value="select">Select</option>

                                        </select>
                                          </div>
                                        </div>
                                        <div className='col-lg-4 col-md-6'>
                                          <div className="form-group pmd-textfield pmd-textfield-floating-label">
                                            <label for="folio" className='text-label'>Select Folio<span className="text-danger">*</span></label><br />
                                            <select name="folio" id="folio" className='form-control border-0 selectpicker' data-live-search="true">
                                          <option value="select">Select</option>

                                        </select>
                                          </div>
                                        </div>
                                      </div>
                                      <div className='row'>
                                        <div className='col-md-5'>
                                          <div className="form-group pmd-textfield pmd-textfield-floating-label">
                                            <label for="scheme" className='lb  text-label'>Select Scheme<span className="text-danger">*</span></label><br />
                                            <select name="scheme" id="scheme" className='form-control border-0 selectpicker' data-live-search="true">
                                          <option value="select">Select</option>

                                        </select>
                                          </div>
                                        </div>
                                        <div className="col-md-7">
                                          <p className="text-label mb-1 mt-1 p-radio">Redemption Type <spna className="text-danger">*</spna></p>
                                          <input className=" input-text" id="amt" type="radio" name="amt_type" value="amt" />
                                          <label htmlFor="amt" className="">By Amount</label>
                                          <input className="input-text ml-3" id="units" type="radio" name="amt_type" value="unit" />
                                          <label htmlFor="units" className="">By Units</label>
                                          <input className="input-text ml-3" id="all_units" type="radio" name="amt_type" value="all_units" />
                                          <label htmlFor="all_units" className="">All Units</label>
                                          <br></br>  <small className="text-danger pull-left"></small>
                                        </div>
                                      </div>
                                      <div className='col-md-5'>
                                        <div className="form-group pmd-textfield pmd-textfield-floating-label">
                                          <label className="control-label lb text-label" for="amount">Enter Amount<span className="text-danger">*</span></label>
                                          <input type="text" className="form-control" name='amount' placeholder='Enter Amount' />
                                        </div>
                                      </div>
                                      <div className="col-md-12 ">
                                        <a href="javascript:void(0)" className="btn btn-danger float-right my-4" >+ Add</a>
                                      </div>
                                      <div className="col-md-12 " id="tbt">
                                      <div className="table-responsive">
                                        <table className="table bg-white mt-5 mb-3">
                                          <tr>
                                            <th>Profile</th>
                                            <th>Scheme Name</th>
                                            <th>Folio Number</th>
                                            <th>Redemption Type</th>
                                            <th>Amount</th>
                                            <th></th>
                                          </tr>
                                          <tr>
                                            <td>Profile</td>
                                            <td>Scheme Name</td>
                                            <td>Folio Number</td>
                                            <td>Redemption Type</td>
                                            <td>3500</td>
                                            <td> <a href="javascript:void(0)" ><FaTrash className="red" /></a></td>
                                          </tr>
                                        </table>
                                        </div>
                                      </div>
                                    </form>
                                  </div>
                                  <div className="modal-footer border-0">
                                  <button data-dismiss="modal" className="new-btn1" data-target="#success-transaction-redem" data-toggle="modal" type="button">Order Now</button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </section>
                        {/* <!--End Form trigger modal Redemption
                        --> */}
                        {/* successful transaction  purchase*/}
                        <section className="success-trans">
                          <div className="row">
                            <div tabindex="-1" className="modal pmd-modal fade" id="success-transaction" aria-hidden="true">
                              <div className="modal-dialog modal-lg modal-dialog-centered">
                                <div className="modal-content b-r px-2 bg-white text-center">
                                  <div className="text-right pt-2">
                                    <button aria-hidden="true" data-dismiss="modal" className="close" type="button">×</button>
                                  </div>
                                  <div className="modal-body py-0">
                                    <img className="img-fluid" src={success} alt="" />
                                    <h3 className="success-c font-weight-500 py-3">Successful Transaction with
                                    Following Details</h3>
                                    <div className="row">
                                    <div className="col-md-5 border-right">
                                        <div className="row">
                                      <div className="col-md-6 text-left text-black">Unique Number </div>
                                      <div className="col-md-1">:</div>
                                      <div className="col-md-4 text-left">12345678</div>
                                      
                                      
                                      <div className="col-md-6 text-left text-black">Trxn Number </div>
                                      <div className="col-md-1">:</div>
                                      <div className="col-md-4 text-left">12346195</div>
                                      
                                      <div className="col-md-6 text-left text-black">Amount   </div>
                                      <div className="col-md-1">:</div>
                                      <div className="col-md-4 text-left">1,000</div>
                                      
                                      
                                      <div className="col-md-6 text-left text-black">Status   </div>
                                      <div className="col-md-1">:</div>
                                      <div className="col-md-4 text-left">Ok</div>
                                     
                                      
                                      </div>
                                      </div>

                                    <div className="col-md-7 ">
                                    <div className="row">
                                   <div className="col-md-4 text-left text-black">Folio Number </div>
                                      <div className="col-md-1">:</div>
                                      <div className="col-md-7 text-left">12345678</div>
                                   </div>
                                      <div className="row ">
                                     
                                     <div className="col-md-4 text-left text-black">Fund </div>
                                     <div className="col-md-1">:</div>
                                     <div className="col-md-7 text-left">Axis Mutual Fund</div>
</div>
                                     <div className="row">
                                     <div className="col-md-4 text-left text-black">Scheme Name </div>
                                     <div className="col-md-1">:</div>
                                     <div className="col-md-7 text-left">Axis Arbitrage Fund - Regular Growth</div>
                                     
                                   
                                   </div>
                                  
                                      </div>
                                    
                                     
                                      </div>
                                     
                                    <hr />
                                    <div className="py-2">
                                      
                                      <p className="text-black fs-13">
                                      <span className="red text-left font-weight-500 fs-16">Note:</span>  Payment link has been sent on your registered mail id and mobile number.The Link shall remain active for the next 48 hours.
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </section>
                        {/* successful transaction  end */}
                         {/* successful transaction  redemption*/}
                         <section className="success-trans">
                          <div className="row">
                            <div tabindex="-1" className="modal pmd-modal fade" id="success-transaction-redem" aria-hidden="true">
                              <div className="modal-dialog modal-lg modal-dialog-centered">
                                <div className="modal-content b-r px-2 bg-white text-center">
                                  <div className="text-right pt-2">
                                    <button aria-hidden="true" data-dismiss="modal" className="close" type="button">×</button>
                                  </div>
                                  <div className="modal-body py-0">
                                    <img className="img-fluid" src={success} alt="" />
                                    <h3 className="success-c font-weight-500 py-3">Successful Transaction with
                                    Following Details</h3>
                                    <div className="row">
                                    <div className="col-md-5 border-right">
                                        <div className="row">
                                      <div className="col-md-6 text-left text-black">Unique Number </div>
                                      <div className="col-md-1">:</div>
                                      <div className="col-md-4 text-left">12345678</div>
                                      
                                      
                                      <div className="col-md-6 text-left text-black">Trxn Number </div>
                                      <div className="col-md-1">:</div>
                                      <div className="col-md-4 text-left">12346195</div>
                                      
                                      <div className="col-md-6 text-left text-black">Amount   </div>
                                      <div className="col-md-1">:</div>
                                      <div className="col-md-4 text-left">1,000</div>
                                      
                                      
                                      <div className="col-md-6 text-left text-black">Status   </div>
                                      <div className="col-md-1">:</div>
                                      <div className="col-md-4 text-left">Ok</div>
                                     
                                      
                                      </div>
                                      </div>

                                    <div className="col-md-7 ">
                                    <div className="row">
                                   <div className="col-md-4 text-left text-black">Folio Number </div>
                                      <div className="col-md-1">:</div>
                                      <div className="col-md-7 text-left">12345678</div>
                                   </div>
                                      <div className="row ">
                                     
                                     <div className="col-md-4 text-left text-black">Fund </div>
                                     <div className="col-md-1">:</div>
                                     <div className="col-md-7 text-left">Axis Mutual Fund</div>
</div>
                                     <div className="row">
                                     <div className="col-md-4 text-left text-black">Scheme Name </div>
                                     <div className="col-md-1">:</div>
                                     <div className="col-md-7 text-left">Axis Arbitrage Fund - Regular Growth</div>
                                     
                                   
                                   </div>
                                  
                                      </div>
                                    
                                     
                                      </div>
                                     
                                    <hr />
                                    <div className="py-2">
                                      
                                      <p className="text-black fs-13">
                                      <span className="red text-left font-weight-500 fs-16">Note:</span>  Authorization link has been sent on your registered mail id and mobile number.The Link shall remain active for the next 48 hours.Kindly authorize.
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </section>
                        {/* successful transaction-redemption  end */}


                         {/*========== something went wrong=========== */}
                        
                         <section className="wrong">
                          <div className="row">
                            <div tabindex="-1" className="modal pmd-modal fade" id="wrong" aria-hidden="true">
                              <div className="modal-dialog modal-dialog-centered">
                                <div className="modal-content  bg-white text-center br-15">
                                <div className="modal-body text-center">
                  <img className="img-fluid" src={opps} alt="" />
                  <h3 className="text-black font-weight-500 py-3"> Something went wrong</h3>
                  <p className="">
                  Please try again after sometime.
                  </p>
                </div>
                <div className="bg-red text-center pt-2 order-b">
                  <a href='#' className="text-white font-weight-bold" data-dismiss="modal" >
                    <h4>OK</h4>
                  </a>
                </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </section>


          {/* ============something went wrong end============= */}
                      </div>
                    </div>
                  </div>
                  {/* Footer */}
                  <Footer />
                    {/* End of Footer */}
                  </div>
                </div>
              </div>
              </>
              )
              }
              }
              export default Transact