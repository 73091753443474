import React, { component } from 'react';
import Header from './header';
import Footer from './footer';
// import {UserAuth} from './short-components';
// import Sidebar from './sidebar';
import { Helmet } from "react-helmet";
import StyleComponent from './styleComponent';
import Axios from 'axios';
import $ from 'jquery';
import { Link, Redirect, useHistory } from 'react-router-dom';
import CanvasJSReact from '../../canvasjs/canvasjs.react';
// import portfolio from "../../assets/images/icons/new-icons/suitcase.png";
// import advisory from "../../assets/images/icons/new-icons/completed-task.png";
import nfo from "../../assets/images/icons/New folder (2)/nfo.png";
import portr from "../../assets/images/icons/New folder (2)/portr.png";
import simplysip from "../../assets/images/icons/New folder (2)/simplysip.png";
import simplysave from "../../assets/images/icons/New folder (2)/simplysave.png";
import retirement from "../../assets/images/icons/new-icons/retirement.png";
import child from "../../assets/images/icons/new-icons/education.png";
import house from "../../assets/images/icons/new-icons/discount.png";
import car from "../../assets/images/icons/new-icons/purchase.png";
import vacation from "../../assets/images/icons/new-icons/sunbed.png";
import home from "../../assets/images/icons/new-icons/renovation.png";
import wealth from "../../assets/images/icons/new-icons/money.png";
import marriage from "../../assets/images/icons/new-icons/wedding-couple.png";
import othergoals from "../../assets/images/icons/New folder (2)/othergoals.png";
import quant2 from "../../assets/images/icons/New folder (2)/quant2.png";
import rupee from "../../assets/images/icons/New folder (2)/rupee.png";
import pgim from "../../assets/images/icons/New folder (2)/pgim.png";
import boi from "../../assets/images/icons/New folder (2)/boi.png";
import canera from "../../assets/images/icons/New folder (2)/Canera1.png";
import Select from 'react-select';
import { Doughnut } from "react-chartjs-2";
// import { FaSlidersH, FaAngleLeft, FaAngleRight } from "react-icons/fa";
import 'animate.css';
import Invest_Sip from './invest-sip';
import Invest_Lumpsum from './invest-lumpsum';
import LoadingOverlay from "react-loading-overlay";
import styled, { css } from "styled-components";
var CanvasJSChart = CanvasJSReact.CanvasJSChart;



const DarkBackground = styled.div`
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 999; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */

  ${(props) =>
    props.disappear &&
    css`
      display: block; /* show */
    `}
`;
var api_url_wms = "https://wms.bfccapital.com";

class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      users: []
    };
    this.state = {
      Items: [],
      wmsloaded: false,
      familyDataLoaded: false,
      familyData: [],
      dataList: [],
    };
  }
  componentDidMount() {
    // const { authTokens } = UserAuth();
    // let history = useHistory();
    // console.log("qq",localStorage.getItem("loginUserData"))
    if (localStorage.getItem("loginUserData") == null) {
      return <Redirect to='/prodigypro/' />
    }
    const userData = JSON.parse(localStorage.getItem("loginUserData"))
    // console.log("user", userData);


    var number = 350000000;

    // console.log("Currency Data", new Intl.NumberFormat('en-IN', {currency: 'INR',maximumSignificantDigits: 3}).format(number));

    // const value = {
    //   pan: pan,
    //   name: userData.name
    // }
    const request = {
      "pan": userData.pan_card
    }
    console.log("userData.pan_card", userData.pan_card)
    // userData.pan_card
    Axios.post(api_url_wms + "/api/snapshotfamilywisetotal", request)
      .then((result) => {
        this.setState({ dataList: result.data })
        // this.setState({ wmsloaded: !this.state.wmsloaded });
        // this.setState({ familyDataLoaded: !this.state.familyDataLoaded });
        // console.log("userData", this.state.dataList.Totalmarketvalue)
        localStorage.setItem("snapShotData", JSON.stringify(result.data));
      })
    var setData = [];
    Axios.post(api_url_wms + "/api/snapshotfamilywise", request)
      .then((result) => {
        this.setState({ familyData: result.data.finalArray })
        this.setState({ wmsloaded: !this.state.wmsloaded });
        this.setState({ familyDataLoaded: !this.state.familyDataLoaded });
        // console.log("userData", result)
        localStorage.setItem("allFamilyMembers", JSON.stringify(result.data.finalArray));

        // for (var i = 0; i < result.data.finalArray.length; i++) {
        //   const portfolioData = {
        //     "pan": result.data.finalArray[i].Pan,
        //     "gpan": result.data.finalArray[i].Gpan,
        //     "userid": result.data.finalArray[i].userid
        //   }
        //   // Axios.post(api_url_wms + "/api/detailedportfolio", portfolioData)
        //   //   .then((result) => {
        //   //     // this.setState({ userData: result.data.data })
        //   //     // this.setState({ wmsloaded: !this.state.wmsloaded });
        //   //     // this.setState({ familyDataLoaded: !this.state.familyDataLoaded });
        //   //     // console.log("result.data.data", result.data.data)
        //   //     // return result.data.data
        //   //     // localStorage.setItem("familyPortfolio", JSON.stringify(result.data.data));

        //   //     setData.push(JSON.parse(localStorage.getItem('familyPortfolio')));
        //   //     localStorage.setItem('familyPortfolio', JSON.stringify(setData));

        //   //   })
        // }

      })
    // console.log("test test", JSON.parse(localStorage.getItem('familyPortfolio')))
  }

  profilioDetail = (pan, name) => {
    this.setState({ profilioPan: pan, profilioName: name })
  }
  render() {
    const data = {
      labels: ["Equity", "Debt", "Gold"],
      datasets: [
        {
          label: "Hours Studied in Geeksforgeeks",
          data: [this.state.dataList.equityPercentFinal, this.state.dataList.debtPercentFinal, this.state.dataList.goldPercentFinal],
          backgroundColor: ["#97C5FB", "#F06D70", "#FBDE80"],
        }
      ]
    }
    if (this.state.profilioName) {
      return <Redirect to={{
        pathname: "/prodigypro/dashboard/portfolio",
        profilioPan: this.state.profilioPan,
        profilioName: this.state.profilioName,
      }} />
    }
    if (localStorage.getItem("loginUserData") == null) {
      return <Redirect to='/prodigypro/' />
    }
    let e; let d; let g; const u = [];

    const options = {
      //exportEnabled: true,
      animationEnabled: true,
      // title: {
      //   text: "Website Traffic Sources"
      // },
      data: [{
        type: "doughnut",
        radius: "70%",
        startAngle: 75,
        toolTipContent: "<b>{label}</b>: {y}%",
        showInLegend: "true",
        legendText: "{label}",
        indexLabelFontSize: 16,
        indexLabel: "{label} - {y}%",
        dataPoints: u,
      }]
    }
    const profile = [
      { value: "--select--", label: "--select--" },
    ];
    const amc = [
      { value: "--select--", label: "--select--" },
    ];
    const scheme = [
      { value: "--select--", label: "--select--" },
    ];
    const payment_mode = [
      { value: "Net Banking", label: "Net Banking" },
      { value: "UPI", label: "UPI" },
      { value: "RTGS/NEFT", label: "RTGS/NEFT" },
      { value: "Debit Mandate", label: "Debit Mandate" },
    ];
    const bankname = [
      { value: "--select--", label: "--select--" },
    ];
    const modeofinvest = [
      { value: "Lumpsum", label: "Lumpsum" },
      { value: "SIP", label: "SIP" },
    ];
    return (
      <>
        <Helmet>
          <title>Dashboard - Prodigy Pro</title>
        </Helmet>
        <style>
          {`
        
        `}
        </style>
        {/* Page Wrapper */}
        <div id="wrapper" className='home-page'>
          {/* 
<div id="overlay" >
  <div className="spinner"></div>
  <br />Loading...
</div>
*/}

          {/* End of Sidebar */}
          {/* Content Wrapper */}
          <div id="content-wrapper" className="d-flex flex-column">
            {/* Main Content */}
            <div id="content">
              {/* Topbar */}
              <Header />
              {/* End of Topbar */}
              {/* Begin Page Content */}
              {/* 
    <div className="container-fluid">
      */}
              <div className="container-fluid px-lg-5 home-main">

                {this.state.familyDataLoaded && this.state.wmsloaded ? (
                  <DarkBackground disappear={false}>
                    <LoadingOverlay
                      active={true}
                      style={{ color: "red" }}
                      // spinner={<BounceLoader />}
                      spinner={true}
                    >
                      {/* <p>Some content or children or something.</p> */}
                    </LoadingOverlay>
                  </DarkBackground>
                ) : (
                  <DarkBackground disappear={true}>
                    <LoadingOverlay
                      active={true}
                      style={{ color: "red" }}
                      // spinner={<BounceLoader />}
                      spinner={true}
                    >
                    </LoadingOverlay>
                  </DarkBackground>
                )}

                <div className='row px-lg-5 pb-5'>
                  <div className="col md-12">
                    {/* Page Heading */}
                    <div className="d-sm-flex align-items-center justify-content-between mb-4">
                      {/* 
              <h1 className="h3 mb-0 text-gray-800">Dashboard</h1>
              */}
                      {/* <a href="#" className="d-none d-sm-inline-block btn btn-sm btn-danger shadow-sm"><i className="fas fa-download fa-sm text-white-50" /> Generate Report</a> */}
                    </div>
                    {/* Content Row */}
                    <section className="pb-4">
                      <div className="row">
                        <div className='col-lg-8 col-md-12 col-sm-12'>

                          <div className="portfolio-s p-portfolio">
                            <div className="row">
                              <div className="col-md-6  pl-3 py-3 ">
                                <div className="row">
                                  <div className="col-md-12">
                                    <h5 className='gray lc gray-color'>Market Value</h5>
                                    <h3 ><span className="rupee-fontsnapshot">₹</span><span className='lc text-dark'>{this.state.dataList.Totalmarketvalue}</span></h3>
                                    <hr className='border-bottom' />
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-md-6 col-sm-6 border-right">
                                    <span className='gray lc fs-13 gray-color'>Purchase Cost</span>
                                    <br />
                                    <h5><span className="rupee-fontsnapshot">₹</span><span className='lc text-dark'>{this.state.dataList.Totalpurchase}</span></h5>
                                  </div>
                                  <div className="col-md-6 col-sm-6">
                                    <span className='gray lc fs-13 pb-2 gray-color'>GAIN/LOSS</span> <br />
                                    <span className='lc text-dark'>{this.state.dataList.Gainloss} {(this.state.dataList.Gainloss > 0) ? <span className="fa fa-arrow-up text-success"></span> : (this.state.dataList.Gainloss === 0) ? null : (<span className="fa fa-arrow-down text-danger"></span>)}</span>
                                  </div>
                                </div>
                                <hr className='border-bottom' />
                                <div className="row">
                                  <div className="col-md-4 col-sm-4 border-right">
                                    <span className='gray lc fs-13 gray-color'>Dividend</span>
                                    <br />
                                    <h5><span className='lc text-dark'>0</span></h5>
                                  </div>
                                  <div className="col-md-5 col-sm-5 border-right">
                                    <span className='gray lc fs-13 pb-2  gray-color'>Day's Change</span> <br />
                                    <span className='lc  text-dark'>{this.state.dataList.Totaldayschange} {(this.state.dataList.Totaldayschange > 0) ? <span className="fa fa-arrow-up text-success"></span> : (this.state.dataList.Totaldayschange === 0) ? null : (<span className="fa fa-arrow-down text-danger"></span>)}</span>
                                  </div>
                                  <div className="col-md-3 col-sm-3">
                                    <span className='gray lc fs-13 pb-2  gray-color'>CAGR</span> <br />
                                    <span className='lc   text-dark'>{this.state.dataList.Finalcagr}%</span>
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-6 pt-5 text-center">
                                <Doughnut data={data} />
                                <h5 className='pt-3'>Asset Allocation</h5>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className='col-lg-4  col-md-12 col-sm-12'>
                          <div className="quick-invest ml-sm-0 mt-sm-2 mt-lg-0">
                            {/* <!-- Button trigger modal --> */}
                            <div className="quick-access-title mt-1">
                              <h3>Quick Invest</h3>
                            </div>
                            <a href='#' className="text-decoration-none" data-target="#form-dialog" data-toggle="modal" type="button">
                              <div className='row'>
                                <div className="col-md-12">
                                  <div className='quick-c py-3' >
                                    Avoid the hassle and Deploy your funds in less than a Minute !
                                  </div>
                                  <input type="button" className='new-btn1 my-4' value="Invest Now" />
                                </div>
                              </div>
                            </a>
                            {/* <!============== Form Modal ================> */}
                            <div tabindex="-1" className="modal pmd-modal animate__animated animate__zoomIn animate__fast invest-q" id="form-dialog" aria-hidden="true">
                              <div className="modal-dialog modal-lg">
                                <div className="modal-content b-r p-3">
                                  <div className="modal-header border-0">
                                    <div className="quick-access-title">
                                      <h3>Quick Invest</h3>
                                    </div>
                                    <button aria-hidden="true" data-dismiss="modal" className="close" type="button">×</button>
                                  </div>
                                  <div className="modal-body">
                                    <form>
                                      <div className='row'>
                                        <div className='col-lg-4 col-md-6'>
                                          <div className="form-group pmd-textfield pmd-textfield-floating-label">
                                            <label for="select-s" className='lb'>Select Profile<span className="text-danger">*</span></label><br />
                                            <Select className='' options={profile} />
                                          </div>
                                        </div>
                                        <div className='col-lg-4 col-md-6'>
                                          <div className="form-group pmd-textfield pmd-textfield-floating-label">
                                            <label for="invest" className='lb'>Select AMC<span className="text-danger">*</span></label><br />
                                            <Select className='' options={amc} />
                                          </div>
                                        </div>
                                        <div className='col-lg-4 col-md-6'>
                                          <p className="text-label mb-1 mt-1 p-radio lb">
                                            Asset Class
                                            <spna className="text-danger">*</spna>
                                          </p>
                                          <div className='pt-2'>
                                            <input className=" input-text" id="equity" type="radio" name="asset" value="Equity" onChange={this.targetScheme} />
                                            <label htmlFor="equity" className="">Equity</label>
                                            <input className="input-text ml-3" id="debt" type="radio" name="asset" value="DEBT" onChange={this.targetScheme} />
                                            <label htmlFor="debt" className="">Debt</label>
                                            <br></br>  <small className="text-danger pull-left">{this.state.asset_err}</small>
                                          </div>
                                        </div>
                                      </div>
                                      <div className='row'>
                                        <div className='col-lg-4 col-md-6'>
                                          <p className="text-label mb-1 mt-1 p-radio lb">
                                            Option
                                            <spna className="text-danger">*</spna>
                                          </p>
                                          <div className='pt-2'>
                                            <input className=" input-text" id="growth" type="radio" name="growth" value="GROWTH" onChange={this.targetScheme} />
                                            <label htmlFor="growth" className="">Growth</label>
                                            <input className="input-text ml-3" id="dividend" type="radio" name="growth" value="Dividend" onChange={this.targetScheme} />
                                            <label htmlFor="dividend" className="">IDCW  </label>
                                          </div>
                                        </div>
                                        <div className='col-lg-4 col-md-6'>
                                          <div className="form-group pmd-textfield pmd-textfield-floating-label">
                                            <label for="sip-t" className='lb'>Select Scheme<span className="text-danger">*</span></label><br />
                                            <Select className='' options={scheme} />
                                          </div>
                                        </div>
                                        <div className='col-lg-4 col-md-6'>
                                          <div className="form-group pmd-textfield pmd-textfield-floating-label">
                                            <label className="control-label lb" for="amount">Enter Amount<span className="text-danger">*</span></label>
                                            <input type="text" className="form-control border-0" name='amount' placeholder='Enter Amount' />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="row">
                                        <div className='col-lg-5 col-md-6'>
                                          <div className="form-group pmd-textfield pmd-textfield-floating-label">
                                            <label htmlFor="payment_mode" className="text-label">Select Payment Mode  <span className="text-danger">*</span></label><br />
                                            <Select className='' options={payment_mode} />
                                          </div>
                                        </div>
                                        <div className='col-lg-7 col-md-6'>
                                          <div className="pt-4 mt-2">
                                            <input className=" input-text" id="emailLink" type="radio" name="payType" value="N" />
                                            <label htmlFor="emailLink" className="">Link On Email</label>
                                            <input className="input-text ml-3" id="immediatePay" type="radio" name="payType" value="Y" />
                                            <label htmlFor="immediatePay" className="">Immediate Payment</label>
                                          </div>
                                        </div>
                                        <div className='col-lg-5 col-md-6'>
                                          <div className="form-group pmd-textfield pmd-textfield-floating-label">
                                            <label for="select-bank" className='lb text-label'>Select Bank<span className="text-danger">*</span></label><br />
                                            <Select className='' options={bankname} />
                                          </div>
                                        </div>
                                        <div className="col-lg-7 col-md-6 pt-4 mt-2">
                                          <button data-dismiss="modal" className="new-btn1 float-right" type="button">Invest Now</button>
                                        </div>
                                      </div>
                                    </form>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                    {/* ====================family Porfolio====================== */}
                    <section className='family-portfolio-s py-4'>
                      <div className="quick-access-title pb-4">
                        <h3>Family Portfolio</h3>
                      </div>
                      <div className='family-portfolio'>
                        <table className='table text-center'>
                          <tr className='red'>
                            <th className='pl-4 '>Name</th>
                            <th className=''>Purchase Cost <span className="rupee-fontfamily" >(₹)</span></th>
                            <th className=''>Market Value<span className="rupee-fontfamily">(₹)</span></th>
                            <th className='d-none-m'>Gain/Loss</th>
                            <th>CAGR(%)</th>
                          </tr>

                          {this.state.familyData
                            ? this.state.familyData.map((item, key) => (

                              <tr>
                                <td className="pl-4">

                                  {/* {(item.Pan == '') ? (
                                    <a href={"dashboard/portfolio?gpan=" + item.Gpan + "&user=" + item.userid}>{item.Name}</a>
                                  ) : (<a href={"dashboard/portfolio?pan=" + item.Pan + "&user=" + item.userid}>{item.Name}</a>)} */}

                                  {(item.Pan == '') ? (
                                    <a href={"dashboard/portfolio?gpan=" + item.Gpan + "&user=" + item.userid + "&name=" + item.Name}>{item.Name}</a>
                                  ) : (<a href={"dashboard/portfolio?pan=" + item.Pan + "&user=" + item.userid + "&name=" + item.Name}>{item.Name}</a>)}


                                </td>
                                <td>{item.Totalpurchase}</td>
                                <td>{item.TotalMarketValue}</td>
                                <td>
                                  {" "}
                                  {item.Gainloss}

                                  {(item.Gainloss > 0) ? <span className="fa fa-arrow-up text-success"></span> : (item.Gainloss === 0) ? null : (<span className="fa fa-arrow-down text-danger"></span>)}
                                  {" "}
                                </td>
                                <td>{item.Cagr}</td>
                              </tr>
                            ))
                            : null}

                        </table>
                      </div>
                    </section>
                    <section className="quick-access  pt-4">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="quick-access-title pb-4">
                            <h3>Quick Access</h3>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-3">
                          <div className="quick-card mt-sm-2 mt-lg-0">
                            <a className="quick-link" href="/prodigypro/dashboard/portfolio-review">
                              <img src={portr} className="mr-2 new-icon" alt='' />
                              <span>Portfolio Review</span></a>
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="quick-card mt-sm-2 mt-lg-0">
                            <a className="quick-link" href="/prodigypro/dashboard/nfo">
                              <img src={nfo} className="mr-2 new-icon" alt='' />
                              <span>NFO Live</span></a>
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="quick-card mt-sm-2 mt-lg-0">
                            <a className="quick-link" href="/prodigypro/dashboard/simply-sip">
                              <img src={simplysip} className="mr-2 img-fluid new-icon" alt='' />
                              <span>Simply Sip</span></a>
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="quick-card mt-sm-2 mt-lg-0">
                            <a className="quick-link" href="/prodigypro/dashboard/simply-save">
                              <img src={simplysave} className="mr-2 img-fluid new-icon" alt='' />
                              <span>Simply Save</span></a>
                          </div>
                        </div>
                      </div>
                    </section>
                    <section className="goal-plan">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="goal-plan-title pb-5 pt-5">
                            <h3>Goal Planning</h3>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-1 col-m  col-sm-12">
                          <a className=" bg-white goal-link" href="/prodigypro/dashboard/retirement-goal">
                            <div className="goal-card text-center">
                              <img src={retirement} className="mx-3 new-icon bg-c p-3" alt='' /><br />
                              <h4 className="pt-4">Retirement</h4>
                            </div>
                          </a>
                        </div>
                        <div className="col-md-1 col-m">
                          <a className="bg-white goal-link" href="/prodigypro/dashboard/child-education-goal">
                            <div className="goal-card text-center">
                              <img src={child} className="mx-3 new-icon bg-c p-3" alt='' />
                              <h4 className="pt-4">Child Education</h4>
                            </div>
                          </a>
                        </div>
                        <div className="col-md-1 col-m">
                          <a className="bg-white goal-link" href="/prodigypro/dashboard/house-purchase-goal">
                            <div className="goal-card text-center">
                              <img src={house} className="mx-3 new-icon bg-c p-3" alt='' />
                              <h4 className="pt-4">House Purchase</h4>
                            </div>
                          </a>
                        </div>
                        <div className="col-md-1 col-m">
                          <a className="bg-white goal-link" href="/prodigypro/dashboard/car-purchase-goal">
                            <div className="goal-card text-center">
                              <img src={car} className="mx-3 new-icon bg-c p-3" alt='' />
                              <h4 className="pt-4">Car Purchase</h4>
                            </div>
                          </a>
                        </div>
                        <div className="col-md-1 col-m">
                          <a className="bg-white goal-link" href="/prodigypro/dashboard/wealth-creation-goal">
                            <div className="goal-card text-center" >
                              <img src={wealth} className="mx-3 new-icon bg-c p-3" alt='' />
                              <h4 className="pt-4">Wealth Creation</h4>
                            </div>
                          </a>
                        </div>
                        <div className="col-md-1 col-m">
                          <a className="bg-white goal-link" href="/prodigypro/dashboard/home-renovetion-goal">
                            <div className="goal-card text-center">
                              <img src={home} className="mx-3 new-icon bg-c p-3" />
                              <h4 className="pt-4">Home Renovation</h4>
                            </div>
                          </a>
                        </div>
                        <div className="col-md-1 col-m">
                          <a className=" bg-white goal-link" href="/prodigypro/dashboard/vacation-goal">
                            <div className="goal-card text-center">
                              <img src={vacation} className="mx-3 new-icon bg-c p-3" />
                              <h4 className="pt-4">Vacation</h4>
                            </div>
                          </a>
                        </div>
                        <div className="col-md-1 col-m">
                          <a className="bg-white goal-link " href="/prodigypro/dashboard/child-marriage-goal">
                            <div className="goal-card text-center">
                              <img src={marriage} className="mx-3 new-icon bg-c p-3" />
                              <h4 className="pt-4">Child Marriage</h4>
                            </div>
                          </a>
                        </div>
                        <div className="col-md-1 col-m">
                          <a className="bg-white goal-link " href="/prodigypro/dashboard/customize-goal">
                            <div className="goal-card text-center">
                              <img src={othergoals} className="mx-3 new-icon bg-c p-3" />
                              <h4 className="pt-4">Customize Goal</h4>
                            </div>
                          </a>
                        </div>
                      </div>
                    </section>
                    {/* =============All mutual funds Section=============== */}
                    <section className='all-mf-s'>
                      <div className="row">
                        <div className="col-md-12">
                          <div className="goal-plan-title pb-5 pt-5">
                            <h3>All Mutual Funds</h3>
                          </div>
                        </div>
                        {/* =============Tabs for mf================ */}
                        <div className='col-md-8 col-sm-12 offset-md-2 filter-all-mf'>
                          <ul class="nav nav-pills navtop  ">
                            <li class="nav-item border-right">
                              <a class="nav-link active" data-toggle="tab" href="#tab1"> <span>Equity</span></a>
                            </li>
                            <li class="nav-item border-right">
                              <a class="nav-link" data-toggle="tab" href="#tab2"><span>Tax Saving</span></a>
                            </li>
                            <li class="nav-item border-right">
                              <a class="nav-link" data-toggle="tab" href="#tab3"><span>Hybrid</span></a>
                            </li>
                            <li class="nav-item">
                              <a class="nav-link " data-toggle="tab" href="#tab4">
                                <span>Debt</span>
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="text-center">
                        <div className="col-md-12 col-sm-12">
                          <div className='col-md-6 offset-md-3 py-4'>
                            <ul class="nav nav-pills nav-fill navtop longshort d-sm-inline-flex">
                              <li class="nav-item">
                                <a class="nav-link active fs-16" href="#menu1" data-toggle="tab">Short Term</a>
                              </li>
                              <li class="nav-item">
                                <a class="nav-link fs-16" href="#menu2" data-toggle="tab">Long Term</a>
                              </li>
                            </ul>
                          </div>
                          <div className="table-responsive">
                            <table className='table text-center'>
                              <tr className='bg-gray'>
                                <th></th>
                                <th className='schemew'>Scheme Name</th>
                                <th style={{ width: "10em" }}>1Y Return</th>
                                <th style={{ width: "10em" }}>3Y Return</th>
                                <th style={{ width: "10em" }}>5Y Return</th>
                                <th style={{ width: "10em" }}>7Y Return</th>
                                <th style={{ width: "23em" }}></th>
                                <th style={{ width: "23em" }}></th>
                              </tr>
                            </table>
                          </div>
                          <div class="tab-content mt-3">
                            <div class="tab-pane show active" id="tab1" role="tabpanel">
                              <div className="table-responsive">
                                <table className='table text-center'>
                                  <tr>
                                    <td> <img src={quant2} alt="" className='maxw' /></td>
                                    <td className='pt-4 schemew'>Quant Small Cap Fund Direct Plan Growth</td>
                                    <td className='pt-4 yearw'>37.54%</td>
                                    <td className='pt-4 yearw'>37.54%</td>
                                    <td className='pt-4 yearw'>37.54%</td>
                                    <td className='pt-4 yearw'>37.54%</td>
                                    <td className='bw'><a className="btn-outline" href="javascript:void(0);" data-target="#invest-sip" data-toggle="modal" type="button">Invest</a><a className="btn-outline ml-2" href="javascript:void(0);" data-target="#add-to-cart" data-toggle="modal" type="button">Add To Cart</a></td>
                                    {/* <td className='bw'> </td> */}
                                  </tr>
                                  <tr>
                                    <td> <img src={quant2} alt="" className='maxw' /></td>
                                    <td className='pt-4 schemew'>Quant Small Cap Fund Direct Plan Growth</td>
                                    <td className='pt-4'>37.54%</td>
                                    <td className='pt-4'>37.54%</td>
                                    <td className='pt-4'>37.54%</td>
                                    <td className='pt-4'>37.54%</td>
                                    <td className='bw'><a className="btn-outline" href="javascript:void(0);" data-target="#invest-lumpsum" data-toggle="modal" type="button">Invest</a><a className="btn-outline ml-2" href="javascript:void(0);" data-target="#add-to-cart" data-toggle="modal" type="button">Add To Cart</a></td>
                                  </tr>
                                  <tr>
                                    <td> <img src={quant2} alt="" className='maxw' /></td>
                                    <td className='pt-4 schemew'>Quant Small Cap Fund Direct Plan Growth</td>
                                    <td className='pt-4'>37.54%</td>
                                    <td className='pt-4'>37.54%</td>
                                    <td className='pt-4'>37.54%</td>
                                    <td className='pt-4'>37.54%</td>
                                    <td><button className='btn-outline'>Invest</button><button className='btn-outline'>Add To Cart</button></td>
                                  </tr>
                                  <tr>
                                    <td> <img src={quant2} alt="" className='maxw' /></td>
                                    <td className='pt-4 schemew'>Quant Small Cap Fund Direct Plan Growth</td>
                                    <td className='pt-4'>37.54%</td>
                                    <td className='pt-4'>37.54%</td>
                                    <td className='pt-4'>37.54%</td>
                                    <td className='pt-4'>37.54%</td>
                                    <td><button className='btn-outline'>Invest</button><button className='btn-outline'>Add To Cart</button></td>
                                  </tr>
                                </table>
                              </div>
                            </div>
                            <div class="tab-pane " id="tab2" role="tabpanel">
                              <div className="table-responsive">
                                <table className='table text-center'>
                                  <tr>
                                    <td> <img src={pgim} alt="" className='maxw' /></td>
                                    <td className='pt-4 schemew'>PGMI India Midcap Opportunities Fund Direct Growth</td>
                                    <td className='pt-4 yearw'>27.54%</td>
                                    <td className='pt-4 yearw'>27.54%</td>
                                    <td className='pt-4 yearw'>27.54%</td>
                                    <td className='pt-4 yearw'>27.54%</td>
                                    <td className='bw'><button className='btn-outline'>Invest</button><button className='btn-outline'>Add To Cart</button></td>
                                  </tr>
                                  <tr>
                                    <td> <img src={pgim} alt="" className='maxw' /></td>
                                    <td className='pt-4 schemew'>PGMI India Midcap Opportunities Fund Direct Growth</td>
                                    <td className='pt-4'>27.54%</td>
                                    <td className='pt-4'>27.54%</td>
                                    <td className='pt-4'>27.54%</td>
                                    <td className='pt-4'>27.54%</td>
                                    <td><button className='btn-outline'>Invest</button><button className='btn-outline'>Add To Cart</button></td>
                                  </tr>
                                  <tr>
                                    <td> <img src={pgim} alt="" className='maxw' /></td>
                                    <td className='pt-4 schemew'>PGMI India Midcap Opportunities Fund Direct Growth</td>
                                    <td className='pt-4'>27.54%</td>
                                    <td className='pt-4'>27.54%</td>
                                    <td className='pt-4'>27.54%</td>
                                    <td className='pt-4'>27.54%</td>
                                    <td><button className='btn-outline'>Invest</button><button className='btn-outline'>Add To Cart</button></td>
                                  </tr>
                                  <tr>
                                    <td> <img src={pgim} alt="" className='maxw' /></td>
                                    <td className='pt-4 schemew'>PGMI India Midcap Opportunities Fund Direct Growth</td>
                                    <td className='pt-4'>27.54%</td>
                                    <td className='pt-4'>27.54%</td>
                                    <td className='pt-4'>27.54%</td>
                                    <td className='pt-4'>27.54%</td>
                                    <td><button className='btn-outline'>Invest</button><button className='btn-outline'>Add To Cart</button></td>
                                  </tr>
                                </table>
                              </div>
                            </div>
                            <div class="tab-pane " id="tab3" role="tabpanel">
                              <div className="table-responsive">
                                <table className='table text-center'>
                                  <tr>
                                    <td> <img src={boi} alt="" className='maxw' /></td>
                                    <td className='pt-4 schemew'>Bank of India Small Cap Fund Direct Growth</td>
                                    <td className='pt-4 yearw'>30.04%</td>
                                    <td className='pt-4 yearw'>30.04%</td>
                                    <td className='pt-4 yearw'>30.04%</td>
                                    <td className='pt-4 yearw'>30.04%</td>
                                    <td className='bw'><button className='btn-outline'>Invest</button><button className='btn-outline'>Add To Cart</button></td>
                                  </tr>
                                  <tr>
                                    <td> <img src={boi} alt="" className='maxw' /></td>
                                    <td className='pt-4 schemew'>Bank of India Small Cap Fund Direct Growth</td>
                                    <td className='pt-4'>30.04%</td>
                                    <td className='pt-4'>30.04%</td>
                                    <td className='pt-4'>30.04%</td>
                                    <td className='pt-4'>30.04%</td>
                                    <td><button className='btn-outline'>Invest</button><button className='btn-outline'>Add To Cart</button></td>
                                  </tr>
                                  <tr>
                                    <td> <img src={boi} alt="" className='maxw' /></td>
                                    <td className='pt-4 schemew'>Bank of India Small Cap Fund Direct Growth</td>
                                    <td className='pt-4'>30.04%</td>
                                    <td className='pt-4'>30.04%</td>
                                    <td className='pt-4'>30.04%</td>
                                    <td className='pt-4'>30.04%</td>
                                    <td><button className='btn-outline'>Invest</button><button className='btn-outline'>Add To Cart</button></td>
                                  </tr>
                                  <tr>
                                    <td> <img src={boi} alt="" className='maxw' /></td>
                                    <td className='pt-4 schemew'>Bank of India Small Cap Fund Direct Growth</td>
                                    <td className='pt-4'>30.04%</td>
                                    <td className='pt-4'><span>30.04%</span></td>
                                    <td className='pt-4'>30.04%</td>
                                    <td className='pt-4'>30.04%</td>
                                    <td><button className='btn-outline'>Invest</button><button className='btn-outline'>Add To Cart</button></td>
                                  </tr>
                                </table>
                              </div>
                            </div>
                            <div class="tab-pane " id="tab4" role="tabpanel">
                              <div className="table-responsive">
                                <table className='table text-center'>
                                  <tr>
                                    <td> <img src={canera} alt="" className='maxw9' /></td>
                                    <td className=' schemew'>Canara Robeco Small Cap Fund Direct Growth</td>
                                    <td className='pt-4 yearw'>7.54%</td>
                                    <td className='pt-4 yearw'><span>7.54%</span></td>
                                    <td className='pt-4 yearw'>7.54%</td>
                                    <td className='pt-4 yearw'>7.54%</td>
                                    <td className='bw'><button className='btn-outline'>Invest</button><button className='btn-outline'>Add To Cart</button></td>
                                  </tr>
                                  <tr>
                                    <td> <img src={canera} alt="" className='maxw9' /></td>
                                    <td className=' schemew'>Canara Robeco Small Cap Fund Direct Growth</td>
                                    <td className='pt-4'>7.54%</td>
                                    <td className='pt-4' ><span>7.54%</span></td>
                                    <td className='pt-4'>7.54%</td>
                                    <td className='pt-4' ><span>7.54%</span></td>
                                    <td><button className='btn-outline'>Invest</button><button className='btn-outline'>Add To Cart</button></td>
                                  </tr>
                                  <tr>
                                    <td> <img src={canera} alt="" className='maxw9' /></td>
                                    <td className=' schemew'>Canara Robeco Small Cap Fund Direct Growth</td>
                                    <td className='pt-4'>7.54%</td>
                                    <td className='pt-4' ><span>7.54%</span></td>
                                    <td className='pt-4'>7.54%</td>
                                    <td className='pt-4' ><span>7.54%</span></td>
                                    <td><button className='btn-outline'>Invest</button><button className='btn-outline'>Add To Cart</button></td>
                                  </tr>
                                  <tr>
                                    <td> <img src={canera} alt="" className='maxw9' /></td>
                                    <td className=' schemew'>Canara Robeco Small Cap Fund Direct Growth</td>
                                    <td className='pt-4'>7.54%</td>
                                    <td className='pt-4' ><span>7.54%</span></td>
                                    <td className='pt-4'>7.54%</td>
                                    <td className='pt-4' ><span>7.54%</span></td>
                                    <td><button className='btn-outline'>Invest</button><button className='btn-outline'>Add To Cart</button></td>
                                  </tr>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <Invest_Sip />
                      <Invest_Lumpsum />
                    </section>
                    {/* =============add to cart modal ============== */}
                    <section className="invest-section">
                      <div className="row">
                        <div tabindex="-1" className="modal pmd-modal animate__animated animate__zoomIn animate__fast" id="add-to-cart" aria-hidden="true">
                          <div className="modal-dialog modal-dialog-centered">
                            <div className="modal-content b-r p-3 " >
                              <div className=" border-0">
                                <button aria-hidden="true" data-dismiss="modal" className="close" type="button">×</button>
                              </div>
                              <div className="modal-body">
                                <form>
                                  <div className='row'>
                                    <div className='col-md-12'>
                                      <div className="form-group pmd-textfield pmd-textfield-floating-label">
                                        <label className="control-label lb " for="email">Mode Of Investment<span className="text-danger">*</span></label>
                                        <Select className=' bg-c' options={modeofinvest} />
                                      </div>
                                    </div>
                                    <div className="col-md-12">
                                      <div className="modal-footer border-0 text-right">
                                        <a type="button" className="btn btn-danger shadow-sm" href="javascript:void(0);">Add to Cart</a>
                                      </div>
                                    </div>
                                  </div>
                                </form>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                    {/* =============add to cart modal end ============== */}
                    {/* Content Row */}
                  </div>
                  {/* /.container-fluid */}
                </div>
                {/* End of Main Content */}
                {/* Footer */}
                <Footer />
                {/* End of Footer */}
              </div>
            </div>
          </div>
          {/* End of Content Wrapper */}
        </div>
        {/* End of Page Wrapper */}
      </>
    )
  }
}
export default Home