import React, { component } from 'react';
import Header from './header';
import Footer from './footer';
import { Helmet } from "react-helmet";
import Axios from 'axios';
import $ from 'jquery';
import Select from 'react-select';

import LoadingOverlay from "react-loading-overlay";
import "./styles.css";
import styled, { css } from "styled-components";
import { liveurl } from "../../utils/urls"


const DarkBackground = styled.div`
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 999; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */

  ${props =>
    props.disappear &&
    css`
      display: block; /* show */
    `}
`;
class Tax_Saving_Investments extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      items: [],
      isLoaded: false,
      loded: true,
      currentDate: '',
      err: '',
      filterIVName: [],
      invName: '',
      totalAmounts: '',
      toggleTriggred: false,
      show: '',
      clas: '',
      total: 0,
      filterFreshIv: [],
      subTotal: 0,
      arr: [],
      currentYear: '',
      wait: '',
      uniqData: []


    };

    this.onData = this.onData.bind();
  }

  componentDidMount() {
    this.setState({ loded: !this.state.loded })
    const userData = JSON.parse(localStorage.getItem("loginUserData"))
    var d = new Date();
    this.setState({ currentYear: `${d.getFullYear() - 1}` + "-" + `${d.getFullYear()}` })

    const data = {
      fromyear: d.getFullYear() - 1,
      toyear: d.getFullYear(),
      pan: userData.pan_card,
      name: userData.name,
    }


    Axios.post(liveurl + "/api/gettaxsavinguserwise", data)
      .then((response) => {
        if (response.data.data.length == 0) {
          this.setState({ wait: "No Data Found", loded: true, items: [], total: 0, filterIVName: [], filterFreshIv: [], arr: [] });

        } else {
          this.setState({ wait: "", isLoaded: true, items: response.data.data });
          const uniquePan = [...new Set(response.data.data.map(q => q.PAN))];
          this.setState({ isLoaded: true, uniquePan: uniquePan });
          console.log("items", this.state.items);

          var totalePrice = 0;
          var totalePriceArray = [];
          let oldName = [];
          oldName = this.state.items



          oldName = oldName.filter(
            (temp => a =>
              (k => !temp[k] && (temp[k] = true))(a.INVNAME + '|' + a.INVNAME)
            )(Object.create(null))
          );
          console.log("oldName", oldName);
          oldName.map((data) => {
            console.log("data ", data);
            totalePrice += data.AMOUNT;
            totalePriceArray.push(totalePrice);

          })
          console.log("aaaaaaaaaaa", totalePriceArray);
          //******************************************* */
          let arr = [];
          oldName.forEach(old => {
            if (this.state.items.find(item => item.INVNAME == old.INVNAME)) {
              arr.push({ name: old.INVNAME, subtotal: 0 })
              console.log("true found");
            }
          })
          this.getInvName(arr[0].name, false, true)
          this.state.items.map(item => {
            // console.log("index ", arr.findIndex(value => value.name == item.INVNAME));
            arr[arr.findIndex(value => value.name == item.INVNAME)].subtotal += item.AMOUNT;
            console.log("index value ", arr[arr.findIndex(value => value.name == item.INVNAME)].subtotal += item.AMOUNT)
          })

          console.log("after modification arr ", arr);

          //********************************************************** */
          var totalePrice = 0;
          this.state.items.map((data) => {
            totalePrice += data.AMOUNT;
          })
          // console.log("amount", totalePrice);
          this.setState({ total: totalePrice, filterFreshIv: oldName, arr: arr, loded: true })
        }
      })
      .catch((err) => {
        this.setState({ wait: "No Data Found", })
      })

  }
  getInvName = (inv, idx, isFirst) => {

    let totalAmount = 0;

    let filterIVNames = [];

    this.state.items.filter(name => {
      if (name.INVNAME.includes(inv)) {
        totalAmount = totalAmount + name.AMOUNT;
        filterIVNames.push(name);
        this.setState({ filterIVName: filterIVNames, totalAmounts: totalAmount })



      }
    })
    if (isFirst) {
      setTimeout(() => {
        $('#collapseOne_0').addClass('show')
      }, 100);
    }
    console.log("push data ", this.state.filterIVName)
  }

  onData = e => {
    this.setState({ loded: !this.state.loded })
    const userData = JSON.parse(localStorage.getItem("loginUserData"))
    var time = e.value;
    console.log("time", time);
    const answer_array = time.split('-');
    if (time) {
      const data = {
        fromyear: answer_array[0],
        toyear: answer_array[1],
        pan: userData.pan_card,
        name: userData.name
      }


      this.setState({ isLoaded: true, uniquePan: '' });
      Axios.post(liveurl + "/api/gettaxsavinguserwise", data)
        .then((response) => {
          if (response.data.data.length == 0) {
            this.setState({ wait: "No Data Found", loded: true, items: [], total: 0, filterIVName: [], filterFreshIv: [], arr: [] });

          } else {

            localStorage.setItem("userScheme", answer_array)
            this.setState({ wait: "", isLoaded: true, items: response.data.data });
            const uniquePan = [...new Set(response.data.data.map(q => q.PAN))];
            this.setState({ isLoaded: true, uniquePan: uniquePan, loded: true });
            console.log("items", this.state.items);

            var totalePrice = 0;
            this.state.items.map((data) => {
              totalePrice += data.AMOUNT;
            })

            let oldName = [];
            oldName = this.state.items

            oldName = oldName.filter(
              (temp => a =>
                (k => !temp[k] && (temp[k] = true))(a.INVNAME + '|' + a.INVNAME)
              )(Object.create(null))
            );
            console.log("oldName", oldName);
            this.setState({ uniqData: oldName })
            console.log(this.state.uniqData);


            let arr = [];
            oldName.forEach(old => {
              if (this.state.items.find(item => item.INVNAME == old.INVNAME)) {
                arr.push({ name: old.INVNAME, subtotal: 0 })
              }
            })
            this.getInvName(arr[0].name, false, true)

            console.log("arr ", arr);
            this.state.items.map(item => {
              // console.log("index ", arr.findIndex(value => value.name == item.INVNAME));
              arr[arr.findIndex(value => value.name == item.INVNAME)].subtotal += item.AMOUNT;
              // console.log("index value ", arr[arr.findIndex(value => value.name == item.INVNAME)].subtotal += item.AMOUNT)
            })

            console.log("after modification arr ", totalePrice);
            this.setState({ total: totalePrice, filterFreshIv: oldName, arr: arr })
          }
        })
        .catch((err) => {
          this.setState({ wait: "No Data Found", })
        })
    } else {
      alert("Please Select Month and Year")
    }
  }



  render() {
    console.log("currentYear", this.state.currentYear);

    const AllYear = [];
    var d = new Date();
    let fullYear = d.getFullYear() - 1993;
    let currentYear = d.getFullYear();
    // console.log("d.getFullYear() ", fullYear, "currentYear ", currentYear);
    for (var i = 0; i <= fullYear; i++) {
      // console.log("parseInt(currentYear) - i ", parseInt(currentYear) - i)
      let pc = {
        value: `${parseInt(currentYear) - i}` + "-" + `${parseInt(currentYear) - i + 1}`,
        label: `${parseInt(currentYear) - i}` + "-" + `${parseInt(currentYear) - i + 1}`
      }
      AllYear.push(pc);
    }
    return (
      <>
        <Helmet>
          <title>Prodigy Pro - taxsavinguserwise Report</title>
        </Helmet>
        <style>
          {`
  .mt-input{
  margin-top:3.5%;
  }
  .mt-btn{
  margin-top:12%;
  }
  .filter-card-body{
  padding-top: .5rem;
  padding-bottom: .5rem;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  }
  th{
  white-space: nowrap;
  }
  .table
  {
  margin-bottom:0rem;
  }
  `}
        </style>
        {/* Page Wrapper */}
        <div id="wrapper">
          {/* Content Wrapper */}
          <div id="content-wrapper" className="d-flex flex-column">
            {/* Main Content */}
            <div id="content">
              {/* Topbar */}
              <Header />
              {/* End of Topbar */}
              {/* Begin Page Content */}
              <div className="container-fluid report">
                {/* Page Heading */}
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item"><a href="home">Home</a></li>
                    <li className="breadcrumb-item active" aria-current="page">Tax Saving Report </li>
                  </ol>
                </nav>
                <div className="row justify-content-center">
                  {/* Area Chart */}
                  <div className="col-xl-10 col-lg-10 pb-5">
                    <div className="shadow-custom mb-4 m-sm-auto">
                      <div className="card-header py-3">

                        <div className="row justify-content-center ">
                          <div className="col-lg-4 col-sm-12">
                            <div className="row">
                              <div className="col-md-10 col-sm-12">
                                <Select className='bg-c' options={AllYear} onChange={this.onData} placeholder={this.state.currentYear} />
                              </div>
                              <div className="py-2">
                                <i class="far fa-calendar float-left  fs-16"></i>
                              </div>
                            </div>
                          </div>

                        </div>

                      </div>
                      {/* Card Body */}
                      <DarkBackground disappear={!this.state.loded}>

                        <LoadingOverlay
                          active={true}
                          spinner={true}

                        >
                        </LoadingOverlay>
                      </DarkBackground>
                      <div className="card-body">
                        {this.state.items == 0 ? null : <div className="accordion px-lg-3" id="accordionExample">
                          <div className="bg-gray border-bottom border-top py-2 mb-3 ">
                            <div className='row red  font-weight-500'>
                              <div className='col-md-9 col-sm-7 text-left pl-lg-5'>Name</div>
                              <div className='col-md-3 col-sm-5 pl-sm-7em'>Amount</div>
                            </div>
                          </div>

                          <h3 style={{ textAlign: "center" }}>{this.state.wait}</h3>
                          {this.state.arr.map((data, idx) => {
                            return <div className="card mb-3 border-bottom ">
                              <div className="card-head border" id={idx} >
                                <h2 className="mb-0" data-toggle="collapse" data-target={"#collapseOne_" + idx} aria-expanded="false" aria-controls="collapseTwo" >
                                  <div className='row fs-16 py-2 red' onClick={() => this.getInvName(data.name, idx)}>
                                    <div className='col-md-9  col-sm-7 text-left pl-lg-5 '>{data.name.toUpperCase()}</div>
                                    <div className='col-md-3 col-sm-5 '>&#x20B9;{(data.subtotal).toFixed(2)}</div>
                                  </div>
                                </h2>
                              </div>
                              <div id={"collapseOne_" + idx} className="collapse" aria-labelledby={idx} data-parent="#accordionExample">
                                <div className="row">
                                  <div className="col-md-11 pr-5rem text-left">
                                    <div className="table-responsive-lg " >
                                      <table className="table">
                                        <thead>
                                          <tr className='red'>
                                            <th scope="col" className='min-w-35em'>Scheme</th>
                                            <th scope="col" className='min-w-11em'>Trxn. Type</th>
                                            <th scope="col" className='min-w-11em'>Date</th>
                                            <th scope="col">Amount</th>
                                          </tr>
                                        </thead>
                                        <tbody className='text-black'>
                                          {this.state.filterIVName.length > 0 ? this.state.filterIVName.map((data) => {

                                            return <tr>
                                              <td>{data.SCHEME}</td>
                                              <td>{data.TRXN_NATURE}</td>
                                              <td>{data.TRADDATE}</td>
                                              <td>&#x20B9;{data.AMOUNT}</td>
                                            </tr>

                                          }) : null
                                          }
                                        </tbody>
                                      </table>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                          })}

                          <div className=" bg-light-red border-bottom border-top py-2 mb-3 ">
                            <div className='row text-black  font-weight-500'>
                              <div className='col-md-9 col-sm-7'>Total</div>
                              <div className='col-md-3 col-sm-5 pl-sm-7em'><>&#x20B9;</>{Math.round(this.state.total)}</div>
                            </div>
                          </div>


                        </div>}

                        <h3 style={{ textAlign: "center" }}>{this.state.wait}</h3>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* ============single scheme======= */}
            <section className="single-scheme-s">
              <div className="row">
                <div tabindex="-1" className="modal pmd-modal animate__animated animate__zoomIn animate__fast" id="single-scheme" aria-hidden="true">
                  <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content  p-2 py-4" >
                      <div className="">
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                          <span aria-hidden="true">×</span>
                        </button>
                      </div>
                      <div id="scheme">
                        <div className="modal-body py-0">
                          <div className="row">
                            <div className="col-md-12 text-black  py-3 fs-16">Mirae Asset Large Cap Fund - Direct Plan - Growth</div>
                            <table className='table text-center'>
                              <thead>
                                <tr className='red bg-gray'>
                                  <th>Date</th>
                                  <th>Amount</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>20-01-2022</td>
                                  <td>2000</td>
                                </tr>
                                <tr>
                                  <td>20-01-2022</td>
                                  <td>2000</td>
                                </tr>
                                <tr>
                                  <td>20-01-2022</td>
                                  <td>2000</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            {/* End of Main Content */}
            {/* Footer */}
            <Footer />
            {/* End of Footer */}
          </div>
          {/* End of Content Wrapper */}
        </div>
        {/* End of Page Wrapper */}
      </>
    )
  }
}
export default Tax_Saving_Investments