import React, { component } from 'react';
import Header from './header';
import Footer from './footer';
import Sidebar from './sidebar';
import { Helmet } from "react-helmet";
import Axios from 'axios';
import $ from 'jquery';
import profile_icon from "../../assets/images/undraw_profile.svg";
import edit from '../../assets/images/icons/New folder (2)/edit-p.png'

class Profile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      users: []
    };

    this.state = {
      Items: []
    };
  }

  componentDidMount() {
    const schemeList = []; const list = '';
    const userData = JSON.parse(localStorage.getItem("loginUserData"))
    const data = {
      email: userData.email,
    }

    Axios.post("http://localhost:5010/api/get_user_profile", data)
      .then((res) => {
        console.log("dscd", res.data.data.data)
        this.setState({ userProfile: res.data.data.data })
        // console.log(this.state.userProfile)
      })
      .catch((err) => {
        console.log("Test");
      });
    // console.log(this.state.userList)
  }


  onFileChange = event => {

    console.log(event.target.files[0]);
    // this.setState({ selectedFile: event.target.files[0] });
    const formData = new FormData();
    formData.append('file',event.target.files[0]);
    formData.append('email',"krishnaravi1995@gmail.com");
    Axios.post("http://localhost:5010/api/profileImg",formData ,{ headers: {'Content-Type': 'multipart/form-data' }})
    .then((response) => {
        console.log(response.data);
    });
};




  render() {

    return (
      <>
        <Helmet>
          <title>Profile</title>
        </Helmet>
        <style>
          {`
            .card{
                min-height:420px;
            }

            .profile-img{
                text-align: center;
            }
            .profile-img img{
                width: 100%;
            }
            .profile-img .file {
                position: relative;
                overflow: hidden;           
                
                border: none;
                border-radius: 0;
                font-size: 17px;
               
            }
             .file input {
                position: absolute;
                opacity: 0;
                right: 12em;
                top: 6.6em;
                cursor: pointer;
                width: 3em;
                cursor:pointer;
            }
 
            .profile-head .nav-tabs .nav-link{
                font-weight:600;
                border: none;
            }
            .profile-head .nav-tabs .nav-link.active{
                border: none;
                border-bottom:2px solid #0062cc;
            }

           .text-alignment{
             text-align : right;
           }

           @media only screen and (max-width: 600px) {
            .text-alignment{
              text-align : left;
            }
          }
          .profile_pic{
            max-width: 200px;
            max-height: 200px;
            border-radius: 20%;
          }
          .img-profile
          {
            max-width: 8em!important;
          }
          .form-control
          {
            margin-top: -0.3em;
           
          }
          
        `}
        </style>

        {/* Page Wrapper */}
        <div id="wrapper">
          {/* Sidebar */}
          {/* <Sidebar /> */}
          {/* End of Sidebar */}


          {/* Content Wrapper */}
          <div id="content-wrapper" className="d-flex flex-column">
            {/* Main Content */}
            <div id="content">

              {/* Topbar */}
              <Header />
              {/* End of Topbar */}

              {/* Begin Page Content */}
              <div className="container-fluid profile">
                {/* Page Heading */}
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item"><a href="home">Home</a></li>
                    <li className="breadcrumb-item active" aria-current="page">Profile</li>
                  </ol>
                </nav>
 
                <div className="row justify-content-center pb-5">
                  <div className="col-xl-6 col-lg-6 ">
                    <div className="card shadow-custom m-sm-auto">
                      
                      {/* Card Body */}
                      <div className="card-body">
                      
                          <form method="post">
                          {this.state.userProfile ?

                            <div className="">
                              <div className="col-md-12 pb-5">
                                <div className="profile-img ">
                                  {/* <img className="profile_pic" src={this.state.userProfile.profile_pic != "" ? "https://prodigypro.bfccapital.com/"+this.state.userProfile.profile_pic : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS52y5aInsxSm31CvHOFHWujqUx_wWTS9iM6s7BAm21oEN_RiGoog"} alt="" /> */}
                                  <img className="img-profile rounded-circle" src={profile_icon} />
                                 
                                 
                                </div>
                                <div className="file red text-center  ">
                                <img src={edit} alt="" className='img-fluid img-profile-p edit-p' />
                                    <input type="file" name="file" onChange={this.onFileChange} />
                                  </div>
                               
                              </div>
                              <div className="px-lg-5 ">
                          
                                <div className='d-flex flex-direction-row-reverse justify-content-center'>
                                <div className="col-md-4 col-sm-3 min-w-8em fs-sm-13">
                                  <label className='float-left'>Name</label>
                                  <span className='float-right'>:</span>
                                </div>
                                <div className="col-md-8 col-sm-9   font-weight-bold">
                                <input type="text" className='form-control p-0 border-0 font-weight-bold text-black' value={this.state.userProfile.name}/> 
                              
                              
                               </div>
                              </div>
                              <div className='d-flex flex-direction-row-reverse justify-content-center'>
                                <div className="col-md-4 col-sm-3 min-w-11em fs-sm-13">
                                  <label className='float-left'>Mobile Number</label>
                                  <span className='float-right'>:</span>
                                </div>
                                <div className="col-md-7 col-sm-9 ">
                                <input type="text" className='form-control p-0 border-0 font-weight-bold text-black' defaultValue={this.state.userProfile.phone}/> 
                                </div>
                                <div className="col-md-1 ">
                                <i className="fas fa-pen fa-sm fa-fw "/>
                                </div>
                              </div>
                              <div className='d-flex flex-direction-row-reverse justify-content-center'>
                                <div className="col-md-4 col-sm-3 fs-sm-13 min-w-10em">
                                  <label  className='float-left'>PAN</label>
                                  <span className='float-right'>:</span>
                                </div>
                                <div className="col-md-8 col-sm-9  font-weight-bold">
                                <input type="text" className='form-control p-0 border-0 font-weight-bold text-black' value={this.state.userProfile.pan_card}/> 
                                  
                                </div>
                              </div>
                              <div className='d-flex flex-direction-row-reverse justify-content-center'>
                                <div className="col-md-4 col-sm-3 min-w-6em fs-sm-13">
                                  <label  className='float-left'>Email</label>
                                  <span className='float-right'>:</span>
                                </div>
                                <div className="col-md-7 col-sm-9  font-weight-bold">
                               <input type="text" className='form-control p-0 border-0 font-weight-bold text-black' value="srivastavs526@gmail.com"/> 
                                 
                                </div>
                                <div className="col-md-1 ">
                                <i className="fas fa-pen fa-sm fa-fw " />
                                </div>
                              </div>
                              {/* <div className="row text-left">
                                <div className="col-md-6 ">
                                  <label>Marital Status</label>
                                </div>
                                <div className="col-md-6 font-weight-bold">
                                  <p>{this.state.userProfile.material_status == 0 ? "Single" : "Married"}</p>
                                </div>
                              </div> */}
                                <div className='d-flex flex-direction-row-reverse justify-content-center'>
                                <div className="col-md-4 col-sm-3 min-w-6em fs-sm-13">
                                  <label  className='float-left'>IIN</label>
                                  <span className='float-right'>:</span>
                                </div>
                             
                                <div className="col-md-8 col-sm-9 font-weight-bold">
                                <input type="text" className='form-control p-0 border-0 font-weight-bold text-black' value={this.state.userProfile.iin}/> 

                                </div>
                              </div>
                              <div className='d-flex flex-direction-row-reverse justify-content-center'>
                                <div className="col-md-4 col-sm-3 min-w-10em fs-sm-13">
                                  <label className='float-left'>Date of Birth</label>
                                  <span className='float-right'>:</span>
                                </div>
                                
                                <div className="col-md-7 col-sm-9 font-weight-bold">
                                <input type="text" className='form-control p-0 border-0 font-weight-bold text-black' defaultValue={this.state.userProfile.date_of_birth}/> 
                                 
                                </div>
                                <div className="col-md-1">
                                <i className="fas fa-pen fa-sm fa-fw " />
                                </div>
                              </div>
                              <div className='d-flex flex-direction-row-reverse justify-content-center'>
                                <div className="col-md-4 col-sm-3 min-w-10em fs-sm-13">
                                  <label className='float-left'>Gender</label>
                                  <span className='float-right'>:</span>
                                </div>
                          
                                <div className="col-md-8 col-sm-9 font-weight-bold">
                                <input type="text" className='form-control p-0 border-0 font-weight-bold text-black' value={this.state.userProfile.gender == 1 ? "Male" : "Female"}/> 
                                 
                                </div>
                              </div>
                            
                              </div>
                            </div>

                            : null}
                            

                          </form>

                      
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* End of Main Content */}
            {/* Footer */}
            <Footer />
            {/* End of Footer */}
          </div>
          {/* End of Content Wrapper */}
        </div>
        {/* End of Page Wrapper */}
      </>
    )
  }

}
export default Profile
