import React from 'react';
import Axios from 'axios';
import $ from 'jquery';
import profile_icon from "../../assets/images/undraw_profile.svg";
class Edit_Profile extends React.Component {
constructor(props) {
super(props);
this.state = {
users: []
};
this.state = {
Items: []
};
}
componentDidMount() {
const schemeList = []; const list = '';
const userData = JSON.parse(localStorage.getItem("loginUserData"))
const data = {
email: userData.email,
}
Axios.post("http://localhost:5010/api/get_user_profile", data)
.then((res) => {
console.log("dscd", res.data.data.data)
this.setState({ userProfile: res.data.data.data })
// console.log(this.state.userProfile)
})
.catch((err) => {
console.log("Test");
});
// console.log(this.state.userList)
}
onFileChange = event => {
console.log(event.target.files[0]);
// this.setState({ selectedFile: event.target.files[0] });
const formData = new FormData();
formData.append('file',event.target.files[0]);
formData.append('email',"krishnaravi1995@gmail.com");
Axios.post("http://localhost:5010/api/profileImg",formData ,{ headers: {'Content-Type': 'multipart/form-data' }})
.then((response) => {
console.log(response.data);
});
};
render() {
return (
<>
<style>
  {
  `
  .request-section .place::placeholder {
  color:#939393 !important;
  text-align: left;
  }
  .form-group {
  margin-bottom: 0.5rem;
  }
  .profile-img img{
  width: 100%;
  }
  `
  }
</style>
<section className="request-section">
  <div className="row">
    <div tabindex="-1" className="modal pmd-modal animate__animated animate__zoomIn animate__fast" id="edit-profile" aria-hidden="true">
      <div className="modal-dialog">
        <div className="modal-content b-r bg-gray" >
          <div className=" border-0 p-2">
            <button aria-hidden="true" data-dismiss="modal" className="close" type="button">×</button>
          </div>
          <div className="modal-body">
            <form method="post">
              {this.state.userProfile ?
              <div className="col-md-12 ">
                <div className="col-md-12 pb-4">
                  <div className="profile-img ">
                    <img className="img-profile-p rounded-circle" src={profile_icon} />
                    <div className="file red text-center">
                      Change Profile Picture
                      <input type="file" name="file"  />
                    </div>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="row text-left ">
                    <div className="col-md-4 ">
                      <label>Name</label>
                    </div>
                    <div className="col-md-8  font-weight-bold">
                      <input type="text" className='form-control' defaultValue={this.state.userProfile.name}/>
                    </div>
                  </div>
                  <div className="row text-left pt-2">
                    <div className="col-md-4">
                      <label>Mobile Number</label>
                    </div>
                    <div className="col-md-8 font-weight-bold">
                      <input type="text" className='form-control' defaultValue={this.state.userProfile.phone}/>
                    </div>
                  </div>
                  <div className="row text-left pt-2">
                    <div className="col-md-4 ">
                      <label>PAN</label>
                    </div>
                    <div className="col-md-8  font-weight-bold">
                      <input type="text" className='form-control' defaultValue={this.state.userProfile.pan_card}/>
                    </div>
                  </div>
                  <div className="row text-left pt-2">
                    <div className="col-md-4 ">
                      <label>Email</label>
                    </div>
                    <div className="col-md-8 font-weight-bold">
                      <input type="email" className='form-control' value="srivastavs521@gmail.com" />
                    </div>
                  </div>
                  <div className="row text-left pt-2">
                    <div className="col-md-4 ">
                      <label>IIN</label>
                    </div>
                    <div className="col-md-8  font-weight-bold">
                      <input type="text" className='form-control' defaultValue={this.state.userProfile.iin} />
                    </div>
                  </div>
                  <div className="row text-left pt-2">
                    <div className="col-md-4 ">
                      <label>Gender</label>
                    </div>
                    <div className="col-md-8  font-weight-bold">
                      <input type="text" className='form-control'defaultValue={this.state.userProfile.gender == 1 ? "Male" : "Female"} />
                    </div>
                  </div>
                  <div className="row text-left pt-2">
                    <div className="col-md-4 ">
                      <label>Date of Birth</label>
                    </div>
                    <div className="col-md-8  font-weight-bold">
                      <input type="text" className='form-control' defaultValue={this.state.userProfile.date_of_birth}/>
                    </div>
                  </div>
                  <div className="row text-center pt-5">
                    <div className="col-md-12">
                      <a href="#" className='btn-custom' >Update</a>
                    </div>
                  </div>
                </div>
              </div>
              : null}
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
</>
)
}
}
export default Edit_Profile