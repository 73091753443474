import React from 'react';
import Select from 'react-select';
class Contact_us extends React.Component {
render() {
const query = [
{ value: "select", label: "select" },
];
return (
<>
<style>
  {
  `
  .request-section .place::placeholder {
  color:#939393 !important;
  text-align: left;
  }
  .form-group {
  margin-bottom: 0.5rem;
  }
  `
  }
</style>
<section className="request-section">
  <div className="row">
    <div tabindex="-1" className="modal pmd-modal animate__animated animate__zoomIn animate__fast" id="raise-a-query" aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered" >
        <div className="modal-content b-r p-3 " >
          <div className="modal-header border-0">
            <div className="quick-access-title">
              <h5 className='red'>Please send us your query or feedback</h5>
            </div>
            <button aria-hidden="true" data-dismiss="modal" className="close" type="button">×</button>
          </div>
          <div className="modal-body">
            <form>
              <div className='row'>
                <div className='col-md-12'>
                  <div className="form-group pmd-textfield pmd-textfield-floating-label">
                    <label className="control-label lb " for="email">Email id<span className="text-danger">*</span></label>
                    <input type="text" className="form-control border-pop place" name='email' placeholder='Enter Your Email' />
                  </div>
                </div>
                <div className='col-md-12'>
                  <div className="form-group pmd-textfield pmd-textfield-floating-label">
                    <label className="control-label lb" for="number">Mobile Number<span className="text-danger">*</span></label>
                    <input type="text" className="form-control border-pop place " name='number' placeholder='Enter Your Number' />
                  </div>
                </div>
                <div className='col-md-12'>
                  <div className="form-group pmd-textfield pmd-textfield-floating-label">
                    <label className="control-label lb" for="amount">Query<span className="text-danger">*</span></label>
                    <Select className=' br-6 p-q border-pop place' options={query} placeholder="Choose Query"/>
                  </div>
                </div>
                <div className='col-md-12'>
                  <div className="form-group pmd-textfield pmd-textfield-floating-label">
                    <label className="control-label lb" for="description">Query Message<span className="text-danger">*</span></label>
                    <textarea className="ta form-control border-pop place" name='description' placeholder='Please Mention Your Query ! ' rows={3}></textarea>
                  </div>
                </div>
                <div className="col-md-12 text-right pt-2">
                  <a href="javascript:void(0);" className="btn-custom" data-dismiss="modal" data-target="#success-page" data-toggle="modal" type="button"  >
                  submit
                  </a>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
{/* Message model */}
<div className="modal animate__animated animate__zoomIn animate__fast" id="success-page" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true" data-keyboard="false" data-backdrop="static">
  <div className="modal-dialog modal-dialog-centered " role="document">
    <div className="modal-content br-15 ">
      <div className="modal-body text-center">
        {/* <img className="img-fluid" src={success} alt="" /> */}
        <h3 className="success-c font-weight-500 py-3"> Thankyou !!!</h3>
        <p className="text-black">
          We have received your query, we will get back to you shortly.
        </p>
      </div>
      <div className="bg-red text-center py-2 order-b">
        <a href='#' className="text-white font-weight-bold" data-dismiss="modal" >
          <h4>OK</h4>
        </a>
      </div>
    </div>
  </div>
</div>
</>
)
}
}
export default Contact_us