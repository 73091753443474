import React, { component } from 'react';
import Header from './header';
import Footer from './footer';
import $ from 'jquery';
import 'react-toastify/dist/ReactToastify.css';
import sunderam from "../../assets/images/icons/New folder (2)/Sundaram_Mutual_Fund.png";
import sbi from "../../assets/images/icons/New folder (2)/sbi mutual fund.png";
import idfc from "../../assets/images/icons/New folder (2)/IDFC.png";
import success from "../../assets/images/icons/New folder (2)/successfully.png";
import Select from 'react-select';
class Goal_Wise_Scheme extends React.Component {
hides1() {
$("#form-sip").css({ "display": "none" });
$("#formhides").css({ "display": "block" });
}
hides2() {
$("#formhides").css({ "display": "none" });
$("#confirms").css({ "display": "block" });
}
hides3() {
$("#confirms").css({ "display": "none" });
$("#success").css({ "display": "block" });
}
edit() {
$("#confirms").css({ "display": "none" });
$("#formhides").css({ "display": "block" });
}
render() {
const folio = [
{ value: '1544545454', label: '1544545454' },
{ value: '55588888', label: '55588888' },
];
const profile = [
{ value: 'select', label: 'select' },
];
const date = [
{ value: '1', label: '1' },
{ value: '2', label: '2' },
{ value: '3', label: '3' },
{ value: '4', label: '4' },
];
const selectperiod = [
{ value: 'select', label: 'select' },
];
return (
<>
{/* Page Wrapper */}
<div id="wrapper">
  {/* Content Wrapper */}
  <div id="content-wrapper" className="d-flex flex-column">
    {/* Main Content */}
    <div id="content">
      {/* Topbar */}
      <Header />
      {/* End of Topbar */}
      {/* Begin Page Content */}
      <div className="container-fluid goal-scheme">
        {/* Page Heading */}
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item"><a href="home">Home</a></li>
            <li className="breadcrumb-item active" aria-current="page">Goal Wise Recommendation</li>
          </ol>
        </nav>
        <div className="row justify-content-center">
          <div className="col-lg-8 col-md-10 px-2 pb-3  shadow-custom">
            <div className="card-body">
              <h6 className='red font-weight-500'>Recommended Schemes</h6>
              <div className="table-responsive">
                <table class="table text-left">
                  <tbody >
                    <tr>
                      <td><img src={idfc} alt="" className='img-fluid min-w-3em'/></td>
                      <td className='min-w-35em'><a href='!#' className='text-black'>IDFC Tax Advantage (ELSS)
                        Fund-Growth-Regular Plan</a>
                      </td>
                    </tr>
                    <tr>
                      <td><img src={sunderam} alt="" className='img-fluid' /></td>
                      <td className='pt-4'><a href='!#' className='text-black'>Sundaram Tax Savings Fund (Formerly Principal Tax Savings Fund)-
                        Regular Growth </a>
                      </td>
                    </tr>
                    <tr>
                      <td><img src={sunderam} alt="" className='img-fluid' /></td>
                      <td className='pt-4'><a href='!#' className='text-black'>Sundaram Tax Savings Fund (Formerly Principal Tax Savings Fund)-
                        Regular Growth </a>
                      </td>
                    </tr>
                    <tr>
                      <td><img src={sbi} alt="" className='img-fluid' /></td>
                      <td className='pt-4'><a href='!#' className='text-black'>SBI Long Term Equity Fund-Regular
                        Plan-Growth  </a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className="col-12 mt-4 text-right">
              <a className="btn-custom text-color" data-toggle="modal" data-target="#invest-sip">Continue</a>
            </div>
          </div>
        </div>
        {/* Message model */}
        <div className="modal fade" id="success-page" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true" data-keyboard="false" data-backdrop="static">
          <div className="modal-dialog modal-dialog-centered " role="document">
            <div className="modal-content br-15 ">
              <div className="modal-body text-center">
                <img className="img-fluid" src={success} alt="" />
                <h3 className="success-c font-weight-500 py-3"> Order Placed</h3>
                <p className="text-black">
                  Units will be alloted subject to realization of funds in AMC's account.
                </p>
              </div>
              <div className="bg-red text-center py-2 order-b">
                <a href='#' className="text-white font-weight-bold" data-dismiss="modal" >
                  <h4>OK</h4>
                </a>
              </div>
            </div>
          </div>
        </div>
        {/*Purchase Modal */}
        <section className="invest-section">
          <div className="row">
            <div tabindex="-1" className="modal pmd-modal animate__animated animate__zoomIn animate__fast" id="invest-sip" aria-hidden="true">
              <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content b-r p-1 bg-gray" >
                  {/*================ form===================  */}
                  <div id="form-sip">
                    <div className="modal-header">
                      <h5 className="modal-title text-black" id="sipTitle">SIP</h5>
                      <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                      <span aria-hidden="true">×</span>
                      </button>
                    </div>
                    <div className="modal-body ">
                      <form>
                        <div className="col mb-3 ">
                          <label htmlFor="Profile" className='text-label' >Select Profile <span className='text-danger'>*</span></label>
                          <Select className='bg-c' options={profile} />
                        </div>
                        <div className="col mb-3 ">
                          <label htmlFor="Profile" className='text-label' >Select Mandate <span className='text-danger'>*</span></label>
                          <Select className='bg-c' options={profile} />
                        </div>
                        <a href="/prodigypro/dashboard/bank-and-mandate" className="red fs-13">Create Mandate</a>
                      </form>
                    </div>
                    <div className="modal-footer border-0">
                      <a type="button" className="btn btn-danger shadow-sm" href="javascript:void(0);" onClick={this.hides1}>Continue</a>
                    </div>
                  </div>
                  {/*=============sip cart============ */}
                  <div id='formhides'>
                    <div className="modal-header">
                      <h5 className="modal-title text-black" id="sipTitle">SIP Cart</h5>
                      <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                      <span aria-hidden="true">×</span>
                      </button>
                    </div>
                    <div className="modal-body ">
                      <div className='cartitemwith bg-light-red'>
                        <div className='row p-4'>
                          <div className='col-md-12  red '>SBI Long Term Equity Fund-Regular
                            Plan-Growth
                          </div>
                        </div>
                        <div className="col bg-white py-2 px-4">
                          <label htmlFor="Profile" className='fs-14' >Select Folio <span className='text-danger'>*</span></label>
                          <Select className='border-pop' options={folio} />
                        </div>
                        <div className="col  bg-white pb-1 px-4">
                          <label htmlFor="amount" className='fs-14' >Enter Amount <span className='text-danger'>*</span></label>
                          <input type="text" className="form-control amount-b" name="amount" placeholder='Enter Amount' />
                        </div>
                        <div className="col bg-white py-2 px-4">
                          <label htmlFor="date" className='fs-14' >Select Date <span className='text-danger'>*</span></label>
                          <Select className='border-pop' options={date} />
                        </div>
                        <div className="col bg-white py-2 px-4">
                          <label htmlFor="period" className='fs-14'>Select Period <span className='text-danger'>*</span></label>
                          <Select className='border-pop' options={selectperiod} />
                        </div>
                        <div className='col mb-2 lastin'>
                          <input className="input-text p-2" id="perpetual" type="checkbox" name="perpetual" value="Y" defaultChecked />
                          <input type="hidden" id="perpetual_val" name="perpetual_val" />
                          <label htmlFor="perpetual" className="ml-2">
                            Perpetual
                            <span className="text-danger">*</span>
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="modal-footer border-0">
                      <a type="button" className="btn btn-danger shadow-sm" href="javascript:void(0);" onClick={this.hides2}>Continue</a>
                    </div>
                  </div>
                  {/* ==========confirm========== */}
                  <div id="confirms">
                    <div className="modal-header">
                      <h5 className="modal-title text-black" id="sipTitle">SIP Confirmation Purchase</h5>
                      <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                      <span aria-hidden="true">×</span>
                      </button>
                    </div>
                    <div className="modal-body ">
                      <div className="col mb-3 border-bottom">
                        <h6 className='red'>Shivam Shrivastav</h6>
                        <p>Mode of Holding :  Single <a href="#" className='p-4 ml-5' onClick={this.edit}>Edit</a></p>
                      </div>
                      <p className='red'>Axis Long Term Equity Fund-Regular-Growth</p>
                      <table className='mx-auto'>
                        <tr className='text-center'>
                          <td className='pr-4'>Folio </td>
                          :
                          <td className='pl-4'>124564</td>
                        </tr>
                        <tr className='text-center'>
                          <td className='pr-4'>Amount  </td>
                          :
                          <td className='pl-4'>50,000</td>
                        </tr>
                        <tr className='text-center'>
                          <td className='pr-4'>SIP From  </td>
                          :
                          <td className='pl-4'>11-sep-2022</td>
                        </tr>
                        <tr className='text-center'>
                          <td className='pr-4'>SIP To </td>
                          :
                          <td className='pl-4'>31-dec-2099</td>
                        </tr>
                        <tr className='text-center'>
                          <td className='pr-4'>SIP Date </td>
                          :
                          <td className='pl-4'>11</td>
                        </tr>
                      </table>
                      <p className='text-center pt-5'><b>Total  :  55,55882</b></p>
                    </div>
                    <div className="modal-footer border-0">
                      <a type="button" className="btn btn-danger shadow-sm" href="javascript:void(0);" onClick={this.hides3} >Order</a>
                    </div>
                  </div>
                  {/* ================successful transaction===============  */}
                  <div id="success">
                    <div className="modal-body py-0">
                      <div className="text-center">
                        <img className="img-fluid" src={success} alt="" />
                        <h6 className="success-c font-weight-500 py-3 fs-19">Successful Transaction with
                          Following Details
                        </h6>
                      </div>
                      <div className="row px-2">
                        <div className="col-md-4 col-sm-4 text-left text-black">Unique Number </div>
                        <div className="col-md-1 col-sm-1">:</div>
                        <div className="col-md-7 col-sm-7 text-left">12345678</div>
                      </div>
                      <div className="row px-2">
                        <div className="col-md-4  col-sm-4 text-left text-black">Trxn Number </div>
                        <div className="col-md-1 col-sm-1">:</div>
                        <div className="col-md-7 col-sm-7 text-left">12346195</div>
                      </div>
                      <div className="row px-2">
                        <div className="col-md-4  col-sm-4 text-left text-black">Fund </div>
                        <div className="col-md-1 col-sm-1">:</div>
                        <div className="col-md-7 col-sm-7 text-left">Axis Mutual Fund</div>
                      </div>
                      <div className="row px-2">
                        <div className="col-md-4  col-sm-4 text-left text-black">Folio Number </div>
                        <div className="col-md-1 col-sm-1">:</div>
                        <div className="col-md-7  col-sm-7 text-left">12345678</div>
                      </div>
                      <div className="row px-2">
                        <div className="col-md-4  col-sm-4 text-left text-black">Amount   </div>
                        <div className="col-md-1 col-sm-1">:</div>
                        <div className="col-md-7 col-sm-7 text-left">1,000</div>
                      </div>
                      <div className="row px-2">
                        <div className="col-md-4  col-sm-4 text-left text-black">Status   </div>
                        <div className="col-md-1 col-sm-1">:</div>
                        <div className="col-md-7  col-sm-7 text-left">Ok</div>
                      </div>
                      <div className="row px-2">
                        <div className="col-md-4  col-sm-4 text-left text-black">Scheme Name </div>
                        <div className="col-md-1 col-sm-1">:</div>
                        <div className="col-md-7 col-sm-7 text-left">Axis Arbitrage Fund - Regular Growth</div>
                      </div>
                      <hr />
                      <div className="text-center">
                        <p className="text-black fs-13">
                          <span className="red text-left font-weight-500 fs-16">Note:</span>  Authorization link has been sent on your registered mail id and mobile number.The Link shall remain active for the next 48 hours.Kindly authorize.
                        </p>
                      </div>
                    </div>
                  </div>
                  {/*  */}
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
    {/* Footer */}
    <Footer />
    {/* End of Footer */}
  </div>
  {/* End of Content Wrapper */}
</div>
{/* End of Page Wrapper */}
</>
)
}
}
export default Goal_Wise_Scheme