import React from "react";
import $ from 'jquery';
import Select from 'react-select';


class Invest_Lumpsum extends React.Component
{
hide1() {
    $("#moi-l").css({ "display": "none" });
    $("#formhide-l").css({ "display": "block" });
  }
  hide2() {
    $("#formhide-l").css({ "display": "none" });
    $("#confirm-l").css({ "display": "block" });
  }
  hide3() {
    $("#confirm-l").css({ "display": "none" });
    $("#purchase-l").css({ "display": "block" });
  }
  edit() {
    $("#confirm-l").css({ "display": "none" });
    $("#formhide-l").css({ "display": "block" });
  }

    render()
    {
        const modeofinvest = [
            { value: "Lumpsum", label: "Lumpsum" },
            { value: "SIP", label: "SIP" },
          ];
        const folio = [
            { value: '1544545454', label: '1544545454' },
            { value: '55588888', label: '55588888' },
          ];
        
          const profile = [
            { value: 'select', label: 'select' },
          ];
        
        return(
            <>
             <section className="invest-section">
          <div className="row">
             {/*Purchase Modal lumpsum */}
             <div className="modal animate__animated animate__zoomIn animate__fast" id="invest-lumpsum" tabIndex={-1} role="dialog" aria-labelledby="sipTitle" aria-hidden="true">
              <div className="modal-dialog modal-dialog-centered" role="document">
                <div className="modal-content bg-light-red">
                  {/* =================mode of investment============ */}
                  <div id="moi-l">
                    <div className="modal-header border-0">
                      <button aria-hidden="true" data-dismiss="modal" className="close" type="button">×</button>
                    </div>
                    <div className="modal-body">
                      <form>
                        <div className='row'>
                          <div className='col-md-12'>
                            <div className="form-group pmd-textfield pmd-textfield-floating-label">
                              <label className="control-label lb " for="email">Mode Of Investment<span className="text-danger">*</span></label>
                              <Select className=' bg-c' options={modeofinvest} />
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="modal-footer border-0 text-right">
                              <a type="button" className="btn btn-danger shadow-sm" href="javascript:void(0);" onClick={this.hide1}>Continue</a>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                  {/* form  */}
                  <div id='formhide-l'>
                    <div className="modal-header">
                      <h5 className="modal-title text-black" id="sipTitle">Investment Details</h5>
                      <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">×</span>
                      </button>
                    </div>
                    <div className="modal-body ">
                      <form>
                        <div className="col mb-3 ">
                          <label htmlFor="Profile" className='text-label' >Select Profile <span className='text-danger'>*</span></label>
                          <Select className='bg-c' options={profile} />
                        </div>
                      </form>
                      <div className='cartitemwith'>
                        <div className='row p-4'>
                          <div className='col-md-12  red '>SBI Long Term Equity Fund-Regular
                            Plan-Growth </div></div>
                        <div className="col bg-white py-3 px-4">
                          <label htmlFor="Profile" className='fs-14' >Select Folio <span className='text-danger'>*</span></label>
                          <Select className='border-pop' options={folio} />
                        </div>
                        <div className="col mb-3 bg-white pb-4 px-4 lastin">
                          <label htmlFor="amount" className='fs-14' >Enter Amount <span className='text-danger'>*</span></label>
                          <input type="text" className="form-control border-pop " name="amount" placeholder='Enter Amount' />
                        </div>
                      </div>
                    </div>
                    <div className="modal-footer border-0">
                      <a type="button" className="btn btn-danger shadow-sm" href="javascript:void(0);" onClick={this.hide2}>Continue</a>
                    </div>
                  </div>
                  <div id="confirm-l">
                    <div className="modal-header">
                      <h5 className="modal-title text-black" id="sipTitle">Confirm Purchase</h5>
                      <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">×</span>
                      </button>
                    </div>
                    <div className="modal-body ">
                      <div className="col mb-3 border-bottom">
                        <h6 className='red'>Shivam Shrivastav</h6>
                        <p>Mode of Holding :  Single <a href="#" className='p-4 ml-5' onClick={this.edit}>Edit</a></p>
                      </div>
                      <p className='red'>Axis Long Term Equity Fund-Regular-Growth</p>
                      <table className='mx-auto'>
                        <tr className='text-center'>
                          <td className='pr-4'>Folio  </td>:
                          <td className='pl-4'>124564</td>
                        </tr>
                        <tr className='text-center'>
                          <td className='pr-4'>Amount  </td>:
                          <td className='pl-4'>50,000</td>
                        </tr>
                      </table>
                      <p className='text-center pt-5'><b>Total  :  55,55882</b></p>
                    </div>
                    <div className="modal-footer border-0">
                      <a type="button" className="btn btn-danger shadow-sm" href="javascript:void(0);" onClick={this.hide3}>Continue</a>
                    </div>
                  </div>
                  <div id='purchase-l'>
                    <div className="modal-header">
                      <h5 className="modal-title text-black" id="sipTitle"> Purchase</h5>
                      <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">×</span>
                      </button>
                    </div>
                    <div className="modal-body ">
                      <form>
                        <p className='red'>Investment Total : 5000.00</p>
                        <div className="col mb-3 ">
                          <label htmlFor="Profile" className='text-label' >Select Payment Mode <span className='text-danger'>*</span></label>
                          <Select className='bg-c' options={profile} />

                        </div>
                        <div className="col mb-3 ">
                          <label htmlFor="Profile" className='text-label' >Select Bank <span className='text-danger'>*</span></label>
                          <Select className='bg-c' options={profile} />

                        </div>
                        <div className="pt-4 mt-3">
                          <input className=" input-text" id="emailLink" type="radio" name="payType" value="N" />
                          <label htmlFor="emailLink" className="">Link On Email</label>
                          <input className="input-text ml-3" id="immediatePay" type="radio" name="payType" value="Y" />
                          <label htmlFor="immediatePay" className="">Immediate Payment</label>
                        </div>

                      </form>
                    </div>
                    <div className="modal-footer border-0">
                      <a type="button" className="btn btn-danger shadow-sm" href="javascript:void(0);" >Order</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
            
            
            </>
        )
    }
}
export default Invest_Lumpsum