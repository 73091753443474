import React from 'react';
import Header from './header';
import Footer from './footer';
import { Helmet } from "react-helmet";
import { Transact_Icon_Group } from './short-components';
import Axios from 'axios';
import StyleComponent from './styleComponent';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Select from 'react-select';

import LoadingOverlay from "react-loading-overlay";
import styled, { css } from "styled-components";

const DarkBackground = styled.div`
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 999; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */

  ${(props) =>
        props.disappear &&
        css`
      display: block; /* show */
    `}
`;
var api_url_wms = "https://wms.bfccapital.com";

class Portfolio extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            users: [],
            Items: [],
            selectedOneValue: '',
            profilioPan: '',
            profilioName: '',
            sData: [],
            familyData: [],
            filteredData: [],
            nameWiseAssetClass: [],
            equityPurchase: 0,
            equityCurrentValue: 0,
            equityGainLoss: 0,
            debtPurchase: 0,
            debtCurrentValue: 0,
            debtGainLoss: 0,
            goldPurchase: 0,
            goldCurrentValue: 0,
            goldGainLoss: 0,
            userid: '',
            name: '',
            snapShotData: {},
            nameWise: [],
            TransactionTypeState: '',
            particularSubTotal: {},
            wmsloaded: false,
            familyDataLoaded: false,
            selectedFromHome: ''

        };

        const parsed = new URLSearchParams(this.props.location.search);

        this.setState({ profilioPan: parsed.get('pan'), profilioName: parsed.get('name') })



    }
    selectedOne = (e) => {
        this.setState({ name: "", userid: "", pan: "", nameWise: [], particularSubTotal: {}, familyData: [] })

        this.setState({ nameWiseAssetClass: [] })

        if (e === 'all') {
            var logingData = JSON.parse(window.localStorage.getItem("loginUserData"))
            // console.log("logingData", logingData);
            this.setState({ name: logingData.name, userid: logingData.userId, pan: logingData.pan_card, nameWise: [], particularSubTotal: {} })

            var allFamilyMembers = JSON.parse(window.localStorage.getItem("allFamilyMembers"))
            this.setState({ familyData: allFamilyMembers })

            var snapShot = JSON.parse(window.localStorage.getItem("snapShotData"))
            this.setState({ snapShotData: snapShot, grandTotalOblect:null});
            // console.log();

            for (let i = 0; i < allFamilyMembers.length; i++) {
                const reqdata = { pan: allFamilyMembers[i].Pan, userid: allFamilyMembers[i].userid, gpan: '' }

                Axios.post(api_url_wms + "/api/detailedportfolio", reqdata)
                    .then((res) => {
                        this.setState({ sData: [...this.state.sData, ...res.data.data] })
                        let equityData = res?.data?.data?.filter(d => { return d.equity > 0 })
                        let debtData = res?.data?.data?.filter(d => { return d.debt > 0 })
                        let goldData = res?.data?.data?.filter(d => { return d.gold > 0 })

                        // **************for Equity
                        let equityDataSubTotal = 0;
                        let equityDataSubTotalpurchase = 0;
                        let equityDataSubTotalUnit = 0;
                        let equityDataSubTotalMarketValue = 0;
                        let equityDataSubTotalGainloss = 0;
                        equityData.map(d => {
                            equityDataSubTotal += parseFloat(d.equity)
                            equityDataSubTotalpurchase += parseFloat(d.purchase)
                            equityDataSubTotalUnit += parseFloat(d.unit)
                            equityDataSubTotalMarketValue += parseFloat(d.currentvalue)
                            equityDataSubTotalGainloss += parseFloat(d.gain)
                        });
                        const equityObject = { equityDataSubTotal: equityDataSubTotal, equityDataSubTotalpurchase: equityDataSubTotalpurchase, equityDataSubTotalUnit: equityDataSubTotalUnit, equityDataSubTotalMarketValue: equityDataSubTotalMarketValue, equityDataSubTotalGainloss: equityDataSubTotalGainloss }
                        // **************for Debt
                        let debtDataSubTotal = 0;
                        let debtDataSubTotalpurchase = 0;
                        let debtDataSubTotalUnit = 0;
                        let debtDataSubTotalMarketValue = 0;
                        let debtDataSubTotalGainloss = 0;
                        debtData.map(d => {
                            debtDataSubTotal += parseFloat(d.debt)
                            debtDataSubTotalpurchase += parseFloat(d.purchase)
                            debtDataSubTotalUnit += parseFloat(d.unit)
                            debtDataSubTotalMarketValue += parseFloat(d.currentvalue)
                            debtDataSubTotalGainloss += parseFloat(d.gain)
                        });
                        const debtObject = { debtDataSubTotal: debtDataSubTotal, debtDataSubTotalpurchase: debtDataSubTotalpurchase, debtDataSubTotalUnit: debtDataSubTotalUnit, debtDataSubTotalMarketValue: debtDataSubTotalMarketValue, debtDataSubTotalGainloss: debtDataSubTotalGainloss }
                        // **************for Gold
                        let goldDataSubTotal = 0;
                        let goldDataSubTotalpurchase = 0;
                        let goldDataSubTotalUnit = 0;
                        let goldDataSubTotalMarketValue = 0;
                        let goldDataSubTotalGainloss = 0;
                        goldData.map(d => {
                            goldDataSubTotal += parseFloat(d.gold)
                            goldDataSubTotalpurchase += parseFloat(d.purchase)
                            goldDataSubTotalUnit += parseFloat(d.unit)
                            goldDataSubTotalMarketValue += parseFloat(d.currentvalue)
                            goldDataSubTotalGainloss += parseFloat(d.gain)
                        });
                        const goldObject = { goldDataSubTotal: goldDataSubTotal, goldDataSubTotalpurchase: goldDataSubTotalpurchase, goldDataSubTotalUnit: goldDataSubTotalUnit, goldDataSubTotalMarketValue: goldDataSubTotalMarketValue, goldDataSubTotalGainloss: goldDataSubTotalGainloss }

                        this.setState({ nameWiseAssetClass: [...this.state.nameWiseAssetClass, { name: allFamilyMembers[i].Name, debt: debtData, gold: goldData, equity: equityData, equityObject, debtObject, goldObject }] })
                        console.log("debtData", debtData);

                    })
            }

        } else {
            // const pan = { pan: e.pan, userid: e.userid, gpan: '' }
            // Axios.post(api_url_wms + "/api/detailedportfolio", pan)
            //     .then((res) => {
            //         this.setState({ snapShotData: {}, nameWise: res.data.data, nameWiseAssetClass: [], particularSubTotal: res.data });
            //     })

            var logingData = JSON.parse(window.localStorage.getItem("loginUserData"))
            // console.log("logingData", logingData);
            this.setState({ name: logingData.name, userid: logingData.userId, pan: logingData.pan_card, nameWise: [], particularSubTotal: {} })

            var allFamilyMembers = JSON.parse(window.localStorage.getItem("allFamilyMembers"))
            this.setState({ familyData: allFamilyMembers })

            const reqdata = { pan: e.pan, userid: e.userid, gpan: '' }

            var snapShot = JSON.parse(window.localStorage.getItem("snapShotData"))
            this.setState({ snapShotData: snapShot })
            // console.log();

            let grandDataSubTotal = 0;
            let grandDataSubTotalpurchase = 0;
            let grandDataSubTotalUnit = 0;
            let grandDataSubTotalMarketValue = 0;
            let grandDataSubTotalGainloss = 0;

            Axios.post(api_url_wms + "/api/detailedportfolio", reqdata)
                .then((res) => {
                    this.setState({ sData: [...this.state.sData, ...res.data.data] })
                    let equityData = res?.data?.data?.filter(d => { return d.equity > 0 })
                    let debtData = res?.data?.data?.filter(d => { return d.debt > 0 })
                    let goldData = res?.data?.data?.filter(d => { return d.gold > 0 })

                    // **************for Equity
                    let equityDataSubTotal = 0;
                    let equityDataSubTotalpurchase = 0;
                    let equityDataSubTotalUnit = 0;
                    let equityDataSubTotalMarketValue = 0;
                    let equityDataSubTotalGainloss = 0;
                    equityData.map(d => {
                        equityDataSubTotal += parseFloat(d.equity)
                        equityDataSubTotalpurchase += parseFloat(d.purchase)
                        equityDataSubTotalUnit += parseFloat(d.unit)
                        equityDataSubTotalMarketValue += parseFloat(d.currentvalue)
                        equityDataSubTotalGainloss += parseFloat(d.gain)

                        //Grand Total
                        grandDataSubTotal += parseFloat(d.equity)
                        grandDataSubTotalpurchase += parseFloat(d.purchase)
                        grandDataSubTotalUnit += parseFloat(d.unit)
                        grandDataSubTotalMarketValue += parseFloat(d.currentvalue)
                        grandDataSubTotalGainloss += parseFloat(d.gain)
                    });
                    const equityObject = { equityDataSubTotal: equityDataSubTotal, equityDataSubTotalpurchase: equityDataSubTotalpurchase, equityDataSubTotalUnit: equityDataSubTotalUnit, equityDataSubTotalMarketValue: equityDataSubTotalMarketValue, equityDataSubTotalGainloss: equityDataSubTotalGainloss }
                    // **************for Debt
                    let debtDataSubTotal = 0;
                    let debtDataSubTotalpurchase = 0;
                    let debtDataSubTotalUnit = 0;
                    let debtDataSubTotalMarketValue = 0;
                    let debtDataSubTotalGainloss = 0;
                    debtData.map(d => {
                        debtDataSubTotal += parseFloat(d.debt)
                        debtDataSubTotalpurchase += parseFloat(d.purchase)
                        debtDataSubTotalUnit += parseFloat(d.unit)
                        debtDataSubTotalMarketValue += parseFloat(d.currentvalue)
                        debtDataSubTotalGainloss += parseFloat(d.gain)

                        //Grand Total
                        grandDataSubTotal += parseFloat(d.debt)
                        grandDataSubTotalpurchase += parseFloat(d.purchase)
                        grandDataSubTotalUnit += parseFloat(d.unit)
                        grandDataSubTotalMarketValue += parseFloat(d.currentvalue)
                        grandDataSubTotalGainloss += parseFloat(d.gain)
                    });
                    const debtObject = { debtDataSubTotal: debtDataSubTotal, debtDataSubTotalpurchase: debtDataSubTotalpurchase, debtDataSubTotalUnit: debtDataSubTotalUnit, debtDataSubTotalMarketValue: debtDataSubTotalMarketValue, debtDataSubTotalGainloss: debtDataSubTotalGainloss }
                    // **************for Gold
                    let goldDataSubTotal = 0;
                    let goldDataSubTotalpurchase = 0;
                    let goldDataSubTotalUnit = 0;
                    let goldDataSubTotalMarketValue = 0;
                    let goldDataSubTotalGainloss = 0;
                    goldData.map(d => {
                        goldDataSubTotal += parseFloat(d.gold)
                        goldDataSubTotalpurchase += parseFloat(d.purchase)
                        goldDataSubTotalUnit += parseFloat(d.unit)
                        goldDataSubTotalMarketValue += parseFloat(d.currentvalue)
                        goldDataSubTotalGainloss += parseFloat(d.gain)

                        //Grand Total
                        grandDataSubTotal += parseFloat(d.gold)
                        grandDataSubTotalpurchase += parseFloat(d.purchase)
                        grandDataSubTotalUnit += parseFloat(d.unit)
                        grandDataSubTotalMarketValue += parseFloat(d.currentvalue)
                        grandDataSubTotalGainloss += parseFloat(d.gain)
                    });
                    const goldObject = { goldDataSubTotal: goldDataSubTotal, goldDataSubTotalpurchase: goldDataSubTotalpurchase, goldDataSubTotalUnit: goldDataSubTotalUnit, goldDataSubTotalMarketValue: goldDataSubTotalMarketValue, goldDataSubTotalGainloss: goldDataSubTotalGainloss }

                    const grandTotalOblect = { grandDataSubTotal: grandDataSubTotal, grandDataSubTotalpurchase: grandDataSubTotalpurchase, grandDataSubTotalUnit: grandDataSubTotalUnit, grandDataSubTotalMarketValue: grandDataSubTotalMarketValue, grandDataSubTotalGainloss: grandDataSubTotalGainloss }

                    console.log('grandTotalOblect', grandTotalOblect)

                    this.setState({ grandTotalOblect: grandTotalOblect });

                    this.setState({ nameWiseAssetClass: [...this.state.nameWiseAssetClass, { debt: debtData, gold: goldData, equity: equityData, equityObject, debtObject, goldObject }] })
                    // console.log("debtData", debtData);

                })

        }

    }

    componentDidMount() {
        var logingData = JSON.parse(window.localStorage.getItem("loginUserData"))
        this.setState({ name: logingData.name, userid: logingData.userId, pan: logingData.pan_card, nameWise: [], particularSubTotal: {} })




        var allFamilyMembers = JSON.parse(window.localStorage.getItem("allFamilyMembers"))
        this.setState({ familyData: allFamilyMembers })

        var snapShot = JSON.parse(window.localStorage.getItem("snapShotData"))
        this.setState({ snapShotData: snapShot })
        const parsed = new URLSearchParams(this.props.location.search);
        let userUrlId = parsed.get('user')
        let idPan = parsed.get('pan')
        let gPan = parsed.get('gpan')

        if (userUrlId && idPan || gPan) {

            const reqdata = { pan: idPan, userid: userUrlId, gpan: gPan }
            let grandDataSubTotal = 0;
            let grandDataSubTotalpurchase = 0;
            let grandDataSubTotalUnit = 0;
            let grandDataSubTotalMarketValue = 0;
            let grandDataSubTotalGainloss = 0;

            Axios.post(api_url_wms + "/api/detailedportfolio", reqdata)
                .then((res) => {
                    this.setState({ sData: [...this.state.sData, ...res.data.data] })
                    let equityData = res?.data?.data?.filter(d => { return d.equity > 0 })
                    let debtData = res?.data?.data?.filter(d => { return d.debt > 0 })
                    let goldData = res?.data?.data?.filter(d => { return d.gold > 0 })

                    // **************for Equity
                    let equityDataSubTotal = 0;
                    let equityDataSubTotalpurchase = 0;
                    let equityDataSubTotalUnit = 0;
                    let equityDataSubTotalMarketValue = 0;
                    let equityDataSubTotalGainloss = 0;
                    equityData.map(d => {
                        equityDataSubTotal += parseFloat(d.equity)
                        equityDataSubTotalpurchase += parseFloat(d.purchase)
                        equityDataSubTotalUnit += parseFloat(d.unit)
                        equityDataSubTotalMarketValue += parseFloat(d.currentvalue)
                        equityDataSubTotalGainloss += parseFloat(d.gain)

                        //Grand Total
                        grandDataSubTotal += parseFloat(d.equity)
                        grandDataSubTotalpurchase += parseFloat(d.purchase)
                        grandDataSubTotalUnit += parseFloat(d.unit)
                        grandDataSubTotalMarketValue += parseFloat(d.currentvalue)
                        grandDataSubTotalGainloss += parseFloat(d.gain)
                    });
                    const equityObject = { equityDataSubTotal: equityDataSubTotal, equityDataSubTotalpurchase: equityDataSubTotalpurchase, equityDataSubTotalUnit: equityDataSubTotalUnit, equityDataSubTotalMarketValue: equityDataSubTotalMarketValue, equityDataSubTotalGainloss: equityDataSubTotalGainloss }
                    // **************for Debt
                    let debtDataSubTotal = 0;
                    let debtDataSubTotalpurchase = 0;
                    let debtDataSubTotalUnit = 0;
                    let debtDataSubTotalMarketValue = 0;
                    let debtDataSubTotalGainloss = 0;
                    debtData.map(d => {
                        debtDataSubTotal += parseFloat(d.debt)
                        debtDataSubTotalpurchase += parseFloat(d.purchase)
                        debtDataSubTotalUnit += parseFloat(d.unit)
                        debtDataSubTotalMarketValue += parseFloat(d.currentvalue)
                        debtDataSubTotalGainloss += parseFloat(d.gain)

                        //Grand Total
                        grandDataSubTotal += parseFloat(d.debt)
                        grandDataSubTotalpurchase += parseFloat(d.purchase)
                        grandDataSubTotalUnit += parseFloat(d.unit)
                        grandDataSubTotalMarketValue += parseFloat(d.currentvalue)
                        grandDataSubTotalGainloss += parseFloat(d.gain)
                    });
                    const debtObject = { debtDataSubTotal: debtDataSubTotal, debtDataSubTotalpurchase: debtDataSubTotalpurchase, debtDataSubTotalUnit: debtDataSubTotalUnit, debtDataSubTotalMarketValue: debtDataSubTotalMarketValue, debtDataSubTotalGainloss: debtDataSubTotalGainloss }
                    // **************for Gold
                    let goldDataSubTotal = 0;
                    let goldDataSubTotalpurchase = 0;
                    let goldDataSubTotalUnit = 0;
                    let goldDataSubTotalMarketValue = 0;
                    let goldDataSubTotalGainloss = 0;
                    goldData.map(d => {
                        goldDataSubTotal += parseFloat(d.gold)
                        goldDataSubTotalpurchase += parseFloat(d.purchase)
                        goldDataSubTotalUnit += parseFloat(d.unit)
                        goldDataSubTotalMarketValue += parseFloat(d.currentvalue)
                        goldDataSubTotalGainloss += parseFloat(d.gain)

                        //Grand Total
                        grandDataSubTotal += parseFloat(d.gold)
                        grandDataSubTotalpurchase += parseFloat(d.purchase)
                        grandDataSubTotalUnit += parseFloat(d.unit)
                        grandDataSubTotalMarketValue += parseFloat(d.currentvalue)
                        grandDataSubTotalGainloss += parseFloat(d.gain)
                    });
                    const goldObject = { goldDataSubTotal: goldDataSubTotal, goldDataSubTotalpurchase: goldDataSubTotalpurchase, goldDataSubTotalUnit: goldDataSubTotalUnit, goldDataSubTotalMarketValue: goldDataSubTotalMarketValue, goldDataSubTotalGainloss: goldDataSubTotalGainloss }

                    const grandTotalOblect = { grandDataSubTotal: grandDataSubTotal, grandDataSubTotalpurchase: grandDataSubTotalpurchase, grandDataSubTotalUnit: grandDataSubTotalUnit, grandDataSubTotalMarketValue: grandDataSubTotalMarketValue, grandDataSubTotalGainloss: grandDataSubTotalGainloss }

                    console.log('grandTotalOblect', grandTotalOblect)

                    this.setState({ grandTotalOblect: grandTotalOblect });

                    this.setState({ nameWiseAssetClass: [...this.state.nameWiseAssetClass, { debt: debtData, gold: goldData, equity: equityData, equityObject, debtObject, goldObject }] })
                    // console.log("debtData", debtData);

                })

        } else {
            for (let i = 0; i < allFamilyMembers.length; i++) {
                const reqdata = { pan: allFamilyMembers[i].Pan, userid: allFamilyMembers[i].userid, gpan: '' }

                Axios.post(api_url_wms + "/api/detailedportfolio", reqdata)
                    .then((res) => {
                        this.setState({ sData: [...this.state.sData, ...res.data.data] })
                        let equityData = res?.data?.data?.filter(d => { return d.equity > 0 })
                        let debtData = res?.data?.data?.filter(d => { return d.debt > 0 })
                        let goldData = res?.data?.data?.filter(d => { return d.gold > 0 })

                        // **************for Equity
                        let equityDataSubTotal = 0;
                        let equityDataSubTotalpurchase = 0;
                        let equityDataSubTotalUnit = 0;
                        let equityDataSubTotalMarketValue = 0;
                        let equityDataSubTotalGainloss = 0;
                        equityData.map(d => {
                            equityDataSubTotal += parseFloat(d.equity)
                            equityDataSubTotalpurchase += parseFloat(d.purchase)
                            equityDataSubTotalUnit += parseFloat(d.unit)
                            equityDataSubTotalMarketValue += parseFloat(d.currentvalue)
                            equityDataSubTotalGainloss += parseFloat(d.gain)
                        });
                        const equityObject = { equityDataSubTotal: equityDataSubTotal, equityDataSubTotalpurchase: equityDataSubTotalpurchase, equityDataSubTotalUnit: equityDataSubTotalUnit, equityDataSubTotalMarketValue: equityDataSubTotalMarketValue, equityDataSubTotalGainloss: equityDataSubTotalGainloss }
                        // **************for Debt
                        let debtDataSubTotal = 0;
                        let debtDataSubTotalpurchase = 0;
                        let debtDataSubTotalUnit = 0;
                        let debtDataSubTotalMarketValue = 0;
                        let debtDataSubTotalGainloss = 0;
                        debtData.map(d => {
                            debtDataSubTotal += parseFloat(d.debt)
                            debtDataSubTotalpurchase += parseFloat(d.purchase)
                            debtDataSubTotalUnit += parseFloat(d.unit)
                            debtDataSubTotalMarketValue += parseFloat(d.currentvalue)
                            debtDataSubTotalGainloss += parseFloat(d.gain)
                        });
                        const debtObject = { debtDataSubTotal: debtDataSubTotal, debtDataSubTotalpurchase: debtDataSubTotalpurchase, debtDataSubTotalUnit: debtDataSubTotalUnit, debtDataSubTotalMarketValue: debtDataSubTotalMarketValue, debtDataSubTotalGainloss: debtDataSubTotalGainloss }
                        // **************for Gold
                        let goldDataSubTotal = 0;
                        let goldDataSubTotalpurchase = 0;
                        let goldDataSubTotalUnit = 0;
                        let goldDataSubTotalMarketValue = 0;
                        let goldDataSubTotalGainloss = 0;
                        goldData.map(d => {
                            goldDataSubTotal += parseFloat(d.gold)
                            goldDataSubTotalpurchase += parseFloat(d.purchase)
                            goldDataSubTotalUnit += parseFloat(d.unit)
                            goldDataSubTotalMarketValue += parseFloat(d.currentvalue)
                            goldDataSubTotalGainloss += parseFloat(d.gain)
                        });
                        const goldObject = { goldDataSubTotal: goldDataSubTotal, goldDataSubTotalpurchase: goldDataSubTotalpurchase, goldDataSubTotalUnit: goldDataSubTotalUnit, goldDataSubTotalMarketValue: goldDataSubTotalMarketValue, goldDataSubTotalGainloss: goldDataSubTotalGainloss }

                        this.setState({ nameWiseAssetClass: [...this.state.nameWiseAssetClass, { name: allFamilyMembers[i].Name, debt: debtData, gold: goldData, equity: equityData, equityObject, debtObject, goldObject }] })
                        console.log("debtData", debtData);

                    })
            }
            this.setState({ wmsloaded: !this.state.wmsloaded, familyDataLoaded: !this.state.familyDataLoaded });
        }




    }
    TableData = (d, index) => {
        return <>
            < tr className={index % 2 == 0 ? "even" : "odd"}>
                <td className='fs-16'>{d.scheme}</td>
                <td>{d.folio}</td>
                <td>{d.unit}</td>
                <td>{d.purchase}</td>
                <td>{d.cnav}</td>
                <td>{d.currentvalue}</td>
                <td>{d.gain}</td>
                <td>{d.days}</td>
                <td>{d.absolute}</td>
                <td>{d.cagr.toFixed(2)}</td>
                <td><Transact_Icon_Group /></td>
                {/* <img src={New_purchase} className="mr-2 new-icon p-3" alt='sss' /> */}

            </tr>
        </>
    }

    render() {
        let DataList = []; var date = '';



        const profile = [];
        if (this.state.familyData) {
            profile.push({
                label: "All",
                value: 'all'
            });
            this.state.familyData.map((val) => {
                let pc = {
                    label: val.Name,
                    value: { pan: val.Pan, userid: val.userid }
                }
                profile.push(pc);
            })
        }
        let defaultOption = {}
        const parsedd = new URLSearchParams(this.props.location.search);
        if (parsedd.get('name')) {
            defaultOption = {
                label: parsedd.get('name'),
                value: parsedd.get('name')
            }
        } else {
            defaultOption = {
                label: "All",
                value: 'all'
            }
        }



        // console.log("from render ", this.state.sData, "familyData ", this.state.familyData);

        // console.log("nameWiseAssetClass ", this.state.nameWiseAssetClass)



        return (

            <>
                <Helmet>
                    <title>Prodigy Pro - Portfolio</title>
                </Helmet>
                <StyleComponent />
                <style>
                    {`

            .swal2-content {
                padding: 17px;
                margin: 22px;
                color: red;
              }
            .mt-input{
                margin-top:3.5%;
            }
            .mt-btn{
                margin-top:12%;
            }
            .filter-card-body{
                padding-top: .5rem;
                padding-bottom: .5rem;
                padding-left: 1.25rem;
                padding-right: 1.25rem;
            }
            th{
                white-space: nowrap;
            }
            .table td, .table th {
                // padding: .60rem;
                font-size:14px;
            }

            .no-wrap-ws{
                white-space: nowrap;
            }
            // .table-responsive{
            //     height: 500px !important;
            // }
            .table-responsive thead { 
                position: sticky;
                top: 0; 
                z-index: 1;
            }
            .table-responsive tfoot { 
                position: sticky;
                bottom: 0; 
                z-index: 1;
            }
            .icon-contain{
                height:22px;
                width:22px;
            }
            .footer_tr{
              display:none
            }
            .wait{
              display:none
            }
            #unit_div{
              display:none;
            }
            #unit_val{
            display:none;
            }
            #amt_val{
            display:none;
            }
            #rem_unit_div{
              display:none;
            }
            #rem_unit_val{
            display:none;
            }
            #rem_amt_val{
            display:none;
            }
            #end_date{
              display:none;
            }
            #payTypeDiv{
              display:none;
            }
            #bankNameDiv{
              display:none;
            }
            #mandateDiv{
              display:none;
            }
             .alert_msg{
              text-align: center;
            }
            .form-control{
                border-radius: 0rem;
                height: calc(2.25rem + 6px);
                border-color: none !important;             
                color:#3A3A3A;
     
             }
             
             .form-control:focus {
                 color: #495057;
                 background-color:#fff;                
                 outline: 0;
                 border-color:none!important;
                 box-shadow: none;
                 border-radius: 0rem;
                 height: calc(2.25rem + 6px);
             }
             .table td, .table th {
                vertical-align: top;
                border-bottom: 1px solid #CDD4FE;
                border-top: none !important;
              }
              .family-portfolio
              {
background-color:#fff!important;
              }
          `}
                </style>

                {/* Page Wrapper */}
                <div id="wrapper">
                    {/*Loader*/}
                    {/* <div id="overlay" >
                        <div class="spinner"></div>
                        <br /><b className="text-danger">Please Wait...</b>
                    </div> */}
                    {/* Message model */}
                    <div class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true" data-keyboard="false" data-backdrop="static">
                        <div class="modal-dialog modal-dialog-centered" role="document">
                            <div class="modal-content">

                                <div class="modal-body alert_msg">
                                    <p className="text-dark font-weight-bold">
                                        {this.state.alertMsg}
                                    </p>
                                    <div className="text-center">
                                        <button type="button" class="btn btn-primary" data-dismiss="modal">OK</button>
                                    </div>
                                </div>
                                {/* <div class="modal-footer">
                
                </div> */}
                            </div>
                        </div>
                    </div>

                    {/* order model */}
                    <div class="modal fade bd-example-modal-lg" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
                        <div class="modal-dialog modal-lg">
                            <div class="modal-content">

                                <div className="modal-header title">
                                    <h5 className="modal-title text-center " id="exampleModalLabel">Order Screen</h5>
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div className="table-responsive-lg">
                                    <table class="table">
                                        <thead class="thead-light">
                                            <tr>
                                                <th scope="col"> Unique No</th>
                                                <th scope="col"> Trxn No</th>
                                                <th scope="col">Application No</th>
                                                <th scope="col">Fund</th>
                                                <th scope="col">Scheme</th>
                                                <th scope="col">Scheme Name</th>
                                                <th scope="col">Amount</th>
                                                <th scope="col">Status</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {/* {this.state.orderData ?
                        this.state.orderData.map((val) =>
                          <tr>
                            <th scope="row">{val.Unique_No}</th>
                            <td>{val.Trxn_No}</td>
                            <td>{val.Application_No}</td>
                            <td>{val.Fund}</td>
                            <td>{val.Scheme}</td>
                            <td>{val.Scheme_Name}</td>
                            <td>{val.Amt}</td>
                            <td>{val.Status_Desc}</td>
                          </tr>
                        ) : null} */}

                                        </tbody>
                                    </table>
                                </div>
                                <div class="modal-body">
                                    <div className="alert alert-info">
                                        <h6 className="text-left text-danger">Note:</h6>
                                        {/* <p>{this.state.orderMsg}</p> */}
                                    </div>
                                </div>

                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>

                                </div>

                            </div>
                        </div>
                    </div>


                    {/* Sidebar */}
                    {/* <Sidebar /> */}
                    {/* End of Sidebar */}
                    {/* Content Wrapper */}
                    <div id="content-wrapper" className="d-flex flex-column portfolio-page">
                        <ToastContainer position="top-right" className="mt-8" />
                        {/* Main Content */}
                        <div id="content">
                            {/* Topbar */}
                            <Header />
                            {/* End of Topbar */}
                            {/* Begin Page Content */}
                            <div className="container-fluid">
                                {/* Page Heading */}
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item"><a href="home">Home</a></li>
                                        <li className="breadcrumb-item active" aria-current="page">Portfolio </li>
                                    </ol>
                                </nav>



                                <div className="row">
                                    {/* Area Chart */}
                                    <div className="col-xl-12 col-lg-12 px-5">
                                        <div className=" mb-3">
                                            {/* Card Header - Dropdown */}
                                            <div className="py-3 d-flex flex-row align-items-center justify-content-between">
                                                <h6 className="m-0 heading-cust">{/*Portfolio - */} Report On Unrealized Gains Only</h6>
                                            </div>
                                            {/* Card Body */}
                                            <div className=" filter-card-body">
                                                <div className="row">
                                                    <div className="col-lg-4 col-md-4 col-sm-6 ">
                                                        <label>Select Applicant Name</label>
                                                        <Select className='portf1' onChange={(e) => this.selectedOne(e.value)} options={profile} defaultValue={defaultOption} placeholder="Please select your role" />
                                                    </div>
                                                    {/* <div className="col-lg-3 col-md-3 col-sm-6">
                                <div class="form-group">
                                    <label>As On Date</label>
                                    <div class="input-group">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text">
                                            <i class="far fa-calendar-alt"></i>
                                            </span>
                                        </div>
                                    <input type="date" className="form-control" />
                                        </div>
                                    </div>
                             </div>
                             <div className="col-lg-3 col-md-3 col-sm-6 ">
                                        <label>Select Category</label>
                                        <select className="form-control">
                                        <option>1</option>
                                        <option>2</option>
                                        <option>3</option>
                                        </select>
                             </div>
                             <div className="col-lg-3 col-md-3 col-sm-6">
                                    <a className="btn btn-danger shadow-sm mt-btn w-100">Show</a>
                             </div> */}
                                                </div>
                                            </div>
                                        </div>

                                        <div className=" mb-5">
                                            <div className=" py-3 d-flex flex-row align-items-center justify-content-between">
                                                {/* <h6 className="m-0 heading-cust">A</h6> */}
                                            </div>

                                            <div className="p-2 family-portfolio">
                                                <div className="table-responsive" style={{ maxHeight: "500px" }}>
                                                    <table className="table">
                                                        <tr className='sticky-c bg-light-red'>
                                                            {/* <th scope="col" className="text-white">Sr.No</th> */}
                                                            <th scope="col" className="red" >Scheme / Company</th>
                                                            <th scope="col" className="red">Folio</th>
                                                            <th scope="col" className="red">Balance Units</th>
                                                            <th scope="col" className="red">Purchase Cost</th>
                                                            <th scope="col" className="red">Current NAV</th>
                                                            <th scope="col" className="red">Market Value</th>
                                                            {/*<th scope="col" className="text-white">Div. Paid</th>*/}
                                                            <th scope="col" className="red">Gain/Loss</th>
                                                            <th scope="col" className="red">Avg. Days</th>
                                                            <th scope="col" className="red">Absolute Return %</th>
                                                            <th scope="col" className="red">CAGR %</th>
                                                            <th scope="col" className="red">Transact</th>

                                                        </tr>

                                                        {
                                                            this.state.nameWise?.length > 0 ? this.state.nameWise?.map((d, index) => {

                                                                return <>
                                                                    {this.TableData(d, index)}

                                                                </>
                                                            }) :
                                                                this.state.nameWiseAssetClass ? this.state.nameWiseAssetClass?.map((data, idx) => {
                                                                    return <>

                                                                        <><p className="red min-w-15 investor-name">{data.name}</p> <p style={{ color: '#36b9cc' }} className="asset-class">Equity</p></>
                                                                        {this.state.nameWiseAssetClass[idx]?.equity.length > 0 ? this.state.nameWiseAssetClass[idx]?.equity?.map((eData, index) => {
                                                                            return <>
                                                                                {this.TableData(eData, index)}


                                                                            </>
                                                                        }) : null}
                                                                        {this.state.nameWiseAssetClass[idx]?.equityObject.equityDataSubTotalpurchase > 0 ? <tr className='bg-gray'>
                                                                            <td className='fs-16'><b>Sub Total</b></td>
                                                                            <td></td>
                                                                            <td>{this.state.nameWiseAssetClass[idx]?.equityObject.equityDataSubTotalUnit.toFixed(4)}</td>
                                                                            <td>{this.state.nameWiseAssetClass[idx]?.equityObject.equityDataSubTotalpurchase.toFixed(2)}</td>
                                                                            <td></td>
                                                                            <td>{(this.state.nameWiseAssetClass[idx]?.equityObject.equityDataSubTotalMarketValue).toFixed(2)}</td>
                                                                            <td>{(this.state.nameWiseAssetClass[idx]?.equityObject.equityDataSubTotalGainloss).toFixed(2)}</td>
                                                                            <td></td>
                                                                            <td></td>
                                                                            <td></td>
                                                                            <td></td>

                                                                        </tr> : null}
                                                                        {this.state.nameWiseAssetClass[idx]?.debt.length > 0 ? this.state.nameWiseAssetClass[idx]?.debt?.map((debtData, index) => {
                                                                            return <>
                                                                                <p style={{ color: '#36b9cc' }} className="asset-class" >Debt</p>
                                                                                {this.TableData(debtData, index)}

                                                                            </>
                                                                        }) : null}
                                                                        {this.state.nameWiseAssetClass[idx]?.debtObject.debtDataSubTotalpurchase > 0 ? <tr className='bg-gray'>
                                                                            <td className='fs-16'><b>Sub Total</b></td>
                                                                            <td></td>
                                                                            <td>{this.state.nameWiseAssetClass[idx]?.debtObject.debtDataSubTotalUnit.toFixed(4)}</td>
                                                                            <td>{this.state.nameWiseAssetClass[idx]?.debtObject.debtDataSubTotalpurchase.toFixed(2)}</td>
                                                                            <td></td>
                                                                            <td>{(this.state.nameWiseAssetClass[idx]?.debtObject.debtDataSubTotalMarketValue).toFixed(2)}</td>
                                                                            <td>{(this.state.nameWiseAssetClass[idx]?.debtObject.debtDataSubTotalGainloss).toFixed(2)}</td>
                                                                            <td></td>
                                                                            <td></td>
                                                                            <td></td>
                                                                            <td></td>

                                                                        </tr> : null}
                                                                        {this.state.nameWiseAssetClass[idx]?.gold.length > 0 ? this.state.nameWiseAssetClass[idx]?.gold?.map((goldData, index) => {
                                                                            return <>
                                                                                <p style={{ color: '#36b9cc' }} className="asset-class" >Gold</p>
                                                                                {this.TableData(goldData)}

                                                                            </>
                                                                        }) : null}
                                                                        {this.state.nameWiseAssetClass[idx]?.goldObject.goldDataSubTotalpurchase > 0 ? <tr className='bg-gray'>
                                                                            <td className='fs-16'><b>Sub Total</b></td>
                                                                            <td></td>
                                                                            <td>{this.state.nameWiseAssetClass[idx]?.goldObject.goldDataSubTotalUnit.toFixed(4)}</td>
                                                                            <td>{this.state.nameWiseAssetClass[idx]?.goldObject.goldDataSubTotalpurchase.toFixed(2)}</td>
                                                                            <td></td>
                                                                            <td>{(this.state.nameWiseAssetClass[idx]?.goldObject.goldDataSubTotalMarketValue).toFixed(2)}</td>
                                                                            <td>{(this.state.nameWiseAssetClass[idx]?.goldObject.goldDataSubTotalGainloss).toFixed(2)}</td>
                                                                            <td></td>
                                                                            <td></td>
                                                                            <td></td>
                                                                            <td></td>

                                                                        </tr> : null}

                                                                    </>


                                                                }) : null}

                                                        {this.state.particularSubTotal.Totalpurchase > 0 ? <tr className='bg-light-red text-black font-weight-600'>
                                                            <td className='fs-16 '>Total</td>
                                                            <td></td>
                                                            <td></td>
                                                            <td>{this.state.particularSubTotal.Totalpurchase}</td>
                                                            <td></td>
                                                            <td>{this.state.particularSubTotal.TotalMarketValue}</td>
                                                            <td>{this.state.particularSubTotal.Gainloss}</td>
                                                            <td></td>
                                                            <td></td>
                                                            <td>{this.state.particularSubTotal.Cagr}</td>
                                                            <td></td>

                                                        </tr> : null
                                                        }
                                                        {this.state.grandTotalOblect!=null ?
                                                            <tr className='bg-light-red text-black font-weight-600'>
                                                                <td className='fs-16'><b>Grand Total</b></td>
                                                                <td></td>
                                                                <td>{this.state.grandTotalOblect.grandDataSubTotalUnit.toFixed(4)}</td>
                                                                <td>{this.state.grandTotalOblect.grandDataSubTotalpurchase.toFixed(2)}</td>
                                                                <td></td>
                                                                <td>{this.state.grandTotalOblect.grandDataSubTotalMarketValue.toFixed(2)}</td>
                                                                <td>{this.state.grandTotalOblect.grandDataSubTotalGainloss.toFixed(2)}</td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>

                                                            </tr>
                                                            :
                                                            <tr className='bg-light-red text-black font-weight-600'>
                                                                <td className='fs-16'><b>Grand Total</b></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td>{this.state.snapShotData?.Totalpurchase}</td>
                                                                <td></td>
                                                                <td>{this.state.snapShotData?.Totalmarketvalue}</td>
                                                                <td>{this.state.snapShotData?.Gainloss}</td>
                                                                <td>{this.state.snapShotData?.Finaldays}</td>
                                                                <td></td>
                                                                <td>{this.state.snapShotData?.Finalcagr}</td>
                                                                <td></td>

                                                            </tr>
                                                        }



                                                        <tbody>
                                                        </tbody>


                                                    </table>
                                                </div>
                                            </div>
                                        </div>




                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* End of Main Content */}

                        {/*Addtitional Purchase Modal */}
                        <div className="modal fade" id="additionalPurchase" tabIndex={-1} role="dialog" aria-labelledby="additionalPurchaseTitle" aria-hidden="true">
                            <div className="modal-dialog modal-dialog-centered" role="document">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h5 className="modal-title text-danger" id="additionalPurchaseTitle">Addtitional Purchase</h5>
                                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                            <span aria-hidden="true">×</span>
                                        </button>
                                    </div>
                                    <div className="modal-body">
                                        <form>
                                            <div className="col mb-3">
                                                <div class="alert alert-info" role="alert">
                                                    <h6></h6>
                                                    <h6 className="mb-0">rajkumar</h6>
                                                </div>
                                            </div>
                                            <div className="col mb-3">
                                                <label htmlFor="amt" >Enter Amount   <spna className="text-danger">*</spna></label>
                                                <input className="form-control input-text" id="amt" type="Text" name="add_amt" placeholder="Enter Amount" />
                                                <small className="text-danger"></small>
                                            </div>

                                            <div className="col mb-3" >
                                                <label htmlFor="profile">Select Payment Mode   <spna className="text-danger">*</spna></label>
                                                <select className="form-control input-text" name="add_paymentMode" >
                                                    <option value="">Select</option>
                                                    <option value="OL">Net Banking</option>
                                                    <option value="UPI">UPI</option>
                                                    <option value="TR">RTGS/NEFT</option>
                                                    <option value="M">Debit Mandate</option>
                                                </select>
                                                <small className="text-danger">{this.state.add_paymentMode_err}</small>
                                            </div>
                                            <div className="col my-2" id="payTypeDiv">
                                                <input className=" input-text" id="emailLink" type="radio" name="add_payType" value="N" />
                                                <label htmlFor="emailLink">Link On Email</label>
                                                <input className="input-text ml-3" id="immediatePay" type="radio" name="add_payType" value="Y" />
                                                <label htmlFor="immediatePay">Immediate Payment</label>
                                                <br></br>
                                                <small className="text-danger">{this.state.add_payType_err}</small>
                                            </div>
                                            <div className="col mb-3" id="bankNameDiv">
                                                <label htmlFor="bank">Select Bank   <spna className="text-danger">*</spna></label>
                                                <select className="form-control" data-live-search="true" name="add_bankName" onChange={this.bankDetail}>
                                                    <option value="">Select</option>
                                                    {this.state.userBankList ?
                                                        this.state.userBankList.map((item, key) =>
                                                            <option value={item.bank_code}>{item.bank_name}</option>
                                                        ) : null}
                                                </select>
                                                <small className="text-danger">{this.state.add_bankName_err}</small>
                                            </div>
                                            <div className="col mb-3" id="mandateDiv">
                                                <label htmlFor="mandate" >Mandate  <spna className="text-danger">*</spna></label>
                                                <select className="form-control" data-live-search="true" name="add_mandate" onChange={this.getMandate}>
                                                    <option value="">Select</option>
                                                    {this.state.userMandateList ?
                                                        this.state.userMandateList.map((item, key) =>
                                                            <option value={item.MANDATE_ID}>{"Bank Name:- " + item.BANK_NAME} | {"A/C No:- " + item.ACCOUNT_NO} | {"A/C Amount:- " + item.AMOUNT}</option>
                                                        ) : null}
                                                </select>
                                                <small className="text-danger">{this.state.add_mandate_err}</small>
                                            </div>
                                        </form>
                                    </div>
                                    <div className="modal-footer">
                                        {/* <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button> */}
                                        <button type="button" className="btn btn-danger shadow-sm" id="placeOrder" >Place Order</button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/*SIP Modal */}
                        <div className="modal fade" id="sip" tabIndex={-1} role="dialog" aria-labelledby="sipTitle" aria-hidden="true">
                            <div className="modal-dialog modal-dialog-centered" role="document">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h5 className="modal-title text-danger" id="sipTitle">SIP</h5>
                                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                            <span aria-hidden="true">×</span>
                                        </button>
                                    </div>
                                    <div className="modal-body">
                                        <form>
                                            <div className="col mb-3">
                                                <div class="alert alert-info" role="alert">
                                                    <h6>rrr</h6>
                                                    <h6 className="mb-0">eeee</h6>
                                                </div>
                                            </div>

                                            <div className="col mb-3">
                                                <label htmlFor="mandate" >Mandate  <spna className="text-danger">*</spna></label>
                                                <select className="form-control" data-live-search="true" name="sip_mandate">
                                                    <option value="">Select</option>

                                                    <option value="{item.MANDATE_ID}">Bank Name</option>
                                                </select>
                                                <small className="text-danger"></small>
                                            </div>
                                            <div className="row px-3">
                                                <div className="col-md-6 mb-3">
                                                    <label htmlFor="amt" >Enter Amount  <spna className="text-danger">*</spna></label>
                                                    <input className="form-control input-text" id="amt" type="Text" name="sip_amt" placeholder="Enter Amount" />
                                                    <small className="text-danger">44</small>
                                                </div>
                                                <div className="col-md-6 mb-3">
                                                    <label htmlFor="amt" >SIP Date  <spna className="text-danger">*</spna></label>
                                                    <select className="form-control" name="sip_date" data-live-search="true" >
                                                        <option value="">Select</option>
                                                        {DataList}
                                                    </select>
                                                    <small className="text-danger">err</small>
                                                </div>
                                            </div>
                                            <div className="row px-3">
                                                <div className="col-sm-6 mb-3">
                                                    <label htmlFor="date_from">SIP Start Date  <spna className="text-danger">*</spna></label>
                                                    <input className="form-control input-text" id="month_from" name="month_from" type="month" placeholder="Enter Value" />
                                                    <small className="text-danger">rrrr</small>
                                                </div>
                                                <div className="col-sm-6 mb-3" id="end_date">
                                                    <label htmlFor="date_to">SIP End Date  <spna className="text-danger">*</spna></label>
                                                    <input className="form-control input-text" id="month_to" name="month_to" type="month" placeholder="Enter Value" />
                                                    <small className="text-danger">rrrr</small>
                                                </div>
                                            </div>
                                            <div className="col mb-3">
                                                <input className="input-text" id="perpetual" type="checkbox" name="perpetual" value="Y" defaultChecked />
                                                <input type="hidden" id="perpetual_val" name="perpetual_val" />
                                                <label htmlFor="perpetual" className="text-label ml-2">Perpetual   <spna className="text-danger">*</spna></label>
                                            </div>
                                        </form>
                                    </div>
                                    <div className="modal-footer">
                                        <button type="button" className="btn btn-danger shadow-sm" id="sipPlaceOrder" >Place Order</button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/*Switch Modal */}
                        <div className="modal fade" id="switch" tabIndex={-1} role="dialog" aria-labelledby="switchTitle" aria-hidden="true">
                            <div className="modal-dialog modal-dialog-centered" role="document">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h5 className="modal-title text-danger" id="switchTitle">Switch</h5>
                                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                            <span aria-hidden="true">×</span>
                                        </button>
                                    </div>
                                    <div className="modal-body">
                                        <form>
                                            <div className="col mb-3">
                                                <div class="alert alert-info" role="alert">
                                                    <h6>rrrrr</h6>
                                                    <h6 className="mb-0">rrrr</h6>

                                                </div>
                                            </div>
                                            <div className="col mb-3">
                                                <label htmlFor="target" >Select Target Scheme   <spna className="text-danger">*</spna></label>
                                                <select className="form-control" name="sw_target_scheme" id="target_scheme" onChange={this.getAmtUnit}>
                                                    <option value="">Select</option>

                                                    <option value="{item.PRODUCT_CODE}">PRODUCT_LONG_NAME</option>

                                                </select>
                                                <small className="text-danger">scheme_err</small>
                                            </div>
                                            <div className="col mb-3">
                                                <p className=" mb-1">Switch Type   <spna className="text-danger">*</spna></p>
                                                <input id="amt" value="amt" type="radio" name="sw_amt_type" />
                                                <label htmlFor="amt">By Amount</label>
                                                <input className="ml-3" id="units" type="radio" name="sw_amt_type" value="unit" />
                                                <label htmlFor="units">By Units</label>
                                                <input className=" ml-3" id="all_units" type="radio" name="sw_amt_type" value="all_units" />
                                                <label htmlFor="all_units">All Units</label>
                                                <br></br>
                                                <small className="text-danger">allerr</small>
                                            </div>
                                            <div className="col mb-3" id="amt_div">
                                                <label htmlFor="amt" >Enter Amount   <spna className="text-danger">*</spna></label>
                                                <input className="form-control" name="sw_amt" id="sw_amt" type="Text" placeholder="Enter Amount" />
                                                <small className="text-danger">amount err</small>
                                            </div>
                                            <div className="col mb-3" id="unit_div">
                                                <label htmlFor="val" >Enter Unit  <spna className="text-danger">*</spna></label>
                                                <input className="form-control" name="sw_unit" id="val" type="Text" placeholder="Enter Unit" />
                                                <small className="text-danger">unit_err</small>
                                            </div>
                                            <div className="col mb-3">
                                                <span className="has-float-label" id="unit_val"></span>
                                                <input id="all_units" type="hidden" name="sw_all_units" />
                                                <input id="amt_unit_type" type="hidden" name="sw_amt_unit_type" />
                                            </div>
                                        </form>
                                    </div>
                                    <div className="modal-footer">
                                        <button type="button" className="btn btn-danger shadow-sm" id="sw_placeOrder" >Place Order</button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/*Redemption Modal */}
                        <div className="modal fade" id="redemption" tabIndex={-1} role="dialog" aria-labelledby="redemptionTitle" aria-hidden="true">
                            <div className="modal-dialog modal-dialog-centered" role="document">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h5 className="modal-title text-danger" id="redemptionTitle">Redemption</h5>
                                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                            <span aria-hidden="true">×</span>
                                        </button>
                                    </div>
                                    <div className="modal-body">
                                        <form>
                                            <div className="col mb-3">
                                                <div class="alert alert-info" role="alert">
                                                    <h6>this.state.userSchemeList.name</h6>
                                                    <h6 className="mb-0">erSchemeList ? this.state.userSchemeList.scheme</h6>
                                                </div>
                                            </div>
                                            <div className="col mb-3">
                                                <p className=" mb-1">Redemption Type  <spna className="text-danger">*</spna></p>
                                                <input id="amt" value="amt" type="radio" name="rem_amt_type" />
                                                <label htmlFor="amt">By Amount</label>
                                                <input className="ml-3" id="units" type="radio" name="rem_amt_type" value="unit" />
                                                <label htmlFor="units">By Units</label>
                                                <input className=" ml-3" id="all_units" type="radio" name="rem_amt_type" value="all_units" />
                                                <label htmlFor="all_units">All Units</label>
                                                <br></br>
                                                <small className="text-danger">this.state.rem_amt_type_err</small>
                                            </div>
                                            <div className="col mb-3" id="rem_amt_div">
                                                <label htmlFor="amt" >Enter Amount   <spna className="text-danger">*</spna></label>
                                                <input className="form-control" name="rem_amt" id="rem_amt" type="Text" placeholder="Enter Amount" />
                                                <small className="text-danger">this.state.rem_amt_err</small>
                                            </div>
                                            <div className="col mb-3" id="rem_unit_div">
                                                <label htmlFor="val" >Enter Amount  <spna className="text-danger">*</spna></label>
                                                <input className="form-control" name="rem_unit" id="val" type="Text" placeholder="Enter Unit" />
                                                <small className="text-danger">this.state.rem_unit_err</small>
                                            </div>
                                            <div className="col mb-3">
                                                <span className="has-float-label" id="rem_unit_val"></span>
                                                <input id="rem_all_units" type="hidden" name="rem_all_units" />
                                                <input id="rem_amt_unit_type" type="hidden" name="rem_amt_unit_type" />
                                            </div>

                                            {/* <div className="col mb-3">
                        <p className=" mb-1">Redemption Type</p>
                        <input id="amt" type="radio" name="demo" />
                        <label htmlFor="amt">By Amount</label>
                        <input className=" ml-3" id="units" type="radio" name="demo" />
                        <label htmlFor="units">By Units</label>
                        <input className=" ml-3" id="all_units" type="radio" name="demo" />
                        <label htmlFor="all_units">All Units</label>                                         
                  </div>
                    <div className="col mb-3">
                        <label htmlFor="amt" >Enter Amount</label>
                        <input className="form-control" id="amt" type="Text" placeholder="Enter Amount" />
                    </div>  
                 */}
                                        </form>
                                    </div>
                                    <div className="modal-footer">
                                        <button type="button" className="btn btn-danger shadow-sm" id="remPlaceOrder">Place Order</button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* Footer */}
                        <Footer />
                        {/* End of Footer */}
                    </div>
                    {/* End of Content Wrapper */}
                </div>
                {/* End of Page Wrapper */}
            </>
        )
    }

}
export default Portfolio
