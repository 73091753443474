import React from "react";
import Header from './header';
import Footer from './footer';
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import sipc from "../../assets/images/icons/New folder (2)/budget.png";
import ring from "../../assets/images/icons/New folder (2)/rings.png";
import education from "../../assets/images/icons/New folder (2)/education.png";
import emi from "../../assets/images/icons/New folder (2)/calculatorfuture.png";
import retirement from "../../assets/images/icons/New folder (2)/retirement.png";
import futurec from "../../assets/images/icons/New folder (2)/calculator.png";
import minus from "../../assets/images/icons/New folder (2)/minus_3(1).png";
import plus from "../../assets/images/icons/New folder (2)/add_3(1).png";
import fd from "../../assets/images/icons/New folder (2)/fd.png";
import elss from "../../assets/images/icons/New folder (2)/elss.png";
import $ from "jquery";
import Button from 'react-bootstrap/Button';
// import 'animate.css';
import { LineChart } from 'react-chartkick';
import 'chartkick/chart.js';
class Retirement_Planning_Pro  extends React.Component {
render() {
return (
<>
<Helmet>
  <title>Calculators - Prodigy Pro</title>
</Helmet>
<style>
  {`
  .result-content.text-center .text-label.font-weight-500.py-2 {
  min-height:65px;
  }
  `}
</style>
{/* Page Wrapper */}
<div id="wrapper">
  {/* 
  <div id="overlay" >
    <div className="spinner"></div>
    <br />Loading...
  </div>
  */}
  {/* Content Wrapper */}
  <div id="content-wrapper" className="d-flex flex-column">
    {/* Main Content */}
    <div id="content">
      {/* Topbar */}
      <Header />
      {/* End of Topbar */}
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb">
          <li className="breadcrumb-item"><a href="home">Home</a></li>
          <li className="breadcrumb-item active" aria-current="page">Calculators</li>
        </ol>
      </nav>
      <div className="container-fluid  px-lg-5 pb-5 calculator">
        <div className="row">
          <div className="col-md-12">
            {/* =============Calculators Nav Start================ */}
            <div class="container-calculator pb-5">
              <ul class="nav navtop text-center">
                <div className="col-lg-3 col-md-6">
                  <li class="nav-item">
                    <Link class="nav-link " to="/prodigypro/dashboard/sip-calculator-pro">
                    <img src={sipc} className="new-icon" alt='' />  <span>   SIP Calculator</span>
                    </Link>
                  </li>
                </div>
                <div className="col-lg-3 col-md-6 pt-xs-2">
                  <li class="nav-item">
                    <Link class="nav-link"  to="/prodigypro/dashboard/marriage-planning-pro">
                    <img src={ring} className="new-icon" alt='' />  <span>Marriage
                    Planning</span></Link>
                  </li>
                </div>
                <div className="col-lg-3 col-md-6 pt-xs-2 pt-tab-2">
                  <li class="nav-item">
                    <Link class="nav-link"  to="/prodigypro/dashboard/education-planning-pro">
                    <img src={education} className="new-icon" alt='' /> <span>Education
                    Planning</span></Link>
                  </li>
                </div>
                <div className="col-lg-3 col-md-6 pt-xs-2 pt-tab-2">
                  <li class="nav-item">
                    <Link class="nav-link"  to="/prodigypro/dashboard/future-value-calculator-pro">
                    <img src={futurec} className="new-icon" alt='' /><span>Future Value
                    Calculator</span></Link>
                  </li>
                </div>
                <div className="col-lg-3 col-md-6 mt-3">
                  <li class="nav-item">
                    <Link class="nav-link active"  to="/prodigypro/dashboard/retirement-planning-pro">
                    <img src={retirement} className="new-icon" alt='' /><span>Retirement
                    Planning</span></Link>
                  </li>
                </div>
                <div className="col-lg-3 col-md-6 mt-3">
                  <li class="nav-item">
                    <Link class="nav-link "  to="/prodigypro/dashboard/emi-calculator-pro">
                    <img src={emi} className="new-icon" alt='' /><span>EMI
                    Calculator</span></Link>
                  </li>
                </div>
                <div className="col-lg-3 col-md-6 mt-3">
                  <li class="nav-item">
                    <Link class="nav-link"  to="/prodigypro/dashboard/fd-calculator-pro">
                    <img src={fd} className="new-icon" alt='' /><span>FD
                    Calculator</span></Link>
                  </li>
                </div>
                <div className="col-lg-3 col-md-6 mt-3">
                  <li class="nav-item">
                    <Link class="nav-link"  to="/prodigypro/dashboard/elss-calculator-pro">
                    <img src={elss} className="new-icon" alt='' /><span>ELSS
                    Calculator</span></Link>
                  </li>
                </div>
              </ul>
              {/* =============Calculators Nav End================ */}
              <div class=" mt-3 ">
                <div class=""  role="tabpanel">
                  <section>
                    <div className="results pt-5">
                      <div className="px-lg-5">
                        <div className="shadowc br-50 px-lg-4 px-sm-3 pb-5">
                          <section className="pt-5 pb-5">
                            <div className="row" >
                              {/*first*/}
                              <div className="col-md-4 text-center">
                                <label for="m-saving" className="text-label font-weight-500 py-2 fs-14">
                                Current Age (Years)
                                </label>
                                <div className="d-flex inputf transcard">
                                  <img src={minus} alt="" className="img-fluid max-27"></img>
                                  <input type="text" className="form-control" name="m-saving" placeholder="200" />
                                  <img src={plus} alt="" className="img-fluid max-27"></img>
                                </div>
                              </div>
                              <div className="col-md-4 text-center">
                                <label for="year" className="text-label font-weight-500 py-2 fs-14">
                                Expected retirement age (Years) 
                                </label>
                                <div className="d-flex inputf transcard">
                                  <img src={minus} alt="" className="img-fluid max-27"></img>
                                  <input type="text" className="form-control" name="year" placeholder="5" />
                                  <img src={plus} alt="" className="img-fluid max-27"></img>
                                </div>
                              </div>
                              <div className="col-md-4 text-center">
                                <label for="return" className="text-label font-weight-500 py-2 fs-14">
                                Monthly expenses for current lifestyle(₹/M)
                                </label>
                                <div className="d-flex inputf transcard">
                                  <img src={minus} alt="" className="img-fluid max-27"></img>
                                  <input type="text" className="form-control" name="return" placeholder="7" />
                                  <img src={plus} alt="" className="img-fluid max-27"></img>
                                </div>
                              </div>
                            </div>
                            {/*second*/}
                            <div className="row" >
                              <div className="col-md-4 text-center">
                                <label for="m-saving" className="text-label font-weight-500 py-2 fs-14">
                                Expected inflation rate (% p.a)
                                </label>
                                <div className="d-flex inputf transcard">
                                  <img src={minus} alt="" className="img-fluid max-27"></img>
                                  <input type="text" className="form-control" name="m-saving" placeholder="200" />
                                  <img src={plus} alt="" className="img-fluid max-27"></img>
                                </div>
                              </div>
                              <div className="col-md-4 text-center">
                                <label for="year" className="text-label font-weight-500 py-2 fs-14">
                                Current saving per month (₹)
                                </label>
                                <div className="d-flex inputf transcard">
                                  <img src={minus} alt="" className="img-fluid max-27"></img>
                                  <input type="text" className="form-control" name="year" placeholder="5" />
                                  <img src={plus} alt="" className="img-fluid max-27"></img>
                                </div>
                              </div>
                              <div className="col-md-4 text-center">
                                <label for="return" className="text-label font-weight-500 py-2 fs-14">
                                Existing Corpus(₹)
                                </label>
                                <div className="d-flex inputf transcard">
                                  <img src={minus} alt="" className="img-fluid max-27"></img>
                                  <input type="text" className="form-control" name="return" placeholder="7" />
                                  <img src={plus} alt="" className="img-fluid max-27"></img>
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-4 text-center">
                                <label for="m-saving" className="text-label font-weight-500 py-2 fs-14">
                                Expected pre retirement returns (% p.a)
                                </label>
                                <div className="d-flex inputf transcard">
                                  <img src={minus} alt="" className="img-fluid max-27"></img>
                                  <input type="text" className="form-control" name="m-saving" placeholder="200" />
                                  <img src={plus} alt="" className="img-fluid max-27"></img>
                                </div>
                              </div>
                              <div className="col-md-4 text-center">
                                <label for="year" className="text-label font-weight-500 py-2 fs-14">
                                Expected post retirement returns (% p.a)
                                </label>
                                <div className="d-flex inputf transcard">
                                  <img src={minus} alt="" className="img-fluid max-27"></img>
                                  <input type="text" className="form-control" name="year" placeholder="5" />
                                  <img src={plus} alt="" className="img-fluid max-27"></img>
                                </div>
                              </div>
                              <div className="col-md-4 text-center">
                                <label for="return" className="text-label font-weight-500 py-2 fs-14">
                                Life Expectancy Post retirement(year)
                                </label>
                                <div className="d-flex inputf transcard">
                                  <img src={minus} alt="" className="img-fluid max-27"></img>
                                  <input type="text" className="form-control" name="return" placeholder="7" />
                                  <img src={plus} alt="" className="img-fluid max-27"></img>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-12  text-right pt-4 text-sm-center">
                              <button className="new-btn1" >Calculate</button>
                            </div>
                          </section>
                          <div className="row shadowc br-50 mx-3 p-lg-5">
                            <div className="col-md-12 result-title text-center pt-3">
                              <h3>Result</h3>
                            </div>
                            <div className="col-md-12 px-lg-5 pt-3">
                              <div className="row result-content text-center">
                                <div className="col-md-4">
                                  <div className="text-label font-weight-500 py-2 fs-14">
                                    Year to retirement (Years)
                                  </div>
                                  <div className="inputf transcard bg-light-red py-2">10000</div>
                                </div>
                                <div className="col-md-4">
                                  <div className="text-label font-weight-500 py-2 fs-14">
                                    Amount Required P.M.-Post <br/>Retirement (₹)
                                  </div>
                                  <div className="inputf transcard bg-light-red py-2">1000</div>
                                </div>
                                <div className="col-md-4">
                                  <div className="text-label font-weight-500 py-2 fs-14">
                                    Corpus to be Achived @ <br/>Retirement(₹)
                                  </div>
                                  <div className="inputf transcard bg-light-red py-2">10500</div>
                                </div>
                              </div>
                              <div className="row result-content text-center pt-3">
                                <div className="col-md-4">
                                  <div className="text-label font-weight-500 py-2 fs-14">
                                    Corpus you will accumulate with current savings per month(₹)
                                  </div>
                                  <div className="inputf transcard bg-light-red py-2">10000</div>
                                </div>
                                <div className="col-md-4">
                                  <div className="text-label font-weight-500 py-2 fs-14">
                                    Corpus yoy will accumulate with existing savings (₹)
                                  </div>
                                  <div className="inputf transcard bg-light-red py-2">100</div>
                                </div>
                                <div className="col-md-4">
                                  <div className="text-label font-weight-500 py-2 fs-14">
                                    Shortfall in amount(₹)
                                  </div>
                                  <div className="inputf transcard bg-light-red py-2">10500</div>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-12 px-lg-5 pt-3 pb-3">
                              <div className="col-md-12 result-title text-center py-3">
                                <h3>Plan of action required</h3>
                              </div>
                              <div className="result-content text-center">
                                <div className="text-label font-weight-500 py-2 fs-14">
                                  Extra savings per month required(₹)
                                </div>
                                <div className="inputf transcard bg-light-red py-2">10000</div>
                              </div>
                            </div>
                          </div>
                          <div className="row px-5 pt-3">
                            <div className="col-md-12 text-right">
                              <button className="btn-custom ">Invest Now</button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Footer */}
      <Footer />
      {/* End of Footer */}
    </div>
  </div>
</div>
</>
)
}
}
export default Retirement_Planning_Pro