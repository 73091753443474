import React, {component} from 'react';

class Header extends React.Component{
    render(){
        
        return(
        <>
            <style>
          {`
         
          `}
          </style>
       <h1>I am header</h1>
        </>
        )
    }
    
}
export default Header
