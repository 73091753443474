import React, { component } from 'react';
import Header from './header';
import Footer from './footer';
import Sidebar from './sidebar';
import { Helmet } from "react-helmet";
import Axios from 'axios';
import $, { data } from 'jquery';
import { DatePickerComponent } from "@syncfusion/ej2-react-calendars";
import { liveurl } from "../../utils/urls"
import LoadingOverlay from "react-loading-overlay";
import "./styles.css";
import styled, { css } from "styled-components";

const DarkBackground = styled.div`
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 999; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */

  ${props =>
        props.disappear &&
        css`
      display: block; /* show */
    `}
`;

class Transaction_Report extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            items: [],
            isLoaded: false,
            currentDate: '',
            loded: true,
            filterIVName: [],
            freshNameArray: [],
            wait: ''
        };
        this.onData = this.onData.bind();
    }
    getInvName = (inv) => {

        console.log("inv", inv);
        let filterIVNames = [];
        this.state.items.filter(name => {
            if (name.INVNAME.includes(inv)) {
                filterIVNames.push(name);
                this.setState({ filterIVName: filterIVNames })

            }
        })
        console.log("push data ", this.state.filterIVName)


    }
    componentDidMount() {
        this.setState({ loded: !this.state.loded })
        var d = new Date();
        let mon = d.getMonth() + 1;


        // ***** for current date show
        let stringMonth = mon.toString();
        let strDate = Intl.DateTimeFormat('en', { month: 'long' }).format(new Date(stringMonth));
        strDate = strDate.substr(0, 3);
        strDate = strDate + "-" + d.getFullYear();
        this.setState({ currentDate: strDate })
        console.log("mo", strDate);
        // *****  current date end

        const data = {
            month: "0" + mon,
            year: d.getFullYear(),
            pan: JSON.parse(localStorage.getItem("loginUserData")).pan_card,
            name: JSON.parse(localStorage.getItem("loginUserData")).name,
            // month: 12,
            // year: 2022,
            // pan: "AHNPG8965C",
            // name: "SUNIL KUMAR GUPTA"
        }
        console.log("response", JSON.stringify(data))
        $('input[name="time"]').val(data.year + '-' + data.month)
        Axios.post(liveurl + "/api/gettransactionuserwise", data)
            .then((response) => {
                if (response.data.data.length == 0) {
                    this.setState({ wait: "No Data Found", loded: true, items: [] })
                } else {
                    this.setState({ wait: "", isLoaded: true, items: response.data.data });
                    const uniquePan = [...new Set(response.data.data.map(q => q.PAN))];
                    this.setState({ isLoaded: true, uniquePan: uniquePan, loded: true });
                    console.log("items", this.state.items);
                    let freshName = this.state.items

                    freshName = freshName.filter(
                        (temp => a =>
                            (k => !temp[k] && (temp[k] = true))(a.INVNAME + '|' + a.INVNAME)
                        )(Object.create(null))
                    );
                    console.log("freshName", freshName);
                    this.setState({ freshNameArray: freshName })


                }
            })

    }

    onData = e => {
        var time = e.target.value;
        let mon = time.getMonth() + 1;
        this.setState({ loded: !this.state.loded })

        if (time) {
            const data = {
                month: "0" + mon,
                year: time.getFullYear(),
                pan: JSON.parse(localStorage.getItem("loginUserData")).pan_card,
                name: JSON.parse(localStorage.getItem("loginUserData")).name,
                // month: 12,
                // year: 2022,
                // pan: "AHNPG8965C",
                // name: "SUNIL KUMAR GUPTA"
            }

            this.setState({ isLoaded: true, uniquePan: '' });
            Axios.post(liveurl + "/api/gettransactionuserwise", data)
                .then((response) => {
                    if (response.data.length == 0) {
                        this.setState({ wait: "No Data Found", loded: true, items: [] })
                    } else {
                        this.setState({ wait: "", isLoaded: true, items: response.data.data, loded: true });
                        const uniquePan = [...new Set(response.data.data.map(q => q.PAN))];
                        this.setState({ isLoaded: true, uniquePan: uniquePan });
                        console.log("items", response.data.data);
                    }
                })
        } else {
            alert("Please Select Month and Year")
        }

    }

    render() {
        let data = []; let invName = []; let temp; let inname;

        if (this.state.uniquePan) {
            for (var i = 0; i < this.state.uniquePan.length; i++) {
                // console.log(this.state.uniquePan[i])
                let itemSrNo = 1;
                this.state.items.map(item => {
                    if (this.state.uniquePan[i] == item.PAN) {
                        if (itemSrNo == 1) {
                            inname = <tr className='bg-gray'>
                                <td scope="row" className="fs-16 red min-w-11em">{item.INVNAME.toUpperCase()}</td>
                                <td scope="row" className="fs-16 red min-w-11em"></td>
                                <td scope="row" className="fs-16 red min-w-11em"></td>
                                <td scope="row" className="fs-16 red min-w-11em"></td>
                                <td scope="row" className="fs-16 red min-w-11em"></td>
                                <td scope="row" className="fs-16 red min-w-11em"></td>
                            </tr>
                            data.push(inname);
                        }

                        if (item.AMOUNT != 0) {
                            itemSrNo++
                            temp = (<tr>
                                {/* <th scope="row">{itemSrNo++}</th> */}
                                <td>{item.TRADDATE}</td>
                                <td>{item.FOLIO_NO}</td>
                                <td>{item.SCHEME}</td>
                                <td>&#8377; {item.AMOUNT}</td>
                                <td>{item.TRXN_NATURE}</td>
                            </tr>)
                            data.push(temp);

                        }
                    }
                })
            }
        } else {
            inname = '';
            temp = '';
            data.push(temp);
        }

        return (
            <>
                <Helmet>
                    <title>Prodigy Pro - Transaction Report</title>
                </Helmet>
                <style>
                    {`
              .mt-input{
              margin-top:3.5%;
              }
              .mt-btn{
              margin-top:12%;
              }
              .filter-card-body{
              padding-top: .5rem;
              padding-bottom: .5rem;
              padding-left: 1.25rem;
              padding-right: 1.25rem;
              }
              th{
              white-space: nowrap;
              }
              `}
                </style>
                {/* Page Wrapper */}
                <div id="wrapper">
                    {/* Content Wrapper */}
                    <div id="content-wrapper" className="d-flex flex-column">
                        {/* Main Content */}
                        <div id="content">
                            {/* Topbar */}
                            <Header />
                            {/* End of Topbar */}
                            {/* Begin Page Content */}
                            <div className="container-fluid">
                                {/* Page Heading */}
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item"><a href="home">Home</a></li>
                                        <li className="breadcrumb-item active" aria-current="page">Transaction Report</li>
                                    </ol>
                                </nav>
                                <div className="row justify-content-center">
                                    <DarkBackground disappear={!this.state.loded}>

                                        <LoadingOverlay
                                            active={true}
                                            spinner={true}

                                        >
                                        </LoadingOverlay>
                                    </DarkBackground>
                                    {/* Area Chart */}
                                    <div className="col-xl-11 col-lg-11 py-5">
                                        <div className=" shadow mb-4">
                                            <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                                                <div className="col-lg-4 col-md-4 col-sm-6 "></div>
                                                <div className="col-lg-4 col-md-4 col-sm-6 ">
                                                    <DatePickerComponent format='MMM-yyyy' className="form-control datep" value={this.state.currentDate} onChange={this.onData} placeholder='MM-YYYY' start='Year' depth='Year' />
                                                </div>
                                                <div className="col-lg-4 col-md-4 col-sm-6 "></div>
                                            </div>
                                            {/* Card Body */}
                                            <div className="card-body">
                                                <div className="table-responsive">
                                                    <table className="table text-center" id="cleanTable">
                                                        <thead>
                                                            {this.state.items.length == 0  ? null: <tr className='red'>
                                                                    <th scope="col">Date</th>
                                                                    <th scope="col">Folio No.</th>
                                                                    <th scope="col">Scheme</th>
                                                                    <th scope="col">Amount</th>
                                                                    <th scope="col">Trxn. Type</th>
                                                                </tr>}
                                                               
                                                        </thead>
                                                        {/* {this.state.freshNameArray.map((datas) => { */}

                                                        <tbody className='text-black'>
                                                            {data}
                                                        </tbody>
                                                        {/* })}  */}
                                                    </table>
                                                </div>
                                                <h3 style={{textAlign: "center"}}>{this.state.wait}</h3>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* End of Main Content */}
                        {/* Footer */}
                        <Footer />
                        {/* End of Footer */}
                    </div>
                    {/* End of Content Wrapper */}
                </div>
                {/* End of Page Wrapper */}
            </>
        )
    }
}
export default Transaction_Report
