import React from 'react';
import Header from './header';
import Footer from './footer';
import $ from "jquery";
import sunderam from "../../assets/images/icons/New folder (2)/Sundaram_Mutual_Fund.png";
import sbi from "../../assets/images/icons/New folder (2)/sbi mutual fund.png";
import idfc from "../../assets/images/icons/New folder (2)/IDFC.png";
import star from "../../assets/images/icons/New folder (2)/star.png";
import { FaTrash } from "react-icons/fa";
import Select from 'react-select';
import success from "../../assets/images/icons/New folder (2)/successfully.png";
import Nav_Chart from './nav-chart';
class Simply_Sip extends React.Component {
hideScheme() {
$("#prod_div").css({ "display": "none" });
$("#single_div").css({ "display": "block" });
}
back() {
$("#single_div").css({ "display": "none" });
$("#prod_div").css({ "display": "block" });
}
hides1() {
$("#carts").css({ "display": "none" });
$("#formf").css({ "display": "block" });
}
hides4() {
$("#formf").css({ "display": "none" });
$("#formhides").css({ "display": "block" });
}
hides2() {
$("#formhides").css({ "display": "none" });
$("#confirms").css({ "display": "block" });
}
hides3() {
$("#confirms").css({ "display": "none" });
$("#success").css({ "display": "block" });
}
render() {
const folio = [
{ value: '1544545454', label: '1544545454' },
{ value: '55588888', label: '55588888' },
];
const date = [
{ value: '1', label: '1' },
{ value: '2', label: '2' },
{ value: '3', label: '3' },
{ value: '4', label: '4' },
];
const profile = [
{ value: 'select', label: 'select' },
];
const selectperiod = [
{ value: 'select', label: 'select' },
];
const years = [
{ value: '1 - 2 Years', label: '1 - 2 Years' },
{ value: '2 - 3 Years', label: '2 - 3 Years' },
{ value: '3 - 4 Years', label: '3 - 4 Years' }
];
return (
<>
<style>
  {`
  #single_div,#formhides, #confirms,#purchases,#formf,#success
  {
  display:none;
  }
  .shadowcart
  {
  background: #FFFFFF;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2);
  border-radius: 15px;
  }
  .shadowcart a
  {
  color:#000;
  }
  a {
  color: #000;
  }
  `}
</style>
{/* Page Wrapper */}
<div id="wrapper">
  {/* 
  <div id="overlay" >
    <div className="spinner"></div>
    <br />Loading...
  </div>
  */}
  {/* Content Wrapper */}
  <div id="content-wrapper" className="d-flex flex-column">
    {/* Main Content */}
    <div id="content">
      {/* Topbar */}
      <Header />
      {/* End of Topbar */}
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb">
          <li className="breadcrumb-item"><a href="home">Home</a></li>
          <li className="breadcrumb-item active" aria-current="page">Simply Sip</li>
        </ol>
      </nav>
      <div id="prod_div">
        <div className="container-fluid px-lg-5 pb-5 simply-sip">
          <div className="row">
            <div className="col-xl-10 col-lg-10 offset-md-1">
              <div className="col-md-7 col-lg-5 pb-4 pl-4">
                <label className='text-label'>Select Anchoring (Investment Horizon)</label>
                <Select className='single-drop py-1' options={years} />
              </div>
              <div className="card shadow-custom bg-light-red text-left">
                <div className="card-body">
                  <div className="col-12 table-responsive">
                    <table class="table">
                      <thead>
                        <tr>
                          <th scope="col">Recommended Schemes</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td><img src={sbi} alt="" className='img-fluid' /></td>
                          <td className='pt-4 min-w-35em'><a href='javascript:void(0);' onClick={this.hideScheme}>SBI Long Term Equity Fund-Regular
                            Plan-Growth  </a>
                          </td>
                        </tr>
                        <tr>
                          <td><img src={sunderam} alt="" className='img-fluid' /></td>
                          <td className='pt-4'><a href='!#'>Sundaram Tax Savings Fund (Formerly Principal Tax Savings Fund)-
                            Regular Growth </a> 
                          </td>
                        </tr>
                        <tr>
                          <td><img src={idfc} alt="" className='img-fluid' /></td>
                          <td className='pt-4'><a href='!#'>IDFC Tax Advantage (ELSS)
                            Fund-Growth-Regular Plan   </a>
                          </td>
                        </tr>
                        <tr>
                          <td><img src={sunderam} alt="" className='img-fluid' /></td>
                          <td className='pt-4'> <a href='!#'>Sundaram Tax Savings Fund (Formerly Principal Tax Savings Fund)-
                            Regular Growth  </a>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className="col-12 mt-4 mb-3 text-right">
                    <a className="btn-custom text-color" data-toggle="modal" data-target="#sip">Continue</a>
                  </div>
                </div>
              </div>
              {/* disclaimer */}
              <div class="text-center pt-4 mb-0" role="alert">
                <p className="text-disclaimer  fs-13 text-black">*Mutual Fund investments are subject to market risks, please read the scheme related documents carefully before investing.</p>
              </div>
            </div>
          </div>
        </div>
        {/* modal */}
        <div className="modal fade" id="sip" tabIndex={-1} role="dialog" aria-labelledby="sipTitle" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content bg-light-red">
              <div id='carts'>
                <div className="modal-header">
                  <h5 className="modal-title text-dark" id="sipTitle">My Cart</h5>
                  <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">×</span>
                  </button>
                </div>
                <div className="modal-body">
                  <h6 className='text-black fs-16'>Schemes Name</h6>
                  <div class="col-md-12">
                    <div className=' row shadowcart mb-2 p-3'>
                      <div className='col-md-2'><img src={idfc} alt="" className='img-fluid' /></div>
                      <div className='col-md-9'><a href='!#'>IDFC Tax Advantage (ELSS)
                        Fund-Growth-Regular Plan  </a> 
                      </div>
                      <div className='col-md-1 pt-2'>
                        <FaTrash />
                      </div>
                    </div>
                    <div className=' row shadowcart mb-2 p-3'>
                      <div className='col-md-2'><img src={sunderam} alt="" className='img-fluid' /></div>
                      <div className='col-md-9'><a href='!#'>Sundaram Tax Savings Fund (Formerly Principal Tax Savings Fund)-
                        Regular Growth </a> 
                      </div>
                      <div className='col-md-1 pt-3'>
                        <FaTrash />
                      </div>
                    </div>
                    <div className=' row shadowcart mb-2 p-3'>
                      <div className='col-md-2'><img src={sunderam} alt="" className='img-fluid' /></div>
                      <div className='col-md-9'><a href='!#'>Sundaram Tax Savings Fund (Formerly Principal Tax Savings Fund)-
                        Regular Growth </a> 
                      </div>
                      <div className='col-md-1 pt-3'>
                        <FaTrash />
                      </div>
                    </div>
                    <div className=' row shadowcart mb-2 p-3'>
                      <div className='col-md-2'><img src={sbi} alt="" className='img-fluid' /></div>
                      <div className='col-md-9'><a href='!#'>SBI Long Term Equity Fund-Regular
                        Plan-Growth  </a>
                      </div>
                      <div className='col-md-1 pt-2'>
                        <FaTrash />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="modal-footer border-0">
                  <a type="button" className="btn btn-danger shadow-sm" href="javascript:void(0);" onClick={this.hides1}>Continue</a>
                </div>
              </div>
              {/* form  */}
              <div id="formf">
                <div className="modal-header">
                  <h5 className="modal-title text-black" id="sipTitle">SIP</h5>
                  <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">×</span>
                  </button>
                </div>
                <div className="modal-body ">
                  <form>
                    <div className="col mb-3 ">
                      <label htmlFor="Profile" className='text-label' >Select Profile <span className='text-danger'>*</span></label>
                      <Select className='bg-c' options={profile} />
                    </div>
                    <div className="col mb-3 ">
                      <label htmlFor="Profile" className='text-label' >Select Mandate <span className='text-danger'>*</span></label>
                      <Select className='bg-c' options={profile} />
                    </div>
                    <a href="/prodigypro/dashboard/bank-and-mandate" className="red fs-13">Create Mandate</a>
                  </form>
                </div>
                <div className="modal-footer border-0">
                  <a type="button" className="btn btn-danger shadow-sm" href="javascript:void(0);" onClick={this.hides4}>Continue</a>
                </div>
              </div>
              <div id='formhides'>
                <div className="modal-header">
                  <h5 className="modal-title text-black" id="sipTitle">SIP Cart</h5>
                  <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">×</span>
                  </button>
                </div>
                <div className="modal-body ">
                  <div className='cartitemwith'>
                    <div className='row p-4'>
                      <div className='col-md-12  red '>SBI Long Term Equity Fund-Regular
                        Plan-Growth 
                      </div>
                    </div>
                    <div className="col bg-white py-3 px-4">
                      <label htmlFor="Profile" className='fs-14' >Select Folio <span className='text-danger'>*</span></label>
                      <Select className='border-pop' options={folio} />
                    </div>
                    <div className="col  bg-white pb-1 px-4">
                      <label htmlFor="amount" className='fs-14' >Enter Amount <span className='text-danger'>*</span></label>
                      <input type="text" className="form-control amount-b" name="amount" placeholder='Enter Amount' />
                    </div>
                    <div className="col bg-white py-3 px-4">
                      <label htmlFor="date" className='fs-14' >Select Date <span className='text-danger'>*</span></label>
                      <Select className='border-pop' options={date} />
                    </div>
                    <div className="col bg-white py-3 px-4">
                      <label htmlFor="date" className='fs-14' >Select Period <span className='text-danger'>*</span></label>
                      <Select className='border-pop' options={selectperiod} />
                    </div>
                    <div className='col mb-2 lastin'>
                      <input className="input-text p-2" id="perpetual" type="checkbox" name="perpetual" value="Y" defaultChecked />
                      <input type="hidden" id="perpetual_val" name="perpetual_val" />
                      <label htmlFor="perpetual" className="ml-2">
                        Perpetual  
                        <spna className="text-danger">*</spna>
                      </label>
                    </div>
                  </div>
                </div>
                <div className="modal-footer border-0">
                  <a type="button" className="btn btn-danger shadow-sm" href="javascript:void(0);" onClick={this.hides2}>Continue</a>
                </div>
              </div>
              <div id="confirms">
                <div className="modal-header">
                  <h5 className="modal-title text-black" id="sipTitle">SIP Confirmation Purchase</h5>
                  <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">×</span>
                  </button>
                </div>
                <div className="modal-body ">
                  <div className="col mb-3 border-bottom">
                    <h6 className='red'>Shivam Shrivastav</h6>
                    <p>Mode of Holding :  Single <a href="#" className='p-4 ml-5'>Edit</a></p>
                  </div>
                  <p className='red'>Axis Long Term Equity Fund-Regular-Growth</p>
                  <table className='mx-auto'>
                    <tr className='text-center'>
                      <td className='pr-4'>Folio </td>
                      :
                      <td className='pl-4'>124564</td>
                    </tr>
                    <tr className='text-center'>
                      <td className='pr-4'>Amount  </td>
                      :
                      <td className='pl-4'>50,000</td>
                    </tr>
                    <tr className='text-center'>
                      <td className='pr-4'>SIP From  </td>
                      :
                      <td className='pl-4'>11-sep-2022</td>
                    </tr>
                    <tr className='text-center'>
                      <td className='pr-4'>SIP To </td>
                      :
                      <td className='pl-4'>31-dec-2099</td>
                    </tr>
                    <tr className='text-center'>
                      <td className='pr-4'>SIP Date </td>
                      :
                      <td className='pl-4'>11</td>
                    </tr>
                  </table>
                  <p className='text-center pt-5'><b>Total  :  55,55882</b></p>
                </div>
                <div className="modal-footer border-0">
                  <a type="button" className="btn btn-danger shadow-sm" href="javascript:void(0);" onClick={this.hides3}>Order</a>
                </div>
              </div>
              {/* success */}
              <section className="success-trans text-center px-3 pt-2" id='success'>
                <div className="row">
                  <div className="col-md-12">
                    <img className="img-fluid" src={success} alt="" />
                    <h5 className="success-c font-weight-500">Successful Transaction with
                      Following Details
                    </h5>
                    <div className="row px-2">
                      <div className="col-md-4 col-sm-4 text-left text-black">Unique Number </div>
                      <div className="col-md-1 col-sm-1">:</div>
                      <div className="col-md-7 col-sm-7 text-left">12345678</div>
                    </div>
                    <div className="row px-2">
                      <div className="col-md-4  col-sm-4 text-left text-black">Trxn Number </div>
                      <div className="col-md-1 col-sm-1">:</div>
                      <div className="col-md-7 col-sm-7 text-left">12346195</div>
                    </div>
                    <div className="row px-2">
                      <div className="col-md-4  col-sm-4 text-left text-black">Fund </div>
                      <div className="col-md-1 col-sm-1">:</div>
                      <div className="col-md-7 col-sm-7 text-left">Axis Mutual Fund</div>
                    </div>
                    <div className="row px-2">
                      <div className="col-md-4  col-sm-4 text-left text-black">Folio Number </div>
                      <div className="col-md-1 col-sm-1">:</div>
                      <div className="col-md-7  col-sm-7 text-left">12345678</div>
                    </div>
                    <div className="row px-2">
                      <div className="col-md-4  col-sm-4 text-left text-black">Amount   </div>
                      <div className="col-md-1 col-sm-1">:</div>
                      <div className="col-md-7 col-sm-7 text-left">1,000</div>
                    </div>
                    <div className="row px-2">
                      <div className="col-md-4  col-sm-4 text-left text-black">Status   </div>
                      <div className="col-md-1 col-sm-1">:</div>
                      <div className="col-md-7  col-sm-7 text-left">Successful</div>
                    </div>
                    <div className="row px-2">
                      <div className="col-md-4  col-sm-4 text-left text-black">Scheme Name </div>
                      <div className="col-md-1 col-sm-1">:</div>
                      <div className="col-md-7 col-sm-7 text-left">Axis Arbitrage Fund - Regular Growth</div>
                    </div>
                    <hr />
                    <div className="text-center">
                      <p className="text-black fs-13">
                        <span className="red text-left font-weight-500 fs-16">Note:</span>  Authorization link has been sent on your registered mail id and mobile number.The Link shall remain active for the next 48 hours.Kindly authorize.
                      </p>
                    </div>
                  </div>
                </div>
              </section>
              {/* successful transaction  end */}
            </div>
          </div>
        </div>
        {/* modal end */}
      </div>
      {/* schemes performance */}
      <div className="col-md-10 offset-md-1 card shadow-custom my-5 text-left" id="single_div">
        <div className="card-body" >
          <div className='col-md-12 py-3 table-responsive'>
            <table className='w-100'>
              <tr>
                <td className='px-4'><img src={sbi} alt="" className='img-fluid min-w-3em' /></td>
                <td className='px-4 min-w-35em'>SBI Long Term Equity Fund-Regular
                  Plan-Growth    
                </td>
                <td className='px-4 ' style={{ color: "#2E3192" }}>
                <h3>25%</h3>
                </td>
                <td className='px-4'>3Y annualised</td>
              </tr>
            </table>
          </div>
          <div className='col-md-12'>
            <div className="pt-4">
              <Nav_Chart />
            </div>
          </div>
          <div className="col-md-8 offset-md-2">
            <div className='nav d-flex justify-content-between nav-h'>
              <div className=''>
                <a href="#">1M</a>
              </div>
              <div className=''>
                <a href="#">6M</a>
              </div>
              <div className=''>
                <a href="#">1Y</a>
              </div>
              <div className=''>
                <a href="#">3Y</a>
              </div>
              <div className=''>
                <a href="#">5Y</a>
              </div>
              <div className=''>
                <a href="#">7Y</a>
              </div>
              <div className=''>
                <a href="#">All</a>
              </div>
            </div>
          </div>
          <div className="col-md-10 offset-md-1 pl-5 py-4 table-responsive">
            <table>
              <tr>
                <td className='px-lg-5 text-label  min-w-8em'>NAV: 12 Aug-2022</td>
                <td className='px-5 text-label'>Rating
                </td>
                <td className='px-lg-5 text-label  min-w-8em'>Min. SIP amount </td>
                <td className='px-lg-5 text-label  min-w-8em'>Fund Size</td>
              </tr>
              <tr>
                <td className='px-5 pt-3'>₹134.56</td>
                <td className='px-5 pt-3'>4 <img src={star} alt className='pb-1' /> </td>
                <td className='px-5 pt-3'>₹1000</td>
                <td className='px-lg-5 pt-3 min-w-8em'>₹1,911 Cr </td>
              </tr>
            </table>
          </div>
          <div className="col-md-12 py-5">
            <button className='btn-custom float-left' onClick={this.back}>Back</button>
            <button className='btn-custom float-right'>Add to Cart</button>
          </div>
        </div>
      </div>
    </div>
    {/* Footer */}
    <Footer />
    {/* End of Footer */}
  </div>
  {/* End of Content Wrapper */}
</div>
{/* End of Page Wrapper */}
</>
)
}
}
export default Simply_Sip