import React, { component } from 'react';
import Header from './header';
import Footer from './footer';
import Sidebar from './sidebar';
import { Helmet } from "react-helmet";
import Axios from 'axios';
import $ from 'jquery';
import { DatePickerComponent } from "@syncfusion/ej2-react-calendars";
// for loader**************
import LoadingOverlay from "react-loading-overlay";
import "./styles.css";
import styled, { css } from "styled-components";

var api_url_wms = "https://wms.bfccapital.com";

const DarkBackground = styled.div`
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 999; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */

  ${props =>
        props.disappear &&
        css`
      display: block; /* show */
    `}
`;




class SSS_Report extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            items: [],
            // isLoaded: false,
            loded: true,
            err: '',
            filterIVName: [],
            invName: '',
            totalAmounts: '',
            toggleTriggred: false,
            show: '',
            clas: '',
            total: 0,
            filterFreshIv: [],
            subTotal: 0,
            arr: [],
            manageStatus: ""


        };

        this.onData = this.onData.bind();
        this.changeType = this.changeType.bind();
    }
    componentDidMount() {
        var d = new Date();
        this.setState({ loded: !this.state.loded, maxDate: d })

        let mon = d.getMonth() + 1;

        let stringMonth = mon.toString();
        let strDate = Intl.DateTimeFormat('en', { month: 'long' }).format(new Date(stringMonth));
        strDate = strDate.substr(0, 3);
        strDate = strDate + "-" + d.getFullYear();
        this.setState({ currentDate: strDate })
        // console.log("mo", strDate);
        // this.setState({ currentDate: strDate });
        var transType = $("input[name='trans_type']:checked").val();
        const data = {
            month: "0" + mon,
            year: d.getFullYear(),
            pan: JSON.parse(localStorage.getItem("loginUserData")).pan_card,
            trans_type: transType,
        }



        Axios.post(api_url_wms + "/api/getsipstpuserwise", data)
            .then((response) => {
                if (response.data.status == 400) {
                    this.setState({ loded: true, items: [], total: 0, filterIVName: [], filterFreshIv: [], arr: [] });
                } else {
                    if (response.data.data.length > 0) {
                        this.setState({ items: response.data.data });
                        const uniquePan = [...new Set(response.data.data.map(q => q.PAN))];
                        this.setState({ uniquePan: uniquePan });
                        // for total amount
                        var totalePrice = 0;
                        var totalePriceArray = [];
                        let oldName = [];
                        oldName = this.state.items
                        oldName = oldName.filter(
                            (temp => a =>
                                (k => !temp[k] && (temp[k] = true))(a.INVNAME + '|' + a.INVNAME)
                            )(Object.create(null))
                        );
                        oldName.map((data) => {
                            console.log("data ", data);
                            totalePrice += data.AMOUNT;
                            totalePriceArray.push(totalePrice);

                        })
                        // console.log("aaaaaaaaaaa", totalePriceArray);
                        //******************************************* */
                        let arr = [];
                        oldName.forEach(old => {
                            if (this.state.items.find(item => item.INVNAME == old.INVNAME)) {
                                arr.push({ name: old.INVNAME, subtotal: 0 })
                                // console.log("true found");
                            }
                        })
                        this.getInvName(arr[0].name, false, true)

                        // console.log("arr ", arr);
                        this.state.items.map(item => {
                            arr[arr.findIndex(value => value.name == item.INVNAME)].subtotal += item.AMOUNT;
                        })

                        // console.log("after modification arr ", arr);

                        //********************************************************** */
                        var totalePrice = 0;
                        this.state.items.map((data) => {
                            totalePrice += data.AMOUNT;
                        })
                        this.setState({ total: totalePrice, filterFreshIv: oldName, arr: arr, loded: true })
                    } else {
                        this.setState({ arr: [], loded: true })
                        // this.setState({ isLoaded: true, uniquePan: '' });
                    }
                }

            })
            .catch((err) => {
                $(".waitdata").css({ "display": "table-row" });
            })

    }


    getInvName = (inv, idx) => {
        let totalAmount = 0;
        let filterIVNames = [];
        this.state.items.filter(name => {
            if (name.INVNAME.includes(inv)) {
                totalAmount = totalAmount + name.AMOUNT;
                filterIVNames.push(name);
                this.setState({ filterIVName: filterIVNames, totalAmounts: totalAmount })
            }
        })
    }
    onData = e => {
        this.setState({ loded: !this.state.loded })
        var d = e.target.value;
        let mon = d.getMonth() + 1;
        var transType = $("input[name='trans_type']:checked").val();
        // console.log("after modification arr ", this.state.arr);

        if (d) {
            const data = {
                month: "0" + mon,
                year: d.getFullYear(),
                pan: JSON.parse(localStorage.getItem("loginUserData")).pan_card,
                trans_type: transType,
            }

            this.setState({ isLoaded: true, uniquePan: '' });
            Axios.post(api_url_wms + "/api/getsipstpuserwise", data)
                .then((response) => {
                    // console.log("after modification arr ", response);
                    if (response.data.status == 400) {

                        this.setState({ loded: true, items: [], total: 0, filterIVName: [], filterFreshIv: [], arr: [] });
                        // this.setState({ arr: [] })


                    } else {
                        if (response.data.data.length > 0) {
                            this.setState({ items: response.data.data });
                            const uniquePan = [...new Set(response.data.data.map(q => q.PAN))];
                            this.setState({ uniquePan: uniquePan, loded: true });
                            // console.log("isLoaded", response.data.data);

                            var totalePrice = 0;
                            this.state.items.map((data) => {
                                totalePrice += data.AMOUNT;
                            })

                            let oldName = [];
                            oldName = this.state.items

                            oldName = oldName.filter(
                                (temp => a =>
                                    (k => !temp[k] && (temp[k] = true))(a.INVNAME + '|' + a.INVNAME)
                                )(Object.create(null))
                            );
                            let arr = [];
                            oldName.forEach(old => {
                                if (this.state.items.find(item => item.INVNAME == old.INVNAME)) {
                                    arr.push({ name: old.INVNAME, subtotal: 0 })

                                }
                            })
                            this.getInvName(arr[0].name, false, true)

                            this.state.items.map(item => {
                                arr[arr.findIndex(value => value.name == item.INVNAME)].subtotal += item.AMOUNT;
                            })
                            this.setState({ total: totalePrice, filterFreshIv: oldName, arr: arr })
                        } else {
                            this.setState({ arr: [], loded: true })
                            // this.setState({ isLoaded: true, uniquePan: '' });
                        }

                    }
                })
                .catch((err) => {
                    $(".waitdata").css({ "display": "table-row" });

                })
        } else {
            alert("Please Select Month and Year")
        }

    }

    changeType = e => {
        this.setState({ loded: !this.state.loded })
        var d = 1 + '-' + $(".form-control.datep.e-control.e-datepicker.e-lib.e-input.e-keyboard").val();
        let date = new Date(d);

        let mon = date.getMonth() + 1;
        var transType = e.target.value;


        if (date) {
            const data = {
                month: "0" + mon,
                year: date.getFullYear(),
                pan: JSON.parse(localStorage.getItem("loginUserData")).pan_card,
                trans_type: transType,
            }

            this.setState({ isLoaded: true, uniquePan: '' });
            Axios.post(api_url_wms + "/api/getsipstpuserwise", data)
                .then((response) => {
                    this.setState({ manageStatus: response.data.status })
                    if (response.data.status == 400) {
                        this.setState({ loded: true, items: [], total: 0, filterIVName: [], filterFreshIv: [], arr: [] });

                    } else {
                        if (response.data.data.length > 0) {
                            $("#wait").html("")
                            $(".tableData").show()
                            this.setState({ items: response.data.data });
                            const uniquePan = [...new Set(response.data.data.map(q => q.PAN))];
                            this.setState({ uniquePan: uniquePan, loded: true });
                            // console.log("isLoaded", response.data.data);

                            var totalePrice = 0;
                            this.state.items.map((data) => {
                                totalePrice += data.AMOUNT;
                            })

                            let oldName = [];
                            oldName = this.state.items

                            oldName = oldName.filter(
                                (temp => a =>
                                    (k => !temp[k] && (temp[k] = true))(a.INVNAME + '|' + a.INVNAME)
                                )(Object.create(null))
                            );
                            let arr = [];
                            oldName.forEach(old => {
                                if (this.state.items.find(item => item.INVNAME == old.INVNAME)) {
                                    arr.push({ name: old.INVNAME, subtotal: 0 })

                                }
                            })
                            this.getInvName(arr[0].name, false, true)

                            this.state.items.map(item => {
                                arr[arr.findIndex(value => value.name == item.INVNAME)].subtotal += item.AMOUNT;
                            })

                            // console.log("after modification arr ", arr);
                            this.setState({ total: totalePrice, filterFreshIv: oldName, arr: arr })
                        } else {
                            this.setState({ arr: [], loded: true })
                            // this.setState({ isLoaded: true, uniquePan: '' });
                        }
                    }



                })
                .catch((err) => {
                    $(".waitdata").css({ "display": "table-row" });
                })
        } else {
            alert("Please Select Month and Year")
        }

    }

    render() {
        // let data = []; let invName = []; let temp; let inname;

        // if (this.state.uniquePan) {
        //     for (var i = 0; i < this.state.uniquePan.length; i++) {
        //         let itemSrNo = 1;
        //         this.state.items.map(item => {
        //             if (this.state.uniquePan[i] == item.PAN) {
        //                 // if (itemSrNo == 1) {
        //                 //     inname = <tr style={{ "background-color": "rgb(0 0 0 / 3%)" }}>
        //                 //         <td colSpan="6"><b>{item.INVNAME}</b></td>
        //                 //     </tr>
        //                 //     data.push(inname);
        //                 // }

        //                 temp = (<tr>
        //                     <th scope="row">{itemSrNo++}</th>
        //                     <td>{item.TRADDATE}</td>
        //                     <td>{item.FOLIO_NO}</td>
        //                     <td>{item.SCHEME}</td>
        //                     <td>&#8377; {item.AMOUNT}</td>
        //                     <td>{item.TRXN_NATUR}</td>
        //                 </tr>)
        //                 data.push(temp);
        //             }
        //         })
        //     }
        //     console.log("dataaa", data);
        // } else {
        //     inname = '';
        //     temp = '';
        //     data.push(temp);
        // }


        return (
            <>
                <Helmet>
                    <title>Prodigy Pro - My SIP/STP/SWP Report</title>
                </Helmet>
                <style>
                    {`
              .mt-input{
              margin-top:3.5%;
              }
              .mt-btn{
              margin-top:12%;
              }
              .filter-card-body{
              padding-top: .5rem;
              padding-bottom: .5rem;
              padding-left: 1.25rem;
              padding-right: 1.25rem;
              }
              th{
              white-space: nowrap;
              }
              .select-month{
              margin-bottom: 0px !important;
              }
              .waitdata{
              display: none;
              }
              body {
              padding-left: 0;
              padding-right: 0;
              }
              .table
              {
              margin-bottom:0rem;
              }
              `}
                </style>
                {/* Page Wrapper */}
                <div id="wrapper">
                    {/* Sidebar */}
                    {/* 
              <Sidebar mainTab="reports"  innertab="sipstpswp-report"/>
              */}
                    {/* End of Sidebar */}
                    {/* Content Wrapper */}
                    <div id="content-wrapper" className="d-flex flex-column">
                        {/* Main Content */}
                        <div id="content">
                            {/* Topbar */}
                            <Header />
                            {/* End of Topbar */}
                            {/* Begin Page Content */}
                            <div className="container-fluid report">
                                {/* Page Heading */}
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item"><a href="home">Home</a></li>
                                        <li className="breadcrumb-item active" aria-current="page">My SIP / STP / SWP Report</li>
                                    </ol>
                                </nav>
                                <DarkBackground disappear={!this.state.loded}>

                                    <LoadingOverlay
                                        active={true}
                                        spinner={true}

                                    >
                                    </LoadingOverlay>
                                </DarkBackground>

                                <div className="row justify-content-center">
                                    {/* Area Chart */}
                                    <div className="col-xl-10 col-lg-10  py-5">
                                        <div className=" shadow-custom mb-4 m-sm-auto">
                                            <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                                                {/* 
                            <h6 className="m-0 font-weight-bold text-danger">All Data</h6>
                            */}
                                                <div className="col-lg-2 col-md-2 col-sm-6 "></div>
                                                <div className="col-lg-4 col-md-4 col-sm-6 ">
                                                    <DatePickerComponent format='MMM-yyyy' className="form-control datep" value={this.state.currentDate} placeholder='MM-YYYY' onChange={this.onData} start='Year' depth='Year' max={this.state.maxDate} />
                                                </div>



                                                <div className="col-lg-4 col-md-4 col-sm-6 ">

                                                    <div className="form-check form-check-inline">
                                                        <input className="form-check-input" type="radio" name="trans_type" id="sip" value="SIP" defaultChecked onChange={this.changeType} />
                                                        <label className="form-check-label" for="sip">SIP</label>
                                                    </div>
                                                    <div className="form-check form-check-inline">
                                                        <input className="form-check-input" type="radio" name="trans_type" id="stp" value="STP In" onChange={this.changeType} />
                                                        <label className="form-check-label" for="stp">STP</label>
                                                    </div>

                                                    <div className="form-check form-check-inline">
                                                        <input className="form-check-input" type="radio" name="trans_type" id="swp" value="SWP" onChange={this.changeType} />
                                                        <label className="form-check-label" for="swp">SWP</label>
                                                    </div>

                                                </div>



                                                <div className="col-lg-2 col-md-2 col-sm-6 "></div>
                                            </div>
                                            {/* Card Body */}
                                            <div className="card-body">
                                                {(this.state.arr.length === 0) ? <h3 id="text">Data Not Found</h3> : (
                                                    <div className="accordion px-lg-3 tableData" id="accordionExample">

                                                        <div className="bg-gray border-bottom border-top py-2 mb-3 ">
                                                            <div className='row red  font-weight-500'>
                                                                <div className='col-md-9 col-sm-7 text-left pl-lg-5'>Name</div>
                                                                <div className='col-md-3 col-sm-5 pl-sm-7em'>Amount</div>
                                                            </div>
                                                        </div>
                                                        {this.state.arr.map((data, idx) => {
                                                            return <div className="card mb-3 border-bottom ">
                                                                <div className="card-head border" id={idx} >
                                                                    <h2 className="mb-0" data-toggle="collapse" data-target={"#collapseOne_" + idx} aria-expanded="false" aria-controls="collapseTwo" >
                                                                        <div className='row fs-16 py-2 red' onClick={() => this.getInvName(data.name, idx)}>
                                                                            <div className='col-md-9  col-sm-7 text-left pl-lg-5'>{data.name.toUpperCase()}</div>
                                                                            <div className='col-md-3 col-sm-5'>&#8377;{(data.subtotal).toFixed(2)}</div>
                                                                        </div>
                                                                    </h2>
                                                                </div>

                                                                <div id={"collapseOne_" + idx} className={(idx === 0) ? ("collapse show") : ("collapse")} aria-labelledby={idx} data-parent="#accordionExample">
                                                                    <div className="row">
                                                                        <div className="col-md-11 pr-5rem text-left">
                                                                            <div className="table-responsive-lg " >
                                                                                <table className="table">
                                                                                    <thead>
                                                                                        <tr className='red'>
                                                                                            <th scope="col" className='min-w-35em'>Scheme</th>
                                                                                            <th scope="col" className='min-w-11em'>Date</th>
                                                                                            <th scope="col">Trxn. Type</th>
                                                                                            <th scope="col">Amount</th>
                                                                                        </tr>
                                                                                    </thead>
                                                                                    <tbody className='text-black'>
                                                                                        {this.state.filterIVName.length > 0 ? this.state.filterIVName.map((data) => {

                                                                                            return <>

                                                                                                <tr>
                                                                                                    <td className='border-b-n'>{data.SCHEME}</td>
                                                                                                    <td className='border-b-n'>{data.TRADDATE}</td>
                                                                                                    <td className='border-b-n'>{data.TRXN_NATURE}</td>
                                                                                                    <td className='border-b-n'> &#8377;{data.AMOUNT}</td>
                                                                                                </tr>
                                                                                            </>
                                                                                        }) : null
                                                                                        }
                                                                                    </tbody>
                                                                                </table>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        })}

                                                        <div className=" bg-light-red border-bottom border-top py-2 mb-3 ">
                                                            <div className='row text-black  font-weight-500'>
                                                                <div className='col-md-9 col-sm-7'>Total</div>
                                                                <div className='col-md-3 col-sm-5 pl-sm-7em'>&#8377;{Math.round(this.state.total)}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}

                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* End of Main Content */}
                        {/* Footer */}
                        <Footer />
                        {/* End of Footer */}
                    </div>
                    {/* End of Content Wrapper */}
                </div>
                {/* End of Page Wrapper */}
            </>
        )
    }
}
export default SSS_Report
