import React from "react";
import Header from './header';
import Footer from './footer';
import $ from "jquery";
import { DatePickerComponent } from "@syncfusion/ej2-react-calendars";
import carg from "../../assets/images/icons/New folder (2)/car-g.png";
class Car_Purchase_Goal extends React.Component {
hideGoal() {
$("#first").css({ "display": "none" });
$("#second").css({ "display": "block" });
}
render() {
return (
<>
<div id="content-wrapper" className="d-flex flex-column">
  {/* ===================Main Content================ */}
  <div id="content">
    {/* ==================Topbar================== */}
    <Header />
    {/* ===================End of Topbar============== */}
    {/* ===================Begin Page Content=============== */}
    <div className="container-fluid goal-page">
      {/* ===================Page Heading=================== */}
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb">
          <li className="breadcrumb-item"><a href="home">Home</a></li>
          <li className="breadcrumb-item active" aria-current="page">Car Purchase Goal</li>
        </ol>
      </nav>
      <section>
        <div id="first">
          <div className="row text-center">
            <div className="col-md-12 bg-light-red mb-5">
              <div className="row py-2">
                <div className="col-md-4 offset-md-4 d-flex justify-content-between">
                  <div className=" text-right">
                    <img className="img-fluid mx-w8 bg-goal" src={carg} alt="" />
                  </div>
                  <div className="text-left">
                    <h4 className="red font-weight-500  pt-5">Car Purchase</h4>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 offset-md-3 shadow-custom p-4  text">
              <form className="pb-5 px-lg-5">
                <h6 className="font-weight-500 text-black text-left">How much money would you need to achieve this goals? </h6>
                <div className="form-group text-left">
                  <label className="text-label" htmlFor="term">(In today’s terms)</label>
                  <input type="text" name="term" className="form-control  b-goal" />
                </div>
                <div className="form-group text-left">
                  <label className="text-label" htmlFor="term">Select Goal Tenure</label>
                  <DatePickerComponent format='MMM-yyyy' className="form-control" placeholder='MM-YYYY' start='Year' depth='Year' />
                </div>
                <div className="result-goal">
                  <div className="text-black font-weight-500"> You will require</div>
                  <div className="red font-weight-500">₹3,22,980</div>
                </div>
              </form>
              <div className="form-group text-right">
                <a href="javascript:void(0);" className="btn-custom" onClick={this.hideGoal}>Add Goal</a>
              </div>
            </div>
          </div>
        </div>
        <div id="second">
          <div className="row py-5 mb-5">
            <div className="col-md-8 offset-md-2 p-1 shadow-table table-responsive">
              <table className="table br-50 text-center">
                <thead >
                  <tr className="red bg-gray">
                    <th className="min-w-14em">Your Goal</th>
                    <th className="min-w-10em">Tenure (Years)</th>
                    <th className="min-w-11em">Required Amount </th>
                    <th className="min-w-10em">SIP Per Month </th>
                  </tr>
                </thead>
                <tbody className="text-black">
                  <tr>
                    <td><img className="img-fluid new-icon" src={carg} alt="" /><span className="pl-2">Car Puchase</span></td>
                    <td>Aug 2023</td>
                    <td>₹4,33,000</td>
                    <td>₹8500</td>
                  </tr>
                  <tr>
                    <td><img className="img-fluid new-icon" src={carg} alt="" /><span className="pl-2">Car Puchase</span></td>
                    <td>Aug 2023</td>
                    <td>₹4,33,000</td>
                    <td>₹8500</td>
                  </tr>
                  <tr >
                    <td><img className="img-fluid new-icon" src={carg} alt="" /><span className="pl-2">Car Puchase</span></td>
                    <td>Aug 2023</td>
                    <td>₹4,33,000</td>
                    <td>₹8500</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="col-md-8 offset-md-2 py-5 text-center text-black font-weight-500">
              <span> Your incremental SIP Amount :</span><span>₹3,22,000</span>
              <div className="row pt-5">
                <div className="col-md-6 col-sm-6 text-left">
                  <a href="javascript:void(0);" className="btn-custom">Add More Goal</a>
                </div>
                <div className="col-md-6 col-sm-6 text-right">
                  <a href="/prodigypro/dashboard/goal-wise-scheme" className="btn-custom">Proceed</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
    {/* ====================Footer=================== */}
    <Footer />
    {/* ====================End of Footer================= */}
  </div>
  {/* =======================End of Content Wrapper==================== */}
</div>
{/*====================== End of Page Wrapper====================== */}
</>
)
}
}
export default Car_Purchase_Goal