import React from "react";
import Header from './header';
import Footer from './footer';
import { FaTrash } from "react-icons/fa";
import sbi from "../../assets/images/icons/New folder (2)/sbi mutual fund.png";
import idfc from "../../assets/images/icons/New folder (2)/IDFC.png";
import Invest_Sip from "./invest-sip";
import Invest_Lumpsum from "./invest-lumpsum";
class View_Cart extends React.Component
{
    render()
    {
        return(
            <>
             {/* Page Wrapper */}
        <div id="wrapper">
        
        {/* Content Wrapper */}
        <div id="content-wrapper" className="d-flex flex-column">
          {/* Main Content */}
          <div id="content">
            {/* Topbar */}
            <Header />
            {/* End of Topbar */}
            {/* Begin Page Content */}
            <div className="container-fluid bg-gray mt-n3">
              {/* Page Heading */}
              <nav aria-label="breadcrumb ">
            <ol className="breadcrumb-nav d-flex py-4">
              <li className="breadcrumb-item"><a href="home">Home</a></li>
              <li className="breadcrumb-item active" aria-current="page">Cart</li>
            </ol>
          </nav>


<section>
    
    <div className="row justify-content-center pb-5">
        <div className="col-md-10 col-lg-8">
        <table className=' shadow-custom bg-white mb-2 p-3 rounded table-responsive ml-sm-auto'>
          <tr>
                <td className='minw-6em'><img src={sbi} alt="" className='img-fluid' /></td>
                <td className='minw-32em pt-2'><a href='!#' className="text-black">SBI Long Term Equity Fund-Regular
                  Plan-Growth  </a>
                </td>
                <td className='pt-1'>
                <a className="btn-outline" href="javascript:void(0);" data-target="#invest-sip" data-toggle="modal" type="button">Invest</a>
                </td>
                <td className='minw-7em pt-2'>
                  <FaTrash  className="red"/>
                </td>
                </tr>
              </table>
              <table className=' shadow-custom bg-white mb-2 p-3 rounded table-responsive ml-sm-auto'>
                <tr>
                <td className='minw-6em pt-2'><img src={idfc} alt="" className='img-fluid' /></td>
                <td className='minw-32em pt-2'><a href='!#'className="text-black">IDFC Tax Advantage (ELSS)
                  Fund-Growth-Regular Plan </a>
                  </td>
                  <td className='pt-1'>
                <a className="btn-outline" href="javascript:void(0);" data-target="#invest-lumpsum" data-toggle="modal" type="button">Invest</a>
                </td>
                <td className='minw-7em pt-2'>
                  <FaTrash  className="red"/>
                  </td>
                </tr>
                </table>
                <table className=' shadow-custom bg-white mb-2 p-3 rounded table-responsive ml-sm-auto'>
          <tr>
                <td className='minw-6em'><img src={sbi} alt="" className='img-fluid' /></td>
                <td className='minw-32em pt-2'><a href='!#' className="text-black">SBI Long Term Equity Fund-Regular
                  Plan-Growth  </a>
                </td>
                <td className='pt-1'>
                <a className="btn-outline" href="javascript:void(0);" data-target="#invest-sip" data-toggle="modal" type="button">Invest</a>
                </td>
                <td className='minw-7em pt-2'>
                  <FaTrash  className="red"/>
                </td>
                </tr>
              </table>
              <table className=' shadow-custom bg-white mb-2 p-3 rounded table-responsive ml-sm-auto'>
          <tr>
                <td className='minw-6em'><img src={sbi} alt="" className='img-fluid' /></td>
                <td className='minw-32em pt-2'><a href='!#' className="text-black">SBI Long Term Equity Fund-Regular
                  Plan-Growth  </a>
                </td>
                <td className='pt-1'>
                <a className="btn-outline" href="javascript:void(0);" data-target="#invest-sip" data-toggle="modal" type="button">Invest</a>
                </td>
                <td className='minw-7em pt-2'>
                  <FaTrash  className="red"/>
                </td>
                </tr>
              </table>
              </div>
    </div>
    <Invest_Sip/>
    <Invest_Lumpsum/>
</section>

              
              </div>
            </div>
           
          </div>
          {/* End of Content Wrapper */}
        </div>
         {/* Footer */}
         <Footer />
            {/* End of Footer */}
        {/* End of Page Wrapper */}
            
            
            </>
        )
    }
}
export default View_Cart