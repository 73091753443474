import React from "react";
import Header from './header';
import Footer from './footer';
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import sipc from "../../assets/images/icons/New folder (2)/budget.png";
import ring from "../../assets/images/icons/New folder (2)/rings.png";
import education from "../../assets/images/icons/New folder (2)/education.png";
import emi from "../../assets/images/icons/New folder (2)/calculatorfuture.png";
import retirement from "../../assets/images/icons/New folder (2)/retirement.png";
import futurec from "../../assets/images/icons/New folder (2)/calculator.png";
import minus from "../../assets/images/icons/New folder (2)/minus_3(1).png";
import plus from "../../assets/images/icons/New folder (2)/add_3(1).png";
import fd from "../../assets/images/icons/New folder (2)/fd.png";
import elss from "../../assets/images/icons/New folder (2)/elss.png";
import $ from "jquery";
import Button from 'react-bootstrap/Button';
// import 'animate.css';
import { LineChart } from 'react-chartkick';
import 'chartkick/chart.js';
class Marriage_Planning_Pro extends React.Component {

render() {

return (
<>
<Helmet>
<title>Calculators - Prodigy Pro</title>
</Helmet>
<style>
{`



.form-control::placeholder
{
color:#000!important;
text-align:center;
}
#marriage-2
{
display:none;
}
.result-content.text-center .text-label.font-weight-500.py-2 {
min-height:65px;
}


`}
</style>
{/* Page Wrapper */}
<div id="wrapper">
  {/* <div id="overlay" >
    <div className="spinner"></div>
    <br />Loading...
  </div>          */}
  {/* Content Wrapper */}
  <div id="content-wrapper" className="d-flex flex-column">
    {/* Main Content */}
    <div id="content">
      {/* Topbar */}
      <Header />
        {/* End of Topbar */}
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item"><a href="home">Home</a></li>
            <li className="breadcrumb-item active" aria-current="page">Calculators</li>
          </ol>
        </nav>
        <div className="container-fluid  px-lg-5 pb-5 calculator">
          <div className="row">
            <div className="col-md-12">
            
              {/* =============Calculators Nav Start================ */}
              <div class="container-calculator pb-5">
                <ul class="nav navtop text-center">
                  <div className="col-lg-3 col-md-6">
                    <li class="nav-item">
                      <Link class="nav-link " to="/prodigypro/dashboard/sip-calculator-pro">
                      <img src={sipc} className="new-icon" alt='' />  <span>   SIP Calculator</span>
                    </Link>
                  </li>
                </div>
                <div className="col-lg-3 col-md-6 pt-xs-2">
                  <li class="nav-item">
                    <Link class="nav-link active" to="/prodigypro/dashboard/marriage-planning-pro"><img src={ring} className="new-icon" alt='' />  <span>Marriage
                  Planning</span></Link>
                </li>
              </div>
              <div className="col-lg-3 col-md-6 pt-xs-2 pt-tab-2">
                <li class="nav-item">
                  <Link class="nav-link" to="/prodigypro/dashboard/education-planning-pro"><img src={education} className="new-icon" alt='' /> <span>Education
                Planning</span></Link>
              </li>
            </div>
            <div className="col-lg-3 col-md-6 pt-xs-2 pt-tab-2">
              <li class="nav-item">
                <Link class="nav-link" to="/prodigypro/dashboard/future-value-calculator-pro"><img src={futurec} className="new-icon" alt='' /><span>Future Value
              Calculator</span></Link>
            </li>
          </div>
          <div className="col-lg-3 col-md-6 mt-3">
            <li class="nav-item">
              <Link class="nav-link" to="/prodigypro/dashboard/retirement-planning-pro"><img src={retirement} className="new-icon" alt='' /><span>Retirement
            Planning</span></Link>
          </li>
        </div>
        <div className="col-lg-3 col-md-6 mt-3">
          <li class="nav-item">
            <Link class="nav-link " to="/prodigypro/dashboard/emi-calculator-pro"><img src={emi} className="new-icon" alt='' /><span>EMI
          Calculator</span></Link>
        </li>
      </div>
      <div className="col-lg-3 col-md-6 mt-3">
        <li class="nav-item">
          <Link class="nav-link" to="/prodigypro/dashboard/fd-calculator-pro"><img src={fd} className="new-icon" alt='' /><span>FD
        Calculator</span></Link>
      </li>
    </div>
    <div className="col-lg-3 col-md-6 mt-3">
      <li class="nav-item">
        <Link class="nav-link" to="/prodigypro/dashboard/elss-calculator-pro"><img src={elss} className="new-icon" alt='' /><span>ELSS
      Calculator</span></Link>
    </li>
  </div>
</ul>
{/* =============Calculators Nav End================ */}
<div class=" mt-3 ">
  <div class="" role="tabpanel">
    <section>
      <div className="results pt-5">
        <div className="px-lg-5">
          <div className="shadowc br-50 px-lg-4 px-sm-3 pb-5">
            <section className="pt-5 pb-5">
              <div className="row" id="marriage-1">
                {/*first*/}
                <div className="col-md-4 text-center">
                  <label for="m-saving" className="text-label font-weight-500 py-2 fs-14">
                    Child's age today (Years)
                  </label>
                  <div className="d-flex inputf transcard">
                    <img src={minus} alt="" className="img-fluid max-27"></img>
                    <input type="text" className="form-control" name="m-saving" placeholder="200" />
                    <img src={plus} alt="" className="img-fluid max-27"></img>
                  </div>
                </div>
                <div className="col-md-4 text-center">
                  <label for="year" className="text-label font-weight-500 py-2 fs-14">
                    Child will get married at the age of (Years)
                  </label>
                  <div className="d-flex inputf transcard">
                    <img src={minus} alt="" className="img-fluid max-27"></img>
                    <input type="text" className="form-control" name="year" placeholder="5" />
                    <img src={plus} alt="" className="img-fluid max-27"></img>
                  </div>
                </div>
                <div className="col-md-4 text-center">
                  <label for="return" className="text-label font-weight-500 py-2 fs-14">
                    Amount required for wedding as on today(₹)
                  </label>
                  <div className="d-flex inputf transcard">
                    <img src={minus} alt="" className="img-fluid max-27"></img>
                    <input type="text" className="form-control" name="return" placeholder="7" />
                    <img src={plus} alt="" className="img-fluid max-27"></img>
                  </div>
                </div>
              </div>
              {/*second*/}
              <div className="row" >
                <div className="col-md-4 text-center">
                  <label for="m-saving" className="text-label font-weight-500 py-2 fs-14">
                    Annual Saving(₹)
                  </label>
                  <div className="d-flex inputf transcard">
                    <img src={minus} alt="" className="img-fluid max-27"></img>
                    <input type="text" className="form-control" name="m-saving" placeholder="200" />
                    <img src={plus} alt="" className="img-fluid max-27"></img>
                  </div>
                </div>
                <div className="col-md-4 text-center">
                  <label for="year" className="text-label font-weight-500 py-2 fs-14">
                    Expected rate of return(%)
                  </label>
                  <div className="d-flex inputf transcard">
                    <img src={minus} alt="" className="img-fluid max-27"></img>
                    <input type="text" className="form-control" name="year" placeholder="5" />
                    <img src={plus} alt="" className="img-fluid max-27"></img>
                  </div>
                </div>
                <div className="col-md-4 text-center">
                  <label for="return" className="text-label font-weight-500 py-2 fs-14">
                    Expected inflation rate (% p.a)
                  </label>
                  <div className="d-flex inputf transcard">
                    <img src={minus} alt="" className="img-fluid max-27"></img>
                    <input type="text" className="form-control" name="return" placeholder="7" />
                    <img src={plus} alt="" className="img-fluid max-27"></img>
                  </div>
                </div>
              </div>
              <div className="col-md-12 mt-3  text-right text-sm-center">
                <button className="new-btn1" >Calculate</button>
              </div>
            </section>
            <div className="row shadowc br-50 mx-3 p-lg-5">
              <div className="col-md-12 px-lg-5 pt-5">
                <div className="col-md-12 result-title text-center">
                  <h3>Result</h3>
                </div>
                <div className="result-content text-center">
                  <div className="row pt-3">
                    <div className="col-md-4">
                      <div className="text-label font-weight-500 py-2">
                        Inflation Adjusted Cost(₹)
                      </div>
                      <div className="inputf transcard  py-2 bg-light-red">10000</div>
                    </div>
                    <div className="col-md-4">
                      <div className="text-label font-weight-500 py-2 ">
                        Future value of saving (₹)
                      </div>
                      <div className="inputf transcard py-2 bg-light-red">1000</div>
                    </div>
                    <div className="col-md-4">
                      <div className="text-label font-weight-500 py-2">
                        Additional funds required to meet expenses(₹)
                      </div>
                      <div className="inputf transcard bg-light-red py-2">10500</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-12 px-lg-5 pt-5">
                <div className="col-md-12 result-title text-center">
                  <h3>Plan of action Required</h3>
                </div>
                <div className=" row result-content text-center pb-3">
                  <div className="col-md-5">
                    <div className="text-label font-weight-500 py-2">
                      One time investment required(₹)
                    </div>
                    <div className="inputf transcard bg-light-red py-2">10000</div>
                  </div>
                  <div className="col-md-2"><div className="text-label font-weight-500 py-2"><strong className="text-black">OR</strong></div></div>
                  <div className="col-md-5">
                    <div className="text-label font-weight-500 py-2">
                      Monthly investment required(₹)
                    </div>
                    <div className="inputf transcard bg-light-red py-2">20301</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row px-5 pt-3">
              
              <div className="col-md-12 text-right">
                <button className="btn-custom ">Invest Now</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</div>

</div>
</div>
</div>
</div>
{/* Footer */}
<Footer />
{/* End of Footer */}
</div>
</div>
</div>
</>
)
}
}
export default Marriage_Planning_Pro