import React, {component} from 'react';

class Footer extends React.Component{
    render(){
        
        return(
        <>
            <style>
          {`
    
          `}
          </style>
          
        <footer className="sticky-footer bg-white border-top">
            <div className="container my-auto">
                <div className="copyright text-center my-auto">
                    <span>Copyright © BFC Capital 2021</span>
                </div>
            </div>
      </footer>
        </>
        )
    }
    
}
export default Footer
