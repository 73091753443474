import React, {component} from 'react';

class Footer extends React.Component{
    render(){
        
        return(
        <>
            <style>
          {`
          .pad {
           padding-left:30px;
           padding-right:30px;
          `}
          </style>
        
        </>
        )
    }
    
}
export default Footer
