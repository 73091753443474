import React from 'react';
class Change_Password extends React.Component {
render() {
return (
<>
<style>
  {
  `
  .px-row{
  padding:0 .8rem;
  }
  .load{
  display:none;
  }
  .eye-button{
  margin-left:-0px;
  }
  .d-icon{
  float: right;
  top: -31px;
  position: relative;
  width: 24px;
  height: 24px;
  right: 10px;
  cursor: pointer;
  }
  .fa-info{
  color:#fff;
  }
  #registerTip{
  left: 885px !important;
  max-width: 162px;
  padding: 8px 13px;
  }
  .top-text{
  position: relative;
  top:  -20px;
  }
  .form-control
  {
  border-color: #ced4da !important;
  }
  `
  }
</style>
<section className="change-password-section">
  <div className="row">
    <div tabindex="-1" className="modal pmd-modal animate__animated animate__zoomIn animate__fast" id="change-password" aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content b-r p-3 bg-white">
          <div className="modal-header border-0">
            <div className="quick-access-title">
              <h5 className='red'>Change Password</h5>
            </div>
            <button aria-hidden="true" data-dismiss="modal" className="close" type="button">×</button>
          </div>
          <div className="modal-body">
            <form>
              <div className="card-body">
                <div className=" text-left">
                  <div className="col-lg-11 col-md-4 mb-3 mx-auto">
                    <label>
                      Exiting Password 
                      <spna classname="text-danger">*</spna>
                    </label>
                    <input className="form-control  border-pop p-3" type="text" placeholder="Enter Exiting Password" name="exit_pass" defaultValue=""/>
                  </div>
                  <div className="col-lg-11 col-md-4 mb-3 mx-auto">
                    <label>
                      New Password 
                      <spna classname="text-danger">*</spna>
                    </label>
                    <div className="d-flex">
                      <input id="pass" type="password" placeholder="Enter New Password" className="form-control  border-pop p-3"  name="new_pass" />
                    </div>
                    <div className="bg-info rounded-circle d-icon text-center" >
                      <i class="fa fa-info " data-tip data-for="registerTip" ></i>
                    </div>
                  </div>
                  <div className="col-lg-11 col-md-4 mb-3 mx-auto">
                    <label>
                      Confirm Password 
                      <spna classname="text-danger">*</spna>
                    </label>
                    <div className="d-flex">
                      <input className="form-control border-pop p-3 " type="password" placeholder="Enter Confirm Password" id="new_pass" name="conf_pass" defaultValue=""/>
                    </div>
                    <div className="rounded-circle d-icon" >
                      <i class="fa fa-fw fa-eye-slash field_icon toggle-password" toggle="#password-field" ></i>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-4 mb-3 mx-auto pt-3 text-center">
                    <a href="javascript:void(0);" class="btn-custom ">
                    Submit 
                    </a>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
</>
)
}
}
export default Change_Password